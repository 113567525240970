//------------------------------------ Description ------------------------------------------------------------
//This form allows to change share state between Write and Read. Change can be set as delayed in time. 
//No longer than current share closure date.
//-------------------------------------------------------------------------------------------------------------

import * as React from "react";
import { Modal } from '@fluentui/react/lib/Modal';
import { getId } from '@fluentui/react/lib/Utilities';
import {
    PrimaryButton,
    Stack,
    DefaultButton,
    IStackTokens,
    Label,
    DatePicker,
    DayOfWeek,
    Toggle
} from '@fluentui/react';
import { Container, Row, Col } from "react-bootstrap";
import { GraphService } from "../components/GraphService";
import * as Msal from "msal";
import { colorThemeLight } from "../components/Themes";
import { ProgressIndicator, IProgressIndicatorStyles } from '@fluentui/react/lib/ProgressIndicator';
import { PLCalendarSettings, ENCalendarSettings } from "../components/Calendars";

const config = require("../components/config.json");

const progressStyles: Partial<IProgressIndicatorStyles> = {
    itemProgress: { paddingTop: 0 }
};

const stackTokens: IStackTokens = { childrenGap: 10 };

export interface ChangeStatusFormProps {
    isOpen: boolean;
    authToken: string;
    userAgentApplication: Msal.UserAgentApplication;
    currentShareData: any;
    shareState: string;
    shareStateLang: string;
    lang: string;
    shareDetailsRefreshMethod: any;
}
export interface ChangeStatusFormState {
    ChangeStatusTextBoxText?: string;
    showChangeStatusModal: boolean;
    ChangeStatusErrorMessage?: string;
    createButtonDisabled: boolean;
    contentLoad: boolean;
    dateVisible: boolean;
    delayedClosureDate?: Date;
    delayed: boolean;
    buttonClick: boolean;
}

export class ChangeStatusForm extends React.Component<ChangeStatusFormProps, ChangeStatusFormState> {
    private _ChangeStatusTitleId: string = getId('new-folder-title');
    private _ChangeStatusSubtitleId: string = getId('new-folder-subtitle');
    private _maxDate: Date;

    constructor(props: ChangeStatusFormProps) {
        super(props);

        this.state = {
            showChangeStatusModal: false,
            createButtonDisabled: true,
            contentLoad: false,
            dateVisible: false,
            delayed: false,
            buttonClick: false
        }
    }

    render() {

        //*** Language section ***
        let resource: any;
        if (this.props.lang == "PL") {
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        let today = new Date;
        this._maxDate = new Date(this.props.currentShareData.DataZamkniecia); //regional string

        const { showChangeStatusModal, contentLoad, dateVisible, buttonClick } = this.state;
        return (
            <Modal
                titleAriaId={this._ChangeStatusTitleId}
                subtitleAriaId={this._ChangeStatusSubtitleId}
                isOpen={showChangeStatusModal}
                onDismiss={this._closeChangeStatusModal.bind(this)}
                isModeless={false}
                dragOptions={undefined}
                containerClassName="swpStyle-narrow-forms-modal-window"
                theme={colorThemeLight}
            >
                <div className="swpStyle-del-item-modal-header">
                    <span id={this._ChangeStatusTitleId}><h6>{resource.str_HeaderChangeShareStatus}</h6></span>
                </div>
                <ProgressIndicator progressHidden={!contentLoad} barHeight={5} theme={colorThemeLight} styles={progressStyles} />
                <div id={this._ChangeStatusSubtitleId} className="swpStyle-del-item-modal-body">
                    <Label theme={colorThemeLight} style={{fontWeight: 400, textAlign: "center", marginLeft: "auto", marginRight: "auto"}}><p>{resource.str_LblConfirmIfStatusChange} {this.props.shareStateLang} ?</p></Label>

                    {this.props.shareState == "Odczyt" && //IF we changing to Disabled  
                        <Container>
                            <Row>
                                <Col>
                                    <Label theme={colorThemeLight} style={{fontWeight: 400}}><p>{resource.str_MsgDelayDisable}</p></Label>
                                    <Toggle
                                        inlineLabel
                                        role="checkbox"
                                        theme={colorThemeLight}
                                        label={resource.str_LblDelayDisable}
                                        onText={resource.str_ToggleYes}
                                        offText={resource.str_ToggleNo}
                                        onChange={this._onToggleChange.bind(this)}
                                        checked={dateVisible}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    }
                    {dateVisible && <Container>
                        <Row>
                            <Col>
                                <DatePicker
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    strings={this.props.lang == "PL" ? PLCalendarSettings : ENCalendarSettings}
                                    placeholder={resource.str_PhChooseDate}
                                    ariaLabel={resource.str_InpNewClosureDate}
                                    onSelectDate={this._onCalendarChange}
                                    minDate={today}
                                    maxDate={this._maxDate}
                                    value={this.state.delayedClosureDate}
                                    theme={colorThemeLight}
                                    formatDate={this._onFormatDate}
                                    //styles={IDatePickerStyleProps}
                                />
                            </Col>
                        </Row>
                    </Container>
                    }
                    <br></br>
                    <div id="swpStyle-del-item-buttons">
                        <Stack horizontal tokens={stackTokens} className="swpStyle-modal-buttonParrent">
                            <PrimaryButton theme={colorThemeLight} style={{ marginLeft: "auto" }} onClick={this._onChangeStatusClick.bind(this)} text={resource.str_BtnOK} disabled={buttonClick} />
                            <DefaultButton theme={colorThemeLight} style={{ marginRight: "auto" }} onClick={this._closeChangeStatusModal.bind(this)} text={resource.str_BtnCancel} />
                        </Stack>
                    </div>
                </div>
            </Modal>
        )
    }

    private _onFormatDate = (date: Date): string => {
        if (this.props.lang == "PL") {
            return date.toLocaleDateString("pl-pl")
        } else {
            return date.toLocaleDateString("en-uk")
        }
    };

    private _onToggleChange(ev: React.MouseEvent<HTMLElement>, checked: boolean) {
        config.DEBUG && console.log('toggle is ' + (checked ? 'checked' : 'not checked'));
        if (checked) {
            this.setState({
                dateVisible: true,
                delayed: true
            });
        } else {
            this.setState({ 
                dateVisible: false,
                delayed: false
            });
        }
    }

    private _onCalendarChange = (date: Date | null | undefined): void => {
        this.setState({
            delayedClosureDate: date
        }, () => {config.DEBUG && console.log(this.state.delayedClosureDate)})

        // config.DEBUG && console.log(this.state.delayedClosureDate)
    }

    private _closeChangeStatusModal() {
        config.DEBUG && console.log("_closeChangeStatusModal");
        this.setState({
            showChangeStatusModal: false,
            dateVisible: false,
            delayedClosureDate: null,
            buttonClick: false
        });
    }

    public _openChangeStatusModal() {
        config.DEBUG && console.log("_openChangeStatusModal");
        this.setState({ showChangeStatusModal: true });
    }

    private _onChangeStatusClick() {
        config.DEBUG && console.log("_onChangeStatusClick");
        config.DEBUG && console.log("Changing Status to: " + this.props.shareState);

        let graphService: GraphService = new GraphService(this.props.authToken, this.props.userAgentApplication);
        // let openDate: Date;
        this.setState({buttonClick: true}) //disabling button to stop users from clicking like crazy

        this.setState({ contentLoad: true }) //start load

        graphService.changeShareStatusOnGoing(null, this.props.currentShareData.id, this.state.delayedClosureDate).then(result => {
            //after modifing entry in share list for temp status, we request for changing the actual status:
            graphService.changeShareStatus(this.props.shareState, this.props.currentShareData.id, this.state.delayed,  this.state.delayedClosureDate).then(result => { //regional string
                //adding item to change share list
                if (result) {
                    this.props.shareDetailsRefreshMethod(); //refreshing ShareDetails component
                    this._closeChangeStatusModal();
                }
            });
        });

        this.setState({ contentLoad: false }) //finish load     
    }
}