//------------------------------------ Description ------------------------------------------------------------
//This form allows Admin Users to confirm share creation after AIP configuration
//-------------------------------------------------------------------------------------------------------------

import * as React from "react";
import {Modal} from '@fluentui/react/lib/Modal';
import { getId } from '@fluentui/react/lib/Utilities';
import { 
    PrimaryButton, 
    Stack, 
    DefaultButton, 
    IStackTokens, 
    Label
 } from '@fluentui/react';
import { GraphService } from "../components/GraphService";
import * as Msal from "msal";
import { colorThemeLight } from "../components/Themes";

const config = require("../components/config.json");
// const resource = require("../LangFiles/resource.json");

const stackTokens: IStackTokens = { childrenGap: 10 };

export interface AIPConfirmFormProps { 
    isOpen:boolean ;
    authToken:string;
    userAgentApplication: Msal.UserAgentApplication;
    currentShareData: any;
    shareDetailsRefreshMethod: any;
    refreshAfterConfirmMethod: any;
    message: string;
    lang: string;
}
export interface AIPConfirmFormState {
    userTypeValue?:string;
    showAIPConfirmModal:boolean;
    ButtonDisabled: boolean;
    shareName: string;
 }

export class AIPConfirmForm extends React.Component<AIPConfirmFormProps, AIPConfirmFormState> {
    private _AIPConfirmTitleId: string = getId('new-folder-title');
    private _AIPConfirmSubtitleId: string = getId('new-folder-subtitle');

    constructor(props: AIPConfirmFormProps) {
        super(props);

        this.state = {
            showAIPConfirmModal:false,
            ButtonDisabled:true,
            shareName: ""
        }
    }


    render() {

        //*** Language section ***
        let resource: any;
        if(this.props.lang == "PL"){
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        const { showAIPConfirmModal} = this.state;

        return(
        <Modal
            titleAriaId={this._AIPConfirmTitleId}
            subtitleAriaId={this._AIPConfirmSubtitleId}
            isOpen={showAIPConfirmModal}
            onDismiss={this._closeAIPConfirmModal.bind(this)}
            isModeless={false}
            dragOptions={undefined}
            containerClassName="swpStyle-narrow-forms-modal-window"
        >
            <div className="swpStyle-upload-file-modal-header">
                <span id={this._AIPConfirmTitleId}><h6>{resource.str_HeaderAIPConfirm}</h6></span>
            </div>

            <div id={this._AIPConfirmSubtitleId} className="swpStyle-upload-file-modal-body">
                {/* <Label theme={colorThemeLight} style={{textAlign: "center"}}><p>{this.props.message}</p></Label> */}
                <Label theme={colorThemeLight} style={{fontWeight: 400, textAlign: "center", marginLeft: "auto", marginRight: "auto"}}><p>{this.props.message}</p></Label>
                {/* Submit buttons: */}
                <Stack horizontal tokens={stackTokens} className="swpStyle-modal-buttonParrent">
                    <PrimaryButton theme={colorThemeLight} onClick={this._onAIPConfirm.bind(this)} text={resource.str_BtnConfirm}/>
                    <DefaultButton theme={colorThemeLight} onClick={this._closeAIPConfirmModal.bind(this)} text={resource.str_BtnCancel} />
                </Stack>
            </div>
        </Modal>
        )
    }

    private _closeAIPConfirmModal= (): void => {
        this.setState({ 
            showAIPConfirmModal: false,
        });
    }

    public _openAIPConfirmModal() {
        this.setState({ showAIPConfirmModal: true });
    }
  
    private _onAIPConfirm() {
        config.DEBUG && console.log("_onAIPConfirmType");
        let graphService: GraphService = new GraphService(this.props.authToken, this.props.userAgentApplication);
        graphService.continueShareRequest(this.props.currentShareData).then(result => {
            //
            if(result){
              this._closeAIPConfirmModal();
              this.props.refreshAfterConfirmMethod();
            }
        })       
    }
    
}