//------------------------------------ Description ------------------------------------------------------------
//This form allows Operators to change share size. Maximum is set to 100GB
//-------------------------------------------------------------------------------------------------------------

import * as React from "react";
import { Modal } from '@fluentui/react/lib/Modal';
import { getId } from '@fluentui/react/lib/Utilities';
import {
    PrimaryButton,
    Stack,
    DefaultButton,
    IStackTokens,
    DatePicker,
    DayOfWeek,
    Label
} from '@fluentui/react';
import { GraphService } from "../components/GraphService";
import * as Msal from "msal";
import { Container, Row, Col } from "react-bootstrap";
import { PLCalendarSettings, ENCalendarSettings } from "../components/Calendars";
import { colorThemeLight } from "../components/Themes";
import { ProgressIndicator, IProgressIndicatorStyles } from '@fluentui/react/lib/ProgressIndicator';

const config = require("../components/config.json");
// const resource = require("../LangFiles/resource.json");

const progressStyles: Partial<IProgressIndicatorStyles> = {
    itemProgress: { paddingTop: 0 }
};

const stackTokens: IStackTokens = { childrenGap: 10 };

export interface ExtendShareFormProps {
    isOpen: boolean;
    authToken: string;
    userAgentApplication: Msal.UserAgentApplication;
    currentShareData: any;
    shareDetailsRefreshMethod: any;
    lang: string;
}
export interface ExtendShareFormState {
    userTypeValue?: string;
    showExtendShareModal: boolean;
    ExtendShareButtonDisabled: boolean;
    shareName: string;
    newShareClosureDate?: Date;
    contentLoad: boolean;
}

export class ExtendShareForm extends React.Component<ExtendShareFormProps, ExtendShareFormState> {
    private _ExtendShareTitleId: string = getId('new-folder-title');
    private _ExtendShareSubtitleId: string = getId('new-folder-subtitle');

    constructor(props: ExtendShareFormProps) {
        super(props);

        this.state = {
            showExtendShareModal: false,
            ExtendShareButtonDisabled: true,
            shareName: "",
            contentLoad: false
        }
    }


    render() {

        //*** Language section ***
        let resource: any;
        if (this.props.lang == "PL") {
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        const { showExtendShareModal, contentLoad, ExtendShareButtonDisabled } = this.state;

        function addDays(date: Date, days: number) {
            var result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        }

        let today = new Date;
        let curClosureDate = new Date(this.props.currentShareData.DataZamkniecia);
        let maxDate = addDays(today, 90);
        let tomorrow = addDays(today, 1)

        return (
            <Modal
                titleAriaId={this._ExtendShareTitleId}
                subtitleAriaId={this._ExtendShareSubtitleId}
                isOpen={showExtendShareModal}
                onDismiss={this._closeExtendShareModal.bind(this)}
                isModeless={false}
                dragOptions={undefined}
                containerClassName="swpStyle-narrow-forms-modal-window"
                theme={colorThemeLight}
            >
                <div className="swpStyle-upload-file-modal-header">
                    <span id={this._ExtendShareTitleId}><h6>{resource.str_HeaderExtendShare}</h6></span>
                </div>
                <ProgressIndicator progressHidden={!contentLoad} barHeight={5} theme={colorThemeLight} styles={progressStyles} />
                <div id={this._ExtendShareSubtitleId} className="swpStyle-upload-file-modal-body">

                    {/* Form fields: */}
                    <Container>
                        <Row>
                            <Label theme={colorThemeLight} style={{fontWeight: 400, textAlign: "center", marginLeft: "auto", marginRight: "auto"}}><p>{resource.str_MsgExtendShareInfo}</p></Label>
                        </Row>
                        <Row>
                            <Col>
                                <DatePicker
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    strings={this.props.lang == "PL" ? PLCalendarSettings : ENCalendarSettings}
                                    placeholder={resource.str_PhChooseDate}
                                    ariaLabel={resource.str_InpNewClosureDate}
                                    onSelectDate={this._onCalendarChange}
                                    minDate={tomorrow}
                                    maxDate={maxDate}
                                    value={this.state.newShareClosureDate}
                                    theme={colorThemeLight}
                                    formatDate={this._onFormatDate}
                                />
                            </Col>
                        </Row>
                    </Container>
                    <br></br>

                    {/* Submit buttons: */}
                        <Stack horizontal tokens={stackTokens} className="swpStyle-modal-buttonParrent">
                            <PrimaryButton disabled={ExtendShareButtonDisabled} theme={colorThemeLight} onClick={this._onExtendShare.bind(this)} text={resource.str_BtnConfirm} />
                            <DefaultButton theme={colorThemeLight} onClick={this._closeExtendShareModal.bind(this)} text={resource.str_BtnCancel} />
                        </Stack>
                </div>
            </Modal>
        )
    }

    private _onFormatDate = (date: Date): string => {
        if (this.props.lang == "PL") {
            return date.toLocaleDateString("pl-pl")
        } else {
            return date.toLocaleDateString("en-uk")
        }
    };

    private _closeExtendShareModal = (): void => {
        this.setState({
            showExtendShareModal: false,
        });
    }

    public _openExtendShareModal() {
        this.setState({ showExtendShareModal: true });
    }

    private _onCalendarChange = (date: Date | null | undefined): void => {
        this.setState({
            newShareClosureDate: date,
            ExtendShareButtonDisabled: false
        })
    }

    private _onExtendShare() {
        config.DEBUG && console.log("_onChangeShareType");
        let graphService: GraphService = new GraphService(this.props.authToken, this.props.userAgentApplication);
        this.setState({ contentLoad: true }) //start load
        graphService.extendShare(this.props.currentShareData, this.state.newShareClosureDate).then(result => {
            //
            if (result) {
                this.props.shareDetailsRefreshMethod(); //refreshing ShareDetails component
                this._closeExtendShareModal();
            }
        })
        this.setState({ contentLoad: false }) //finish load      
    }

}