//------------------------------------ Description ------------------------------------------------------------
//This form allows Operators to invite other users to cooperate on share.
//it is not possible to add 2 different external users with the same external email address.
//-------------------------------------------------------------------------------------------------------------

import * as React from "react";
import { Modal } from '@fluentui/react/lib/Modal';
import { getId } from '@fluentui/react/lib/Utilities';
import {
  PrimaryButton,
  Stack,
  TextField,
  MaskedTextField,
  DefaultButton,
  IStackTokens,
  Toggle,
  Dropdown,
  IDropdownStyles,
  IDropdownOption,
  Dialog,
  DialogFooter,
  DialogType,
  Text
} from '@fluentui/react';
import {
  IBasePickerSuggestionsProps,
  IBasePicker,
  NormalPeoplePicker,
  ValidationState
} from '@fluentui/react/lib/Pickers';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { Icon } from '@fluentui/react/lib/Icon';
import { GraphService } from "../components/GraphService";
import * as Msal from "msal";
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import { Container, Row, Col } from "react-bootstrap";
import { colorThemeLight } from "../components/Themes";
import { ProgressIndicator, IProgressIndicatorStyles } from '@fluentui/react/lib/ProgressIndicator';

const config = require("../components/config.json");
// const resource = require("../LangFiles/resource.json");
const gblResource = require("../LangFiles/resource.json");
const gblResourcePL = require("../LangFiles/resourcePL.json");

const stackTokens: IStackTokens = { childrenGap: 10 };

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 }
};

const progressStyles: Partial<IProgressIndicatorStyles> = {
  itemProgress: { paddingTop: 0 }
};

const options: IDropdownOption[] = [
  { key: 'PracownikNBP', text: gblResource.str_UserTypePartner, title: "PracownikNBP" },
  //{ key: 'Operator', text: gblResource.str_UserTypeOperator, title: "Operator" },
  { key: 'PodmiotZewnetrzny', text: gblResource.str_UserTypeExternalSmall, title: "PodmiotZewnetrzny" }
];

const optionsPL: IDropdownOption[] = [
  { key: 'PracownikNBP', text: gblResourcePL.str_UserTypePartner, title: "PracownikNBP" },
  //{ key: 'Operator', text: gblResourcePL.str_UserTypeOperator, title: "Operator" },
  { key: 'PodmiotZewnetrzny', text: gblResourcePL.str_UserTypeExternalSmall, title: "PodmiotZewnetrzny" }
];

const dialogContentProps = {
  type: DialogType.normal
};

const dialogErrorContentProps = {
  type: DialogType.normal,
};

const missingDataDialogContentProps = {
  type: DialogType.normal,
};

const ForbiddenEmailDataDialogContentProps = {
  type: DialogType.normal,
};

const dialogModalProps = {
  isBlocking: true,
  styles: { main: { minWidth: 350, maxWidth: 350, minHeight: 50 } }
};

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: '',
  //mostRecentlyUsedHeaderText: 'Suggested Contacts',
  noResultsFoundText: 'Brak wyników',
  loadingText: 'W trakcie',
  showRemoveButtons: false,
  suggestionsAvailableAlertText: 'Dostępne wyniki kontrolki wyszyukiwania',
  suggestionsContainerAriaLabel: 'Sugerowane kontakty'
};

const maskFormat: { [key: string]: RegExp } = {
  '*': /[0-9]/,
};

export interface IAzurePersonaProps {
  displayName: string;
  giveName: string;
  mail: string;
  surname: string;
  userPrincipalName: string;
  id: string;
  text: string;
}

export interface NewUserFormProps {
  isOpen: boolean;
  authToken: string;
  userAgentApplication: Msal.UserAgentApplication;
  currentShareData: any;
  shareUsersRefreshMethod: any;
  lang: string;
}
export interface NewUserFormState {
  userTypeValue?: string;
  externalUserFieldsVisible: boolean;
  showNewUserModal: boolean;
  emailWrongPatternError?: string;
  emailNotMatchError?: string;
  phoneNotCorrectError?: string;
  peopleFieldEmpty: boolean;
  requiredFieldCount: number;
  showConfirmDialog: boolean;
  showErrorDialog: boolean;
  showMissingDataDialog: boolean;
  showForbiddenEmailDataDialog: boolean;
  exUserFirstName?: string;
  exUserLastName?: string;
  inUserAccountName?: string;
  shareName: string;
  exUserEmailText: string;
  exUserEmailRepeatText: string;
  phoneNumber: string;
  currentPicker?: number | string;
  delayResults?: boolean;
  peopleList: IPersonaProps[];
  mostRecentlyUsed: IPersonaProps[];
  currentSelectedItems?: IPersonaProps[];
  isPickerDisabled?: boolean;
  createUserLoad: boolean;
  fnValueEmpty: boolean;
  lnValueEmpty: boolean;
  emailValueEmpty: boolean;
  email2ValueEmpty: boolean;
  phoneValueEmpty: boolean;
  isUserExternalTemp: boolean;
  toOperator: boolean;
  createAccount: boolean;
  buttonClick: boolean;
  userTypeSelected?: boolean;
}

export class NewUserForm extends React.Component<NewUserFormProps, NewUserFormState> {
  private _newUserTitleId: string = getId('new-folder-title');
  private _newUserSubtitleId: string = getId('new-folder-subtitle');
  private userAgentApplication: Msal.UserAgentApplication;
  private mappedToPersonaItems: IPersonaProps[];
  private _picker = React.createRef<IBasePicker<IPersonaProps>>();
  private firstName: string;
  private lastname: string;

  constructor(props: NewUserFormProps) {
    super(props);

    this.state = {
      showNewUserModal: false,
      peopleFieldEmpty: false,
      requiredFieldCount: 0,
      externalUserFieldsVisible: false,
      showConfirmDialog: true,
      showErrorDialog: true,
      showMissingDataDialog: true,
      showForbiddenEmailDataDialog: true,
      exUserEmailText: "",
      exUserEmailRepeatText: "",
      shareName: "",
      userTypeValue: null,
      phoneNumber: "",
      currentPicker: 1,
      delayResults: false,
      peopleList: [],
      mostRecentlyUsed: [],
      currentSelectedItems: [],
      isPickerDisabled: false,
      createUserLoad: false,
      fnValueEmpty: false,
      lnValueEmpty: false,
      emailValueEmpty: false,
      email2ValueEmpty: false,
      phoneValueEmpty: false,
      isUserExternalTemp: false,
      toOperator: false,
      createAccount: false,
      buttonClick: false,
      userTypeSelected: false
    }
  }


  render() {

    //*** Language section ***
    let resource: any;
    if (this.props.lang == "PL") {
      resource = require("../LangFiles/resourcePL.json");
    } else {
      resource = require("../LangFiles/resource.json");
    }
    //*** end of language section ***

    let currentPicker: JSX.Element | undefined = undefined;
    currentPicker = this._renderNormalPicker();
    const { userTypeSelected, buttonClick, showNewUserModal, showConfirmDialog, showErrorDialog, createUserLoad, showMissingDataDialog, showForbiddenEmailDataDialog, fnValueEmpty, lnValueEmpty, emailValueEmpty, email2ValueEmpty, phoneValueEmpty, peopleFieldEmpty } = this.state;
    return (
      <Modal
        titleAriaId={this._newUserTitleId}
        subtitleAriaId={this._newUserSubtitleId}
        isOpen={showNewUserModal}
        onDismiss={this._closeNewUserModal.bind(this)}
        isModeless={false}
        dragOptions={undefined}
        containerClassName="swpStyle-new-user-modal-window"
        theme={colorThemeLight}
      >
        <div className="swpStyle-upload-file-modal-header">
          <span id={this._newUserTitleId}><h6>{resource.str_HeaderInviteUser}</h6></span>
        </div>
        <ProgressIndicator progressHidden={!createUserLoad} barHeight={5} theme={colorThemeLight} styles={progressStyles} />
        <div id={this._newUserSubtitleId} className="swpStyle-upload-file-modal-body">
          <Container>
            <Row>
              <Col>
                <Dropdown
                  theme={colorThemeLight}
                  placeholder={resource.str_UserTypeLabel}
                  label={resource.str_UserTypeLabel}
                  options={this.props.lang == "PL" ? optionsPL : options}
                  styles={dropdownStyles}
                  onChange={this._onDropDownChoice}
                />
                <br></br>
              </Col>
            </Row>
          </Container>

          {this.state.externalUserFieldsVisible ?
            <Container>
              <Row>
                <Col><TextField theme={colorThemeLight} label={resource.str_LblFirstName} onChange={this._onFirstNameAdded.bind(this)} required validateOnFocusOut onGetErrorMessage={this._getErrorMessage.bind(this)} /><br></br></Col>
                <Col><TextField theme={colorThemeLight} label={resource.str_LblLastName} onChange={this._onLastNameAdded.bind(this)} required validateOnFocusOut onGetErrorMessage={this._getErrorMessage.bind(this)} /><br></br></Col>
              </Row>
              <Row style={{ minHeight: "110px" }}>
                <Col><TextField theme={colorThemeLight} label={resource.str_LblEmail} errorMessage={this.state.emailWrongPatternError} onChange={this._onEmailTextBoxChange.bind(this)} required validateOnFocusOut onGetErrorMessage={this._getErrorMessage.bind(this)} /><br></br></Col>
                <Col><TextField theme={colorThemeLight} label={resource.str_LblRepeatEmail} errorMessage={this.state.emailNotMatchError} onChange={this._onRepeatEmailTextBoxChange.bind(this)} required validateOnFocusOut onGetErrorMessage={this._getErrorMessage.bind(this)} /><br></br></Col>
              </Row>
              {/* <Row>
                <Col><MaskedTextField theme={colorThemeLight} label={resource.str_LblPhone} mask="*********" maskFormat={maskFormat} maskChar="" onChange={this._onPhoneAdded.bind(this)} required validateOnFocusIn errorMessage={this.state.phoneNotCorrectError} /><br></br></Col>
                <Col><div style={{ overflowWrap: "anywhere" }}>{resource.str_MsgPhoneRequirement}</div></Col>
              </Row> */}
            </Container> :
            <Container>
              <Row>
                <Col>
                  {resource.str_LblSearchPerson}
                  {currentPicker}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Toggle theme={colorThemeLight} label={
                    <div>
                      Operator{' '}
                      <TooltipHost content={resource.str_TipOperatorPermissions}>
                        <Icon iconName="Info" aria-label={resource.str_TipOperatorPermissions} />
                      </TooltipHost>
                    </div>
                  }
                    onText={resource.str_ToggleYes} offText={resource.str_ToggleNo} onChange={this._onOperatorToggle.bind(this)} />
                </Col>
              </Row>
            </Container>
          }
          <br></br>

          {/* Submit buttons: */}
          <Stack horizontal tokens={stackTokens} className="swpStyle-modal-buttonParrent">
            {/* <PrimaryButton theme={colorThemeLight} onClick={this._onCreateUserClick.bind(this)} text={resource.str_BtnConfirm} disabled={!fnValueEmpty || !lnValueEmpty || !emailValueEmpty || !email2ValueEmpty || !peopleFieldEmpty || !phoneValueEmpty || buttonClick} /> */}
            <PrimaryButton theme={colorThemeLight} onClick={this._onCreateUserClick.bind(this)} text={resource.str_BtnConfirm} disabled={!userTypeSelected|| !fnValueEmpty || !lnValueEmpty || !emailValueEmpty || !email2ValueEmpty || !peopleFieldEmpty || buttonClick} />
            <DefaultButton theme={colorThemeLight} onClick={this._closeNewUserModal.bind(this)} text={resource.str_BtnCancel} />
          </Stack>

          {/* Dialogs */}
          <Dialog hidden={showConfirmDialog} onDismiss={this._closeNewUserModal} dialogContentProps={dialogContentProps} modalProps={dialogModalProps} title={this.props.lang == "PL" ? gblResourcePL.str_NewUSerFormSentOK : gblResource.str_NewUSerFormSentOK}>
            <Text style={{ overflowWrap: "anywhere" }}>{this.props.lang == "PL" ? gblResourcePL.str_MsgUserAdeddRefreshInfo : gblResource.str_MsgUserAdeddRefreshInfo}</Text>
            <DialogFooter>
              <PrimaryButton theme={colorThemeLight} onClick={this._closeNewUserModal} text={resource.str_BtnOK} />
            </DialogFooter>
          </Dialog>
          <Dialog hidden={showErrorDialog} onDismiss={this._closeNewUserModal} dialogContentProps={dialogErrorContentProps} modalProps={dialogModalProps} title={this.props.lang == "PL" ? gblResourcePL.str_MsgUserExistErrorTitle : gblResource.str_MsgUserExistErrorTitle}>
            <Text style={{ overflowWrap: "anywhere" }}>{this.props.lang == "PL" ? gblResourcePL.str_MsgUserExistError : gblResource.str_MsgUserExistError}</Text>
            <DialogFooter>
              <PrimaryButton theme={colorThemeLight} onClick={this._closeErrorDialog} text={resource.str_BtnOK} />
            </DialogFooter>
          </Dialog>
          <Dialog hidden={showMissingDataDialog} onDismiss={this._closeNewUserModal} dialogContentProps={missingDataDialogContentProps} modalProps={dialogModalProps} title={this.props.lang == "PL" ? gblResourcePL.str_WrnFieldsEmptyTitle : gblResource.str_WrnFieldsEmptyTitle}>
            <Text style={{ overflowWrap: "anywhere" }}>{this.props.lang == "PL" ? gblResourcePL.str_WrnFieldsEmpty : gblResource.str_WrnFieldsEmpty}</Text>
            <DialogFooter>
              <PrimaryButton theme={colorThemeLight} onClick={this._closeErrorDialog} text={resource.str_BtnOK} />
            </DialogFooter>
          </Dialog>
          <Dialog hidden={showForbiddenEmailDataDialog} onDismiss={this._closeNewUserModal} dialogContentProps={ForbiddenEmailDataDialogContentProps} modalProps={dialogModalProps} title={this.props.lang == "PL" ? gblResourcePL.str_WrnForbiddenEmailTitle : gblResource.str_WrnForbiddenEmailTitle}>
            <Text style={{ overflowWrap: "anywhere" }}>{this.props.lang == "PL" ? gblResourcePL.str_WrnForbiddenEmail : gblResource.str_WrnForbiddenEmail}</Text>
            <DialogFooter>
              <PrimaryButton theme={colorThemeLight} onClick={this._closeErrorDialog} text={resource.str_BtnOK} />
            </DialogFooter>
          </Dialog>
        </div>
      </Modal>
    )
  }

  private _renderNormalPicker() {
    return (
      <NormalPeoplePicker
        onResolveSuggestions={this._onFilterChanged}
        onEmptyInputFocus={null}
        getTextFromItem={this._getTextFromItem}
        pickerSuggestionsProps={suggestionProps}
        className={'ms-PeoplePicker'}
        //key={'normal'}
        //onChange={this._onItemsChange}
        onValidateInput={this._validateInput}
        removeButtonAriaLabel={'Remove user item'}
        onItemSelected={this._onItemSelected}
        componentRef={this._picker}
        //onInputChange={null}
        resolveDelay={100}
        disabled={this.state.isPickerDisabled}
        itemLimit={1}
        inputProps={{
          onFocus: () => this._onItemDismissed(),
          onBlur: () => this._onItemDismissed(),
          'aria-label': "People picker control - search for user"
        }}
        theme={colorThemeLight}
      />
    );
  }

  private _onCreateUserClick() {
    //*** Language section ***
    let langfile: any;
    if (this.props.lang == "PL") {
      langfile = require("../LangFiles/resourcePL.json");
    } else {
      langfile = require("../LangFiles/resource.json");
    }
    //*** end of language section ***
    config.DEBUG && console.log("_onCreateUserClick");
    let graphService: GraphService = new GraphService(this.props.authToken, this.props.userAgentApplication);
    if (this.state.userTypeValue == langfile.str_UserTypePartnerKey) {
      //if Internal 
      this.setState({ createUserLoad: true, buttonClick: true }) //start load:

      graphService.getSpecificUsersFromInvitations(this.props.currentShareData.IdentyfikatorGrupy, this.state.inUserAccountName, this.state.userTypeValue).then(result => {
        if (result.value.length > 0) {
          config.DEBUG && console.log(result)
          this.setState({ createUserLoad: false }) //finish load
          this._showErrorDialog(); //showing error (user exist) dialog
        } else {
          //no existing users, creating new one:
          if (this.state.inUserAccountName != null) {
            graphService.newUserRequest(this.firstName, this.lastname, this.state.userTypeValue, null, null, this.props.currentShareData.id, this.state.inUserAccountName, this.props.currentShareData.IdentyfikatorGrupy, "", "tak", this.state.toOperator).then(result => {
              this.setState({ createUserLoad: false, buttonClick: false }) //finish load
              this._showDialog(); //showing correct submit confirm dialog
            })
          } else {
            config.DEBUG && console.log("User account value not registered")
            this.setState({ createUserLoad: false, buttonClick: false }) //finish load
            this._showMissingDataDialog(); //showing error - user account value missing
          }
        }
      })
      ///////

    } else if (this.state.userTypeValue == langfile.str_UserTypeExternalKey) {
      //If external
      this.setState({ createUserLoad: true, buttonClick: true }) //start load

      //first test agains nbp address in email field
      var pattern = new RegExp("@nbp.pl")
      if (this.state.exUserEmailText.toString().toLocaleLowerCase().match(pattern)) {
        this.setState({ createUserLoad: false, buttonClick: false }) //finish load
        this._showForbiddenEmailDataDialog();
      } else {
        graphService.getSpecificUsersFromInvitations(this.props.currentShareData.IdentyfikatorGrupy, this.state.exUserEmailText, this.state.userTypeValue).then(result => {
          config.DEBUG && console.log(result.value)
          if (result.value.length && result.value.length > 0) {
            config.DEBUG && console.log(result)
            this.setState({ createUserLoad: false, buttonClick: false }) //finish load
            this._showErrorDialog(); //showing error (user exist) dialog
          } else {
            //no existing users, creating new one:
            config.DEBUG && console.log("Adding External: " + this.state.exUserEmailText)
            config.DEBUG && console.log(this.props.currentShareData)
            graphService.newUserRequest(this.state.exUserFirstName, this.state.exUserLastName, this.state.userTypeValue, this.state.exUserEmailText, this.state.phoneNumber, this.props.currentShareData.id, null, this.props.currentShareData.IdentyfikatorGrupy, "", "tak", this.state.toOperator).then(result => {
              this.setState({ createUserLoad: false, buttonClick: false }) //finish load
              this._showDialog(); //showing correct submit confirm dialog
            })
          }
        })
      }
    } else {
      //User type not picked
      config.DEBUG && console.log("$$$$ ERROR, no user type picked $$$$$")
      this._showMissingDataDialog(); //showing error - user type value missing
    }

  }

  private _onItemDismissed = (): void => {
    this.setState({
      peopleFieldEmpty: false,
      fnValueEmpty: false,
      lnValueEmpty: false,
      email2ValueEmpty: false,
      emailValueEmpty: false,
      toOperator: false
    });
  };

  private _onItemSelected = (item: IPersonaProps): Promise<IPersonaProps> => {
    const processedItem = { ...item };
    processedItem.primaryText = item.primaryText;
    config.DEBUG && console.log(this.props.currentShareData);

    this.setState({ inUserAccountName: item.secondaryText }, () => {
      this.setState({
        peopleFieldEmpty: true,
        fnValueEmpty: true,
        lnValueEmpty: true,
        email2ValueEmpty: true,
        emailValueEmpty: true,
        phoneValueEmpty: true
      });
      config.DEBUG && console.log(this.state.inUserAccountName);
      this.firstName = item.primaryText.toString().split(",")[1];
      this.lastname = item.primaryText.toString().split(",")[0];
      config.DEBUG && console.log(this.firstName + " " + this.lastname);
    });

    return new Promise<IPersonaProps>((resolve, reject) => setTimeout(() => resolve(processedItem), 250));
  };

  private _getPeopleList = (inputText: string): any => {
    var graphService = new GraphService(this.props.authToken, this.userAgentApplication);
    graphService.getUsersFromAAD(inputText).then(
      result => {
        config.DEBUG && console.log(result);
        this.mappedToPersonaItems = new Array();
        this.mappedToPersonaItems = result.value.map(function (val: any) {
          //config.DEBUG && console.log("***Imie i Nazwisko ***" + val.givenName + " " + val.surname);
          var initials = ""
          if (val.givenName != null && val.givenName != undefined && val.surname != null && val.surname != undefined) {
            initials = val.givenName.substring(0, 1) + val.surname.substring(0, 1)
          } else {
            initials = "."
          }
          var claims = "i:0#.f|membership|" + val.userPrincipalName
          return {
            imageInitials: initials,
            primaryText: val.displayName,
            secondaryText: val.userPrincipalName,
            optionalText: claims
          }
        })
      }
    );
    this.setState({
      peopleList: this.mappedToPersonaItems
    })
    config.DEBUG && console.log(this.state.peopleList);
  }

  //#region AllExceptPicker fields

  private _closeNewUserModal = (): void => {
    this.setState({
      showNewUserModal: false,
      showConfirmDialog: true,
      userTypeValue: null,
      externalUserFieldsVisible: false,
      fnValueEmpty: false,
      lnValueEmpty: false,
      email2ValueEmpty: false,
      emailValueEmpty: false,
      peopleFieldEmpty: false,
      createUserLoad: false,
      buttonClick: false,
      toOperator: false,
      userTypeSelected: false
    });
  }

  public _openNewUserModal() {
    this.setState({ showNewUserModal: true });
  }

  private _onDropDownChoice = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
    this.setState({ userTypeValue: item.title }, () => { //inserting key not text to avoid issues in Flow that checks for user type (spaces not recognized properly)
      config.DEBUG && console.log("--- User type picked: " + this.state.userTypeValue)
      this.setState({userTypeSelected: true})
    });
    if (item.title == gblResourcePL.str_UserTypeExternalKey) {
      config.DEBUG && console.log("--- External ----")
      this.setState({
        externalUserFieldsVisible: true,
        peopleFieldEmpty: false,
        fnValueEmpty: false,
        lnValueEmpty: false,
        email2ValueEmpty: false,
        emailValueEmpty: false,
        phoneValueEmpty: false
      });
    } else {
      config.DEBUG && console.log("--- Other ----")
      this.setState({
        externalUserFieldsVisible: false,
        peopleFieldEmpty: false,
        fnValueEmpty: false,
        lnValueEmpty: false,
        email2ValueEmpty: false,
        emailValueEmpty: false,
        phoneValueEmpty: false
      });
    }
  };

  private _onFirstNameAdded(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.length > 0) {
      this.setState({
        exUserFirstName: event.target.value,
        fnValueEmpty: true,
        peopleFieldEmpty: true
      })
    } else {
      this.setState({
        fnValueEmpty: false,
        peopleFieldEmpty: false
      })
    }
  }

  private _onLastNameAdded(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.length > 0) {
      this.setState({
        exUserLastName: event.target.value,
        lnValueEmpty: true,
        peopleFieldEmpty: true,
      })
    } else {
      this.setState({
        lnValueEmpty: false,
        peopleFieldEmpty: false
      })
    }
  }

  private _onEmailTextBoxChange(event: React.ChangeEvent<HTMLInputElement>) {
    var pattern = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");
    if (event.target.value.toString().toLocaleLowerCase().match(pattern) != null && event.target.value) {
      this.setState({
        exUserEmailText: event.target.value,
        emailWrongPatternError: "",
        email2ValueEmpty: true,
        peopleFieldEmpty: true
      })
    } else {
      this.setState({
        emailWrongPatternError: this.props.lang == "PL" ? gblResourcePL.str_ErrIncorrectEmailFormat : gblResource.str_ErrIncorrectEmailFormat,
        email2ValueEmpty: false,
        peopleFieldEmpty: false
      })
    }
  }

  private _onRepeatEmailTextBoxChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value == this.state.exUserEmailText) {
      this.setState({
        exUserEmailRepeatText: event.target.value,
        emailNotMatchError: "",
        emailValueEmpty: true,
        peopleFieldEmpty: true
      })
    } else {
      this.setState({
        emailNotMatchError: this.props.lang == "PL" ? gblResourcePL.str_ErrEmailDoesntMatch : gblResource.str_ErrEmailDoesntMatch,
        emailValueEmpty: false,
        peopleFieldEmpty: false
      })
    }
  }

  private _onOperatorToggle(ev: React.MouseEvent<HTMLElement>, checked: boolean) {
    console.log('Operator toggle is ' + (checked ? 'checked' : 'not checked'));
    if (checked) {
      this.setState({ toOperator: true });
    } else {
      this.setState({ toOperator: false });
    }
  }

  private _onPhoneAdded(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.length == 9) {
      config.DEBUG && console.log(event.target.value.length)
      this.setState({
        phoneNotCorrectError: "",
        phoneNumber: event.target.value,
        phoneValueEmpty: true,
        peopleFieldEmpty: true,
      })
    } else {
      config.DEBUG && console.log(event.target.value.length)
      this.setState({
        phoneNotCorrectError: this.props.lang == "PL" ? gblResourcePL.str_ErrIncorrectPhone : gblResource.str_ErrIncorrectPhone,
        phoneValueEmpty: false,
        peopleFieldEmpty: false
      })
    }
  }

  private _getErrorMessage = (value: string): string => {
    if (value.length == 0) {
      return this.props.lang == "PL" ? gblResourcePL.str_ErrFieldRequired : gblResource.str_ErrFieldRequired
    }
  };

  //#endregion AllExceptPicker fields

  //#region - Dialogs

  private _showDialog = (): void => {
    this.setState({
      showConfirmDialog: false,
      userTypeValue: null
    });

  }

  private _showErrorDialog = (): void => {
    this.setState({ showErrorDialog: false });
  }

  private _showMissingDataDialog = (): void => {
    this.setState({ showMissingDataDialog: false })
  }

  private _showForbiddenEmailDataDialog = (): void => {
    this.setState({ showForbiddenEmailDataDialog: false })
  }
  //showForbiddenEmailDataDialog

  private _closeErrorDialog = (): void => {
    this.setState({
      showErrorDialog: true,
      showMissingDataDialog: true,
      showForbiddenEmailDataDialog: true
    });
  }

  //#endregion - Dialogs

  //#region - peoplePicker field typing actions

  private _getTextFromItem(persona: IPersonaProps): string {
    return persona.primaryText as string;
  }
  private _onFilterChanged = (filterText: string, currentPersonas: IPersonaProps[], limitResults?: number): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      let filteredPersonas: IPersonaProps[] = this._filterPersonasByText(filterText);
      filteredPersonas = this._removeDuplicates(filteredPersonas, currentPersonas);
      filteredPersonas = limitResults ? filteredPersonas.splice(0, limitResults) : filteredPersonas;

      return this._filterPromise(filteredPersonas);

    } else {
      return [];
    }
  };

  private _filterPromise(personasToReturn: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> {
    if (this.state.delayResults) {
      return this._convertResultsToPromise(personasToReturn);
    } else {
      return personasToReturn;
    }
  }

  private _listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
    if (!personas || !personas.length || personas.length === 0) {
      return false;
    }
    return personas.filter(item => item.primaryText === persona.primaryText).length > 0;
  }

  private _filterPersonasByText(filterText: string): IPersonaProps[] {
    this._getPeopleList(filterText);
    return this.state.peopleList.filter(item => this._doesTextStartWith(item.primaryText as string, filterText));
  }

  private _doesTextStartWith(text: string, filterText: string): boolean {
    return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
  }

  private _convertResultsToPromise(results: IPersonaProps[]): Promise<IPersonaProps[]> {
    return new Promise<IPersonaProps[]>((resolve, reject) => setTimeout(() => resolve(results), 2000));
  }

  private _removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
    return personas.filter(persona => !this._listContainsPersona(persona, possibleDupes));
  }

  private _validateInput = (input: string): ValidationState => {
    config.DEBUG && console.log("Validating");
    if (input.indexOf('@') !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  };

  //#endregion - peoplePicker field typing actions




}