import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import * as Msal from "msal";
import { Fabric } from '@fluentui/react/lib/Fabric';
import { Announced } from '@fluentui/react/lib/Announced';
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { GraphService } from "./GraphService";
import { Icon } from '@fluentui/react/lib/Icon';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { getFileTypeIconProps, FileIconType, initializeFileTypeIcons } from '@uifabric/file-type-icons';
import {
  MessageBar,
  MessageBarType,
  CommandButton,
  IContextualMenuProps,
  IIconProps,
  Stack, IStackStyles, IStackTokens, IStackProps,
  Dialog,
  DialogFooter,
  DialogType
} from '@fluentui/react';
import * as moment from 'moment';
import { Modal } from '@fluentui/react/lib/Modal';
import { getId } from '@fluentui/react/lib/Utilities';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { DefaultButton, PrimaryButton, CompoundButton } from '@fluentui/react/lib/Button';
import { Breadcrumb as BreadcrumbO365, IBreadcrumbItem, IBreadcrumbStyles } from '@fluentui/react/lib/Breadcrumb';
import { LoadingSpinner } from "./LoadingSpinner";
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import { NewFolderForm } from "../Forms/NewFolderForm";
import { RenameItemForm } from "../Forms/RenameItemForm";
import { ProgressIndicator, IProgressIndicatorStyles } from '@fluentui/react/lib/ProgressIndicator';
import { ShareDetails } from './ShareDetails';
import { colorThemeLight } from './Themes';
import { ProgressBar } from "./ProgressBar";
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip'
import * as JSZipUtils from 'jszip-utils'

initializeIcons();
initializeFileTypeIcons();

const config = require("./config.json");
const gblResource = require("../LangFiles/resource.json");
const gblResourcePL = require("../LangFiles/resourcePL.json");

const { detect } = require('detect-browser');

const settIcon: IIconProps = { iconName: 'Settings' };
const uploadIcon: IIconProps = { iconName: 'Upload' };
const downloadIcon: IIconProps = { iconName: 'Download' };
const newFolderIcon: IIconProps = { iconName: 'NewFolder' };
const deleteIcon: IIconProps = { iconName: 'Delete' };
const renameIcon: IIconProps = { iconName: 'Rename' };

const browser = detect();

//#region Styles
const stackStyles: Partial<IStackStyles> = { root: { height: 44 } };
const stackTokens: IStackTokens = { childrenGap: 10 };

const restoreDialogContentProps = {
  type: DialogType.normal,
  subText: 'Procedura odtworzenia obszaru została uruchomiona. Data dezaktywacji ustawiona na 30 dni od dzisiaj.'
};

const restoreDialogModalProps = {
  isBlocking: true,
  styles: { main: { minWidth: 250, minHeight: 50 } }
};

const verticalStackProps: IStackProps = {
  styles: { root: { overflow: 'hidden', width: '100%' } },
  tokens: { childrenGap: 20 }
};

const folderBreadCrumbStyles: Partial<IBreadcrumbStyles> = {
  itemLink:
  {
    fontSize: 20,
    color: '#152e52'
  },
  chevron: {
    fontSize: 16
  }
};

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px'
  },
  fileIconCell: {
    textAlign: 'center',
    height: '20px',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden'
      }
    }
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    //minHeight: '20px',
    maxWidth: '16px'
  },
  cancelProgressIcon: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    //minHeight: '20px',
    maxWidth: '16px'
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px'
  },
  selectionDetails: {
    marginBottom: '10px',
    marginTop: '10px',
    paddingLeft: '8px',
    color: 'black'
  }
});

const progressStyles: Partial<IProgressIndicatorStyles> = {
  itemProgress: { paddingTop: 0 }
};

//#endregion Styles

//#region Messages

interface IMessageBarProps {
  fileName?: string;
  closeMsg?: any;
  langData?: any;
}

const MessageBarProgressIndicatorFoldersDown = (p: IMessageBarProps) => (
  <ProgressIndicator label={p.langData.str_MsgDownloadingInProgressFolders}></ProgressIndicator>
);

const MessageBarUploadSuccess = (p: IMessageBarProps) => (//need to add another prop for multiple files
  <MessageBar
    messageBarType={MessageBarType.success}
    isMultiline={true}
    dismissButtonAriaLabel={p.langData.str_BtnDismiss}
    onDismiss={p.closeMsg}
  >
    {p.langData.str_MsgUploadSuccessPart1} {p.fileName} {p.langData.str_MsgUploadSuccessPart2}
  </MessageBar>
);

const MessageBarRemoveSuccess = (p: IMessageBarProps) => (
  <MessageBar
    messageBarType={MessageBarType.success}
    isMultiline={false}
    dismissButtonAriaLabel={p.langData.str_BtnDismiss}
    onDismiss={p.closeMsg}
  >
    {p.langData.str_MsgFilesDeletedSuccess}
  </MessageBar>
);

const MessageBarUploadSuccessMulti = (p: IMessageBarProps) => (//need to add another prop for multiple files
  <MessageBar
    messageBarType={MessageBarType.success}
    isMultiline={false}
    dismissButtonAriaLabel={p.langData.str_BtnDismiss}
    onDismiss={p.closeMsg}
  >
    {p.langData.str_MsgFilesUploadedSucces}
  </MessageBar>
);

const MessageBarUploadSuccessFolders = (p: IMessageBarProps) => (//need to add another prop for multiple files
  <MessageBar
    messageBarType={MessageBarType.success}
    isMultiline={false}
    dismissButtonAriaLabel={p.langData.str_BtnDismiss}
    onDismiss={p.closeMsg}
  >
    {p.langData.str_MsgFoldersUploadedSucces}
  </MessageBar>
);

const MessageBarDownloadSuccessMulti = (p: IMessageBarProps) => (//need to add another prop for multiple files
  <MessageBar
    messageBarType={MessageBarType.success}
    isMultiline={false}
    dismissButtonAriaLabel={p.langData.str_BtnDismiss}
    onDismiss={p.closeMsg}
  >
    {p.langData.str_MsgFoldersDownloadedSuccess}
  </MessageBar>
);

const MessageBarDownloadSuccess = (p: IMessageBarProps) => (//need to add another prop for multiple files
  <MessageBar
    messageBarType={MessageBarType.success}
    isMultiline={false}
    dismissButtonAriaLabel={p.langData.str_BtnDismiss}
    onDismiss={p.closeMsg}
  >
    {p.langData.str_MsgFoldersDownloadedSuccessNonZip}
  </MessageBar>
);

const MessageBarUploadError = (p: IMessageBarProps) => (//need to add another prop for multiple files
  <MessageBar
    messageBarType={MessageBarType.error}
    isMultiline={false}
    dismissButtonAriaLabel={p.langData.str_BtnDismiss}
    onDismiss={p.closeMsg}>
    {p.langData.str_MsgErrorOnUpload} {p.fileName}
  </MessageBar>
);
const MessageBarDownloadError = (p: IMessageBarProps) => (//need to add another prop for multiple files
  <MessageBar
    messageBarType={MessageBarType.error}
    isMultiline={false}
    dismissButtonAriaLabel={p.langData.str_BtnDismiss}
    onDismiss={p.closeMsg}>
    {"Error downloading files"}
  </MessageBar>
);

//#endregion messages

export interface IDocument {
  key: string;
  name: string;
  value: string;
  iconName: string;
  fileType: string;
  modifiedBy: string;
  dateModified: string;
  dateModifiedValue: number;
  fileSize: string;
  fileSizeRaw: number;
  downloadUrl: string;
  webUrl: string;
  isFolder: boolean;
  itemId: string;
  webDavUrl: string;
}

export interface FileBrowserProps {
  shareName: string,
  shareGroupId: string,
  //shareSiteId: string,
  backLinkMethod: any,
  authToken: string,
  currentUserUPN: string,
  isAdmin: boolean,
  isOperator: boolean,
  // isOperatorAccessOnly: boolean,
  fileBrowserRefresh: any,
  lang: string,
};
export interface FileBrowserState {
  visible: boolean;
  shareName: string;
  columns: IColumn[];
  items: IDocument[];
  selectionDetails: string;
  isModalSelection: boolean;
  isCompactMode: boolean;
  announcedMessage?: string;
  showAddItemModal: boolean;
  showAddFolderModal: boolean;
  showDelItemModal: boolean;
  showNewFolderModal: boolean;
  uploadPending?: boolean;
  uploadSuccessfull?: boolean;
  downloadPending?: boolean;
  downloadSuccessfull?: boolean;
  downloadSuccessfullNonZipped?: boolean;
  uploadError?: boolean;
  downloadError?: boolean;
  removeSuccessfull?: boolean;
  uploadPendingFileName?: string;
  driveId?: string;
  currentSiteId?: string;
  currentFolderId?: string;
  currentFolderName?: string;
  delButtonDisabled: boolean;
  renameButtonDisabled: boolean;
  downloadButtonDisabled: boolean;
  breadcrumbItems: IBreadcrumbItem[];
  //folderPath?: string[];
  loadingState: boolean;
  showRenameItemModal: boolean;
  selectedFiles?: IDocument[];
  multiFile: boolean;
  multiFoldersFiles: boolean;
  singleFile: boolean;
  newFolderErrorMessage?: string;
  newFolderTextBoxText?: string;
  role: number;
  rowClicked: boolean;
  contextualMenuProps?: IContextualMenuProps;
  shareStateDiabled: boolean;
  currentShareState: string;
  curentShareURL: string;
  currentShareTitle: string;
  createUserLoad: boolean;
  pbFileSize?: number;
  pbTotalSize?: number;
  pbChunkSize?: number;
  pbChunkStart?: number;
  pbNextStartPoint: number;
  shareDeleteInProgress?: string;
  lang: any;
  shareSoftDelete: string;
  currentShareItem: any;
  // langCodeState: string;
  userAlertState: number;
  inviteId?: number;
  restoreConfirmDlgDisabled: boolean;
  restoreButtonDisabled: boolean;
  restoreInProgress: boolean;
};

export class FileBrowser extends React.Component<FileBrowserProps, FileBrowserState> {
  private msalConfig: Msal.Configuration;
  private userAgentApplication: Msal.UserAgentApplication;
  private _selection: Selection;
  private _allItems: IDocument[];
  private folderTreeItems: any[];
  private _titleId: string = getId('title');
  private _subtitleId: string = getId('subText');
  private _delItemTitleId: string = getId('del-title');
  private _delItemSubtitleId: string = getId('del-subText');
  private _uploadFolderTitleId: string = getId('folder-title');
  private _uploadFolderSubtitleId: string = getId('folder-subText');
  private driveId: string;
  private folderPath = new Array<string>();
  private newFolderForm = React.createRef<NewFolderForm>();
  private renameItemForm = React.createRef<RenameItemForm>();

  constructor(props: FileBrowserProps) {
    super(props);

    this.msalConfig = {
      auth: {
        clientId: config.appId,
        authority: config.authority
      }
    }

    this.forceRefreshFileList.bind(this);

    this._allItems = [];

    this.userAgentApplication = new Msal.UserAgentApplication(this.msalConfig);

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      }

    });

    const columns: IColumn[] = [
      {
        key: 'column1',
        name: 'Typ',
        className: classNames.fileIconCell,
        iconClassName: classNames.fileIconHeaderIcon,
        ariaLabel: 'Column operations for File type, Press to sort on File type',
        iconName: 'Page',
        isIconOnly: true,
        fieldName: 'name',
        minWidth: 16,
        maxWidth: 16,
        onColumnClick: this._onColumnClick,
        onRender: (item: IDocument) => {
          if (item.fileType == "folder")
            return (
              <Icon className="swpStyle-file-browser-icon" {...getFileTypeIconProps({ type: FileIconType.folder, size: 16, imageFileType: 'svg' })} />
            );
          else
            return (
              <a href={item.webUrl} target="_blank">
                <Icon className="swpStyle-file-browser-icon" {...getFileTypeIconProps({ extension: item.fileType, size: 16, imageFileType: 'svg' })} />
              </a>
            );
        }
      },
      {
        key: 'column2',
        name: this.props.lang == "PL" ? gblResourcePL.str_ColTitle : gblResource.str_ColTitle,
        fieldName: 'name',
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IDocument) => {
          if (item.isFolder) {
            return <a id={item.itemId} data-name={item.name} className="swpStyle-file-download-link" onClick={this._onFolderClick.bind(this)}>{item.name}</a>
          } else {
            return <a href={item.webUrl} target="_blank" className="swpStyle-file-download-link">{item.name}</a>
          }
        }
      },
      {
        key: 'column3',
        name: this.props.lang == "PL" ? gblResourcePL.str_ColModified : gblResource.str_ColModified,
        fieldName: 'dateModifiedValue',
        minWidth: 90,
        maxWidth: 110,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'number',
        onRender: (item: IDocument) => {
          return <span>{item.dateModified}</span>;
        },
        isPadded: true
      },
      {
        key: 'column4',
        name: this.props.lang == "PL" ? gblResourcePL.str_ColModifiedBy : gblResource.str_ColModifiedBy,
        fieldName: 'modifiedBy',
        minWidth: 100,
        maxWidth: 120,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        onColumnClick: this._onColumnClick,
        onRender: (item: IDocument) => {
          return <span>{item.modifiedBy}</span>;
        },
        isPadded: true
      },
      {
        key: 'column5',
        name: this.props.lang == "PL" ? gblResourcePL.str_ColSize : gblResource.str_ColSize,
        fieldName: 'fileSizeRaw',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'number',
        onColumnClick: this._onColumnClick,
        onRender: (item: IDocument) => {
          return <span>{item.fileSize}</span>;
        }
      }
    ];

    this.state = {
      visible: false,
      shareName: "",
      items: this._allItems,
      selectionDetails: this._getSelectionDetails(),
      isModalSelection: false,
      isCompactMode: false,
      announcedMessage: undefined,
      showAddItemModal: false,
      showAddFolderModal: false,
      showNewFolderModal: false,
      uploadPending: false,
      uploadSuccessfull: false,
      uploadError: false,
      downloadError: false,
      delButtonDisabled: true,
      renameButtonDisabled: true,
      downloadButtonDisabled: true,
      breadcrumbItems: [
        { text: this.props.lang == "PL" ? gblResourcePL.str_LblDocs : gblResource.str_LblDocs, key: 'Files', onClick: this._onBreadcrumbItemClicked.bind(this), isCurrentItem: true }
      ],
      currentFolderId: null,
      currentFolderName: null,
      loadingState: false,
      showRenameItemModal: false,
      showDelItemModal: false,
      removeSuccessfull: false,
      multiFile: false,
      multiFoldersFiles: false,
      singleFile: false,
      role: 1,
      rowClicked: false,
      shareStateDiabled: false,
      currentShareState: "Zapis",
      curentShareURL: null,
      currentShareTitle: "",
      createUserLoad: false,
      pbNextStartPoint: 0,
      shareDeleteInProgress: "nie",
      lang: gblResourcePL,
      // langCodeState: this.props.lang,
      columns: columns,
      shareSoftDelete: "0",
      currentShareItem: null,
      userAlertState: 0,
      restoreConfirmDlgDisabled: true,
      restoreButtonDisabled: false,
      restoreInProgress: false
    }
  }

  async componentDidMount() {
    config.DEBUG && console.log("FileBrowser mounted");

    //*** Language section ***
    if (this.props.lang == "PL") {
      this.setState({
        lang: gblResourcePL
      })
    } else {
      this.setState({
        lang: gblResource
      })
    }
    //*** end of language section ***
    this.checkIfCurrentUserIsShareOperator();
    await this.getDriveId();
    await this.getDocuments().then(
      result => {
        this.setState(
          {
            items: this._allItems
          });
      }
    );

    config.DEBUG && console.log("Props: " + this.state.currentSiteId + " " + this.props.shareGroupId + " " + this.props.shareName);

    if(this.state.role == 0){
      await this.verifyAlertState();
    }

    // this.setState({
    //   items: this._allItems
    //   //driveId: this.driveId,
    // })

    config.DEBUG && console.log("Current state, DriveID: " + this.state.driveId);

  }


  render() {

    //*** Language section ***
    let resource: any;
    if (this.props.lang == "PL") {
      resource = gblResourcePL;
    } else {
      resource = gblResource;
    }
    //*** end of language section ***

    function addDays(date: Date, days: number) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }
    config.DEBUG && console.log(this.state.currentShareItem)
    var hardDeleteDay: any;
    if (this.state.currentShareItem !== null) {
      var closureDate = new Date(this.state.currentShareItem.DataZamkniecia)
      hardDeleteDay = addDays(closureDate, 30)
    }


    const {
      columns,
      items,
      createUserLoad,
      selectionDetails,
      currentShareState,
      curentShareURL,
      contextualMenuProps,
      announcedMessage,
      showDelItemModal,
      showAddItemModal,
      showAddFolderModal,
      showNewFolderModal,
      showRenameItemModal,
      downloadButtonDisabled,
      shareDeleteInProgress,
      shareSoftDelete,
      currentShareItem,
      restoreConfirmDlgDisabled,
      restoreButtonDisabled,
      restoreInProgress } = this.state;

    //Setting share status values based on ShareStatus field:  
    let shareDisabled = null;
    if (currentShareState == resource.str_ShareStatusDisabled) {
      shareDisabled = true;
    } else {
      shareDisabled = false;
    }

    const adminMenuProps: IContextualMenuProps = {
      shouldFocusOnMount: false,
      theme: colorThemeLight,
      items: [
        {
          key: 'siteSettings',
          text: this.props.lang == "PL" ? 'SharePoint - Ustawienia' : 'SharePoint - Site Settings',
          iconProps: { iconName: 'SharepointLogo' },
          target: "_blank",
          href: curentShareURL + config.spoLayoutsSettings
        },
        {
          key: 'sitePermissions',
          text: this.props.lang == "PL" ? 'SharePoint - Uprawnienia' : 'SharePoint - Site Permissions',
          iconProps: { iconName: 'SharepointLogo' },
          target: "_blank",
          href: curentShareURL + config.spoLayoutsUsers
        }
      ]
    };

    const uploadMenuProps: IContextualMenuProps = {
      shouldFocusOnMount: false,
      items: [
        {
          key: 'files',
          text: this.props.lang == "PL" ? 'Pliki' : 'Files',
          disabled: shareDisabled,
          onClick: this._showUploadFileModal
        },
        {
          key: 'folders',
          text: this.props.lang == "PL" ? 'Foldery' : 'Folders',
          disabled: shareDisabled || browser.name == "ie",
          title: browser.name == "ie" ? resource.str_MsgIncompatibileBroswer : "",
          onClick: this._showUploadFolderModal //to change for Folder specific form
        }
      ]
    };

    return (
      <Container>
        <Row>
          <Col>
              <Breadcrumb bsPrefix="swpStyle-wide-panel breadcrumb">
                <Breadcrumb.Item style={{ color: '#152e52'}} onClick={this.props.backLinkMethod}>{resource.str_HeaderMainPage}</Breadcrumb.Item>
                {/* <Breadcrumb.Item active >{this.state.currentShareTitle}</Breadcrumb.Item> */}
                <Breadcrumb.Item active >{this.props.shareName}</Breadcrumb.Item>
              </Breadcrumb>

            {/* Only show the rest of the page if Share is not being deleted */}
            {this.state.shareDeleteInProgress == "tak" ?
              <div className="swpStyle-shared-deleted">
                <p>&nbsp;</p>
                <p>{resource.str_WrnShareBeingDeleted}</p>
              </div> :
              <div>
                {this.props.isAdmin || this.state.role == 0 ?
                  <ShareDetails
                    shareGroupId={this.props.shareGroupId}
                    authToken={this.props.authToken}
                    refreshMethod={this.props.backLinkMethod}
                    shareListRefreshMethod={this._forceRefreshShareStateValues.bind(this)}
                    alertRefreshMethod={this.verifyAlertState.bind(this)}
                    lang={this.props.lang}
                    currentUserUPN={this.props.currentUserUPN}
                    userAlertState={this.state.userAlertState}
                    inviteId={this.state.inviteId}
                  /> :
                  <div></div>
                }
                {this.state.currentShareState != resource.str_ShareStatusDisabled ?
                  <div></div> :
                  <div className="swpStyle-readonly-header"><h4 className="swpStyle-readonly-header-content">{resource.str_HeaderShareDisabled}</h4></div>
                }

                {shareSoftDelete == "0" ?
                  <Fabric>
                    <div style={{ display: 'flex' }}>
                      <Stack horizontal styles={stackStyles} wrap={true}>
                        <CommandButton iconProps={uploadIcon} text={resource.str_BtnUpload} menuProps={uploadMenuProps} disabled={shareDisabled} theme={colorThemeLight}/>
                        <CommandButton iconProps={downloadIcon} text={resource.str_BtnDownload} disabled={downloadButtonDisabled} onClick={this._onDownloadItemClick.bind(this)} theme={colorThemeLight}/>
                        <CommandButton iconProps={newFolderIcon} text={resource.str_BtnNewFolder} disabled={shareDisabled} onClick={this._showNewFolderModal} theme={colorThemeLight}/>
                        <CommandButton iconProps={deleteIcon} text={resource.str_BtnDelete} disabled={this.state.role == 1 || this.state.delButtonDisabled || shareDisabled} onClick={this._showDelItemModal} theme={colorThemeLight}/>
                        <CommandButton iconProps={renameIcon} text={resource.str_ButtonChange} disabled={this.state.role == 1 || this.state.renameButtonDisabled || shareDisabled} onClick={this._showRenameItemModal} theme={colorThemeLight}/>

                        {this.props.isAdmin &&
                          <CommandButton 
                            iconProps={settIcon} 
                            text={this.props.lang == "PL" ? "Administracja" : "Admin tasks"} 
                            menuProps={adminMenuProps}
                            theme={colorThemeLight}/>
                        }
                        {/* Below button is a test for uploading progress bar  */}
                        {/* <CommandBarButton iconProps={refreshIcon} text="Przekazywanie danych" disabled={null} checked={null} style={{minWidth: '100px', backgroundColor: colorThemeLight.palette.themeLighterAlt}}/> */}
                      </Stack>
                      <div className="search-box">
                        <SearchBox placeholder={resource.str_PhSearch} onChange={this._onChangeText} disableAnimation={true} theme={colorThemeLight}/>
                      </div>
                    </div>

                    <NewFolderForm
                      isOpen={showNewFolderModal}
                      ref={this.newFolderForm}
                      driveId={this.state.driveId}
                      folderId={this.state.currentFolderId}
                      siteId={this.state.currentSiteId}
                      authToken={this.props.authToken}
                      userAgentApplication={this.userAgentApplication}
                      lang={this.props.lang}
                      forceFileListRefresh={this.forceRefreshFileList.bind(this)}
                    />
                    <RenameItemForm
                      isOpen={showRenameItemModal}
                      ref={this.renameItemForm}
                      driveId={this.state.driveId}
                      folderId={this.state.currentFolderId}
                      siteId={this.state.currentSiteId}
                      authToken={this.props.authToken}
                      userAgentApplication={this.userAgentApplication}
                      lang={this.props.lang}
                      forceFileListRefresh={this.forceRefreshFileList.bind(this)}
                    />
                    <Modal
                      titleAriaId={this._titleId}
                      subtitleAriaId={this._subtitleId}
                      isOpen={showAddItemModal}
                      onDismiss={this._closeModal}
                      isModeless={false}
                      dragOptions={undefined}
                      containerClassName="swpStyle-upload-file-modal-window"
                      theme={colorThemeLight}
                    >
                      <div className="swpStyle-upload-file-modal-header">
                        <span id={this._titleId}><h6>{resource.str_HeaderUploadingFile}</h6></span>
                      </div>
                      <div id={this._subtitleId} className="swpStyle-upload-file-modal-body">
                        <p>{resource.str_InpChooseFile}</p>
                        <input type="file" multiple className="form-control-file" id="uploadFile" onChange={this._onFileChange.bind(this)}></input><br></br>
                        <Stack horizontal tokens={stackTokens} className="swpStyle-modal-buttonParrent">
                          <DefaultButton onClick={this._closeModal} text={resource.str_BtnCancel} />
                        </Stack>
                      </div>
                    </Modal>
                    <Modal
                      titleAriaId={this._uploadFolderTitleId}
                      subtitleAriaId={this._uploadFolderSubtitleId}
                      isOpen={showAddFolderModal}
                      onDismiss={this._closeModal}
                      isModeless={false}
                      //containerClassName={styles.stickyContainer}
                      dragOptions={undefined}
                      containerClassName="swpStyle-upload-file-modal-window"
                      theme={colorThemeLight}
                    >
                      <div className="swpStyle-upload-file-modal-header">
                        <span id={this._uploadFolderTitleId}><h6>{resource.str_HeaderUploadingFolder}</h6></span>
                      </div>
                      <div id={this._uploadFolderSubtitleId} className="swpStyle-upload-file-modal-body">
                        <p>{resource.str_InpChooseFolder}</p>
                        <input directory="" webkitdirectory="" type="file" multiple className="form-control-file" id="uploadFolder" onChange={this._onFolderChange.bind(this)}></input><br></br>
                        <Stack horizontal tokens={stackTokens} className="swpStyle-modal-buttonParrent">
                          <DefaultButton onClick={this._closeModal} text={resource.str_BtnCancel} />
                        </Stack>
                      </div>
                    </Modal>
                    <Modal
                      titleAriaId={this._delItemTitleId}
                      subtitleAriaId={this._delItemSubtitleId}
                      isOpen={showDelItemModal}
                      onDismiss={this._closeDelItemModal}
                      isModeless={false}
                      dragOptions={undefined}
                      containerClassName="swpStyle-del-item-modal-window"
                      theme={colorThemeLight}
                    >
                      <div className="swpStyle-del-item-modal-header">
                        <span id={this._delItemTitleId}><h6>{resource.str_HeaderDelete}</h6></span>
                      </div>
                      <ProgressIndicator progressHidden={!createUserLoad} barHeight={5} theme={colorThemeLight} styles={progressStyles} />
                      <div id={this._delItemSubtitleId} className="swpStyle-del-item-modal-body">
                        <p>{resource.str_MsgConfirmFileDelete}</p>
                        <Stack horizontal tokens={stackTokens} className="swpStyle-modal-buttonParrent">
                          <PrimaryButton disabled={createUserLoad} style={{ marginLeft: "auto" }} onClick={this._onDelItemButtonClick.bind(this)} text={resource.str_BtnDelete} />
                          <DefaultButton style={{ marginRight: "auto" }} onClick={this._closeDelItemModal} text={resource.str_BtnCancel} />
                        </Stack>
                      </div>
                    </Modal>

                    <p></p>
                    <div className={classNames.selectionDetails}>{selectionDetails}</div>
                    {announcedMessage ? <Announced message={announcedMessage} /> : undefined}


                    <Stack {...verticalStackProps}>
                      {(this.state.uploadPending) && (this.state.singleFile) &&
                        <ProgressBar
                          totalSize={this.state.pbFileSize}
                          fileSize={this.state.pbFileSize}
                          fileName={resource.str_MsgUploadingInProgress + this.state.uploadPendingFileName}
                          hasFolderTree={false}
                          chunkSize={this.state.pbChunkSize}
                          startPosition={this.state.pbChunkStart}
                        ></ProgressBar>
                      }

                      {(this.state.uploadPending) && (this.state.multiFile) &&
                        <ProgressBar
                          totalSize={this.state.pbTotalSize}
                          fileSize={this.state.pbFileSize}
                          relativeStartPoint={this.state.pbNextStartPoint}
                          fileName={resource.str_MsgUploadingInProgressMulti}
                          hasFolderTree={false}
                          chunkSize={this.state.pbChunkSize}
                          startPosition={this.state.pbChunkStart}
                        ></ProgressBar>}

                      {(this.state.uploadPending) && (this.state.multiFoldersFiles) &&
                        <ProgressBar
                          totalSize={this.state.pbTotalSize}
                          fileSize={this.state.pbFileSize}
                          relativeStartPoint={this.state.pbNextStartPoint}
                          fileName={resource.str_MsgUploadingInProgressFolders}
                          hasFolderTree={true}
                          chunkSize={this.state.pbChunkSize}
                          startPosition={this.state.pbChunkStart}
                        ></ProgressBar>
                      }

                      {(this.state.uploadSuccessfull) && (this.state.singleFile) && <MessageBarUploadSuccess fileName={this.state.uploadPendingFileName} closeMsg={this.dismissMsg} langData={this.state.lang} />}
                      {(this.state.uploadSuccessfull) && (this.state.multiFile) && <MessageBarUploadSuccessMulti closeMsg={this.dismissMsg} langData={this.state.lang} />}
                      {(this.state.uploadSuccessfull) && (this.state.multiFoldersFiles) && <MessageBarUploadSuccessFolders closeMsg={this.dismissMsg} langData={this.state.lang} />}

                      {(this.state.downloadPending) && <MessageBarProgressIndicatorFoldersDown langData={this.state.lang} />}
                      {(this.state.downloadSuccessfull) && <MessageBarDownloadSuccessMulti closeMsg={this.dismissMsg} langData={this.state.lang} />}
                      {(this.state.downloadSuccessfullNonZipped) && <MessageBarDownloadSuccess closeMsg={this.dismissMsg} langData={this.state.lang} />}

                      {(this.state.uploadError) && <MessageBarUploadError fileName={this.state.uploadPendingFileName} closeMsg={this.dismissMsg} langData={this.state.lang} />}
                      {(this.state.downloadError) && <MessageBarDownloadError closeMsg={this.dismissMsg} langData={this.state.lang} />}
                      {(this.state.removeSuccessfull) && <MessageBarRemoveSuccess closeMsg={this.dismissMsg} langData={this.state.lang} />}
                    </Stack>
                    {this.state.loadingState ? <LoadingSpinner lang={this.props.lang} /> :
                      <div style={{ display: 'box' }}>
                        <BreadcrumbO365
                          items={this.state.breadcrumbItems}
                          ariaLabel="Nawigacja dla obszaru"
                          overflowAriaLabel={resource.str_LblMore}
                          styles={folderBreadCrumbStyles}
                          className="swpStyle-folder-breadcrumb"
                          //theme={colorThemeLight}
                        />

                        <MarqueeSelection selection={this._selection} theme={colorThemeLight}>
                          <DetailsList
                            items={items}
                            compact={false}
                            columns={columns}
                            selectionMode={SelectionMode.multiple}
                            getKey={this._getKey}
                            setKey="multiple"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            selection={this._selection}
                            selectionPreservedOnEmptyClick={false}
                            enterModalSelectionOnTouch={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="Row checkbox"
                            onItemContextMenu={this._onItemContextMenu.bind(this)}
                            theme={colorThemeLight}
                          />
                          {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}

                        </MarqueeSelection>
                        {(items.length == 0) ?
                          <div>
                            <div style={{ textAlign: "center", width: "200px", marginLeft: "auto", marginRight: "auto" }}><img src="https://static2.sharepointonline.com/files/fabric/office-ui-fabric-react-assets/images/emptyfolder/empty_folder_drop.svg"></img></div>
                            <div style={{ textAlign: "center" }}>{resource.str_LblNoItemsToShow}</div>
                          </div> :
                          <div></div>}
                      </div>
                    }
                  </Fabric>
                  :
                  <div className="swpStyle-shared-deleted">
                    <p>&nbsp;</p>
                    <CompoundButton primary disabled={restoreButtonDisabled || shareSoftDelete == "2"} secondaryText={`${"Odtworzenie możliwe do: "}${this._onFormatDate(hardDeleteDay)}`} theme={colorThemeLight} onClick={this.restoreShareClick.bind(this)}>
                      {shareSoftDelete == "2" ? resource.str_LblRestoreInProgress : resource.str_BtnRestore}
                    </CompoundButton>
                    <Dialog hidden={restoreConfirmDlgDisabled} onDismiss={this._closeRestoreDialog} dialogContentProps={restoreDialogContentProps} modalProps={restoreDialogModalProps} title={this.props.lang == "PL" ? gblResourcePL.str_LblCreateShareConfirm : gblResource.str_LblCreateShareConfirm}>
                      <DialogFooter>
                        <PrimaryButton theme={colorThemeLight} onClick={this._closeRestoreDialog} text={resource.str_BtnOK} />
                      </DialogFooter>
                    </Dialog>
                  </div>
                }
              </div>
            }
          </Col>
        </Row>
      </Container>
    );
  }

  //#region - Downloading

  async enumerateThroughFolder(path: string) {
    //get info about the folder first
    var graphService = new GraphService(this.props.authToken, this.userAgentApplication);

    await graphService.getFolder(path, this.props.shareGroupId).then(async result => {
      this.folderTreeItems.push({
        name: result.name,
        url: path,
        relative: path + "/",
        type: "folder"
      });
      config.DEBUG && console.log("Folder: " + result.name)
      //go through children if any
      if (result.folder.childCount > 0) {
        await graphService.getFolderChildItems(result.id, this.props.shareGroupId).then(async childResult => {
          for (let i = 0; i < childResult.value.length; i++) {
            var childItem = childResult.value[i]
            if (childItem.folder) {
              //repeat actions for folder
              var curPath = childItem.parentReference.path.replace("/drive/root:/", "") + "/" + childItem.name
              await this.enumerateThroughFolder(curPath)
            } else {
              //actions for file. Add to array for zipping.
              var relativePathArray = childItem.parentReference.path.replace("/drive/root:/", "").split("/")
              relativePathArray.splice(0, this.folderPath.length)
              var relativePath = relativePathArray.join("/") + "/"
              this.folderTreeItems.push({
                name: childItem.name,
                url: childItem["@microsoft.graph.downloadUrl"],
                relative: relativePath,
                type: "file"
              });

            }
          }
        })
      } else {
        //need to create that folder...
        // this.folderTreeItems.push({
        //   name: result.name,
        //   url: path,
        //   relative: path + "/",
        //   type: "folder"
        // });
      }
    })
  }

  private async zipContent(items: any[], zipObject: any, count: number, filestring: string) {
    config.DEBUG && console.log("Item count for zipping: " + items.length)
    config.DEBUG && console.log(items)

    var loopIterate = new Promise<void>((resolve, reject) => {
      items.forEach(function (item) {
        var filename = item.name;
        var url = item.url;
        var type = item.type;
        var rel = item.relative;

        JSZipUtils.getBinaryContent(url, function (err: any, data: any) {
          if (type == "file") {
            zipObject.file(rel + filename, data, { binary: true });
          }
          count++;
          if (count == items.length) {
            zipObject.generateAsync({ type: 'blob' })
              .then(function (content: any) {
                saveAs(content, "Pliki_SWP" + filestring + ".zip");
                resolve()
              })
          }
        });
      });
    })

    loopIterate.then(() => {
      config.DEBUG && console.log("<<<<Got all data - ZIPPING>>>>>")
      this._setStateWhenAllZipped()
    });

  }

  public _setStateWhenAllZipped() {
    this.setState({
      downloadPending: false,
      downloadSuccessfull: true,
      downloadSuccessfullNonZipped: false
    })
  }

  private async _onDownloadItemClick() { //this method will compress files to zip if more than one selected
    var zip = new JSZip();
    var count = 0;
    var currentDate = new Date;
    this.folderTreeItems = []
    var filestring: string = "_" + currentDate.getHours() + currentDate.getMinutes() + "_" + currentDate.getDate() + "_" + currentDate.getMonth() + "_" + currentDate.getFullYear()

    config.DEBUG && console.log(this.state.selectedFiles)

    this.setState({
      downloadPending: true,
      downloadSuccessfull: false,
      downloadSuccessfullNonZipped: false
    });

    if (this.state.selectedFiles.length == 1 && !this.state.selectedFiles[0].isFolder) {
      config.DEBUG && console.log("****Started Download SINGLE File....")
      window.open(this.state.selectedFiles[0].downloadUrl, '_self')
      this.setState({
        downloadPending: false,
        downloadSuccessfull: false,
        downloadSuccessfullNonZipped: true
      })

    } else {
      for (let i: number = 0; i < this.state.selectedFiles.length; i++) {
        const file = this.state.selectedFiles[i];
        if (!file.isFolder) { //if file
          config.DEBUG && console.log("****Started Download Files....")
          //getting relative path:
          var relativeUrlArray = file.webDavUrl.split("/").slice(6)
          config.DEBUG && console.log(relativeUrlArray)
          var mod = relativeUrlArray.splice(0, this.folderPath.length) //removing current folder path from file path.

          relativeUrlArray.pop()
          var relativePath = relativeUrlArray.join("/")
          this.folderTreeItems.push({
            name: file.name,
            url: file.downloadUrl,
            relative: relativePath,
            type: "file"
          });
        } else { //if folder
          // var path = file.webUrl 
          if (this.folderPath.length > 0) {
            var path = this.folderPath.join('/') + "/" + file.name
          } else {
            var path = file.name
          }

          await this.enumerateThroughFolder(path)
        }
      }
      // config.DEBUG && console.log(this.folderTreeItems)
      await this.zipContent(this.folderTreeItems, zip, count, filestring).then((result: any) => {

      })
    }


  }


  //#endregion - Downloading

  //#region - Breadcrumb and Folders

  private _onBreadcrumbItemClicked(ev: React.MouseEvent<HTMLElement>, item: IBreadcrumbItem): void {
    config.DEBUG && console.log("Breadcrumb clicked " + item.text + " " + item.key);

    if (!this.state.loadingState) {
      config.DEBUG && console.log("Loading files START...") //trigger for spinner
      this.setState({ loadingState: true });
    }

    var folderId = item.key;

    if (item.key == "Files") {
      //If root reset folder path
      this.folderPath = [];
      this.setState({
        currentFolderId: null,
        currentFolderName: null,
        items: new Array()
      });
    } else if (item.key == "Site") {

    } else if (item.key == "Root") {

    } else {
      //if not root update folder path
      config.DEBUG && console.log(item.text)
      this.updateFolderPath(item.text);
      this.setState({
        currentFolderId: item.key,
        currentFolderName: item.text,
        items: new Array()
      });
    }


    if (item.key == "Files") {
      this.getDocuments(this.state.driveId).then(result => {
        this.setState({
          items: this._allItems
        })
      }
      );
    } else {
      this.getDocuments(this.state.driveId, item.key).then(result => {
        this.setState({
          items: this._allItems
        })
      }
      );
    }


    this.updateBreadCrumb(folderId);
    config.DEBUG && console.log(this.folderPath);

    if (this.state.loadingState) {
      config.DEBUG && console.log("Loading files STOP...") //stopping spinner
      this.setState({ loadingState: false });
    }
  }

  private updateFolderPath(folderName: string) {
    var currentFolderPath = this.folderPath;
    var updatedFolderPath = new Array<string>();

    for (var i = 0; i < currentFolderPath.length; i++) {
      if (currentFolderPath[i] == folderName) {
        updatedFolderPath.push(currentFolderPath[i]);
        break;
      } else {
        updatedFolderPath.push(currentFolderPath[i]);
      }
    }

    this.folderPath = updatedFolderPath;
  }

  private updateBreadCrumb(folderId: string) {
    config.DEBUG && console.log("Truncating breadcrumb to folder item: " + folderId);
    config.DEBUG && console.log("Truncating breadcrumb to folder item: " + this.state.currentFolderName);
    var breadcrumbItems = this.state.breadcrumbItems;
    //var index = breadcrumbItems.findIndex(obj => obj.key == folderId);
    //config.DEBUG && console.log("Truncating breadcrumb to folder from index: " + index);
    var truncatedBreadcrumbItems = new Array();

    for (var i = 0; i < breadcrumbItems.length; i++) {
      if (breadcrumbItems[i].key == folderId) {
        truncatedBreadcrumbItems.push(breadcrumbItems[i]);
        break;
      } else {
        truncatedBreadcrumbItems.push(breadcrumbItems[i]);
      }
    }

    config.DEBUG && console.log(truncatedBreadcrumbItems);

    this.setState(
      {
        breadcrumbItems: truncatedBreadcrumbItems
      }
    );
  }

  private addBreadcrumbItem(folderName: string, folderId: string) {
    var breadcrumbItem = { text: folderName, key: folderId, onClick: this._onBreadcrumbItemClicked.bind(this), isCurrentItem: true };
    config.DEBUG && console.log("adding breadcrumb item: " + folderName + " " + folderId);
    var breadcrumbItems = this.state.breadcrumbItems;
    breadcrumbItems.push(breadcrumbItem);

    this.setState(
      {
        breadcrumbItems: breadcrumbItems
      }
    );
  }

  async _createFolder(folderName: string, parentFolderId: string) {
    config.DEBUG && console.log("Name: " + folderName + " ID: " + parentFolderId)
    let newFolderId = null
    let graphService = new GraphService(this.props.authToken, this.userAgentApplication);

    if (parentFolderId) {
      await graphService.createFolder(this.state.driveId, folderName, this.state.currentFolderId).then(result => {
        //this.forceRefreshFileList();
        newFolderId = result.id
        config.DEBUG && console.log(newFolderId)
      });
    } else {
      await graphService.createFolder(this.state.driveId, folderName).then(result => {
        //this.forceRefreshFileList();
        newFolderId = result.id
        config.DEBUG && console.log(newFolderId)
      });
    }
  }

  //#endregion - Breadcrumb and Folders

  //#region - general

  private _onFormatDate = (date: Date): string => {
    if (this.props.lang == "PL") {
      return date.toLocaleDateString("pl-pl")
    } else {
      return date.toLocaleDateString("en-uk")
    }
  };

  public async _forceRefreshShareStateValues() {
    var graphService = new GraphService(this.props.authToken, this.userAgentApplication);
    var shareState = await graphService.getShareListItem(this.props.shareGroupId);
    this.setState({
      currentShareState: shareState.value[0].fields.StatusObszaru,
      currentShareItem: shareState.value[0].fields
    }); //###Regional Value String### 
    this.props.fileBrowserRefresh()
    this.forceRefreshFileList()
  }

  public forceRefreshFileList() {
    this.setState(
      {
        items: new Array()
      }
    )
    this.getDocuments(this.state.driveId, this.state.currentFolderId).then(
      result => {
        this.setState(
          {
            items: this._allItems
          });
      }
    );
  }

  public componentDidUpdate(previousProps: any, previousState: FileBrowserState) {
    if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
      this._selection.setAllSelected(false);
    }
  }

  async getDriveId() {
    var graphService = new GraphService(this.props.authToken, this.userAgentApplication);

    if (!this.state.loadingState) {
      config.DEBUG && console.log("Loading files START...") //trigger for spinner
      this.setState({ loadingState: true });
    }
    var shareState = await graphService.getShareListItem(this.props.shareGroupId);
    if (shareState.value != undefined) {
      this.setState({
        currentShareState: shareState.value[0].fields.StatusObszaru, //###Regional Value String###
        curentShareURL: shareState.value[0].fields.AdresObszaru, //###Regional Value String###
        shareDeleteInProgress: shareState.value[0].fields.Usuwany, //###Regional Value String###
        shareSoftDelete: shareState.value[0].fields.Wygaszony, //###Regional Value String###
        currentShareTitle: shareState.value[0].fields.Title,
        currentShareItem: shareState.value[0].fields
      });
    }

    await graphService.getSiteId(this.props.shareGroupId).then(async getSideIdResult => {
      this.setState({ 
        currentSiteId: getSideIdResult.id 
      });
      config.DEBUG && console.log("SITE ID:" + getSideIdResult.id)
      await graphService.getDriveId(getSideIdResult.id).then( result => {
        this.driveId = result.value[0].id;
        this.setState({
          driveId: this.driveId,
        })
        config.DEBUG && console.log("DRIVE ID:" + result.value[0].id)
      })
    })
  }

  async getDocuments(driveId?: string, folderId?: string) {
    var graphService = new GraphService(this.props.authToken, this.userAgentApplication);
    let documents: IDocument[];

    if (driveId && folderId) {
      await graphService.getShareFiles(this.state.currentSiteId, driveId, folderId).then(
        result => {
          documents = result.value.map((val: any, index: number) => {
            var fileType = "";
            var downloadUrl = "";
            var webUrl = "";
            var isFolder = false;
            var lastModifiedBy = "";

            if (val.folder) {
              config.DEBUG && console.log(val.name + " is folder");
              fileType = "folder";
              downloadUrl = "";
              webUrl = val.webUrl;
              isFolder = true;
            } else {
              fileType = _getDocTypeFromFileName(val.name);
              downloadUrl = val['@microsoft.graph.downloadUrl'];
              webUrl = val.webUrl;
              isFolder = false;
            }

            if (val.lastModifiedBy.user) {
              lastModifiedBy = val.lastModifiedBy.user.displayName;
            } else {
              lastModifiedBy = val.lastModifiedBy.application.displayName;
            }

            return {
              key: index.toString(),
              name: val.name,
              value: val.name,
              iconName: _getFileIcon(_getDocTypeFromFileName(val.name)).url,
              fileType: fileType,
              //fileType: "docx",
              modifiedBy: lastModifiedBy,
              dateModified: moment(val.lastModifiedDateTime).format("DD.MM.YYYY hh:mm"),
              dateModifiedValue: val.lastModifiedDateTime,
              fileSize: _getFileSize(val.size).value,
              fileSizeRaw: _getFileSize(val.size).rawSize,
              downloadUrl: downloadUrl,
              webUrl: webUrl,
              webDavUrl: val.webDavUrl,
              isFolder: isFolder,
              itemId: val.id
            }
          })
        }
      )
    } else {
      await graphService.getShareFiles(this.state.currentSiteId).then(
        result => {

          documents = result.value.map((val: any, index: number) => {
            var fileType = "";
            var downloadUrl = "";
            var webUrl = "";
            var isFolder = false;
            var lastModifiedBy = "";

            if (val.folder) {
              config.DEBUG && console.log(val.name + " is folder");
              fileType = "folder";
              downloadUrl = "";
              webUrl = val.webUrl;
              isFolder = true;
            } else {
              fileType = _getDocTypeFromFileName(val.name);
              downloadUrl = val['@microsoft.graph.downloadUrl'];
              webUrl = val.webUrl;
              isFolder = false;
            }

            if (val.lastModifiedBy.user) {
              lastModifiedBy = val.lastModifiedBy.user.displayName;
            } else {
              lastModifiedBy = val.lastModifiedBy.application.displayName;
            }

            return {
              key: index.toString(),
              name: val.name,
              value: val.name,
              iconName: _getFileIcon(_getDocTypeFromFileName(val.name)).url,
              fileType: fileType,
              //fileType: "docx",
              modifiedBy: lastModifiedBy,
              dateModified: moment(val.lastModifiedDateTime).format("DD.MM.YYYY hh:mm"),
              dateModifiedValue: val.lastModifiedDateTime,
              fileSize: _getFileSize(val.size).value,
              fileSizeRaw: _getFileSize(val.size).rawSize,
              downloadUrl: downloadUrl,
              webUrl: webUrl,
              webDavUrl: val.webDavUrl,
              isFolder: isFolder,
              itemId: val.id
            }
          })
        });
    }


    this._allItems = documents;

    if (this.state.loadingState) {
      config.DEBUG && console.log("Loading files STOP...") //stopping spinner
      this.setState({ loadingState: false });
    }
    //config.DEBUG && console.log(documents);
  }

  public async verifyAlertState() {
    var graphService = new GraphService(this.props.authToken, this.userAgentApplication);
    const alert = await graphService.getAlert(this.props.shareGroupId, this.props.currentUserUPN)

    if (alert.value) {
      config.DEBUG && console.groupCollapsed("%cAlerts", "color: blue")
      config.DEBUG && console.log(alert)
      var alertState = alert.value[0].fields.Alert
      if (alertState == "1") {
        config.DEBUG && console.log("%cAlerts ENABLED", "color: Green")
        this.setState({
          userAlertState: 1,
          inviteId: alert.value[0].id
        })
      } else if (alertState == "0" || alertState == null) {
        config.DEBUG && console.log("%cAlerts DISABLED", "color: Red")
        this.setState({
          userAlertState: 0,
          inviteId: alert.value[0].id
        })
      } else {
        config.DEBUG && console.log("Alerts Change in progress")
        this.setState({
          userAlertState: 2,
          inviteId: alert.value[0].id
        })
      }
      config.DEBUG && console.groupEnd()
    }
  }

  private async checkIfCurrentUserIsShareOperator() {
    //Alternate check
    var graphService = new GraphService(this.props.authToken, this.userAgentApplication);
    let shareOperator: boolean = false;
    let shareOwner: boolean = false;
    let employee: boolean = false;

    //Checking in share invitations

    const invitationOperators = await graphService.getOperatorsFromInvitations(this.props.shareGroupId, this.props.currentUserUPN);
    if (invitationOperators) {
      config.DEBUG && console.log("Invitation Operators");
      config.DEBUG && console.log(invitationOperators);
      if (invitationOperators.value.length > 0) {
        config.DEBUG && console.log("Current user is invitation Operator");
        shareOperator = true;
        this.verifyAlertState()
      } else {
        shareOperator = false;
      }
    } else {
      config.DEBUG && console.log("Invitation Operators null");
      shareOperator = false;
    }

    if (this.props.isAdmin || shareOperator) {
      this.setState({
        role: 0
      });
    } else {
      this.setState({
        role: 1
      });
    }

    config.DEBUG && console.log("Final role (0 - owner, 1 - external partner ): " + this.state.role.toString());

  }

  private updateState() { //updating state after multiple files upload
    this.setState(
      {
        items: new Array()
      }
    );

    this.getDocuments(this.state.driveId, this.state.currentFolderId).then(
      result => {
        this.setState(
          {
            items: this._allItems
          });
      }
    );

    this.setState({
      uploadPending: false,
      uploadSuccessfull: true,
    })
  }

  private clearMessages() {
    this.setState({
      removeSuccessfull: false,
      uploadError: false,
      downloadError: false,
      uploadPending: false,
      uploadSuccessfull: false,
      downloadPending: false,
      multiFile: false,
      singleFile: false,
      multiFoldersFiles: false
    });
  }

  async _onFileChange(event: React.ChangeEvent<HTMLInputElement>) { //method used to upload a file
    this.clearMessages();
    event.persist();
    //config.DEBUG && console.log(event.target.files);
    let files = event.target.files;
    var graphService = new GraphService(this.props.authToken, this.userAgentApplication);
    this._closeModal();

    var totalSize = 0
    for (let i = 0; i < files.length; i++) {
      totalSize += files[i].size
    }
    config.DEBUG && console.log("$$$ Total size of all files to upload in bytes: " + totalSize)

    if (files.length > 1) {
      this.setState({
        //uploadPending: true,
        uploadSuccessfull: false,
        multiFile: true,
        singleFile: false,
        pbTotalSize: totalSize
      });

      config.DEBUG && console.log("Uploading many files to folder: " + currentFolderPath);
      var uploadFileArray = [];
      for (var x = 0; x < files.length; x++) { //for each file

        let file = event.target.files[x]
        // config.DEBUG && console.log(file)
        // console.log(file)
        var currentFolderPath = this.folderPath.join("/");

        if (file.size < 4194304) {
          //small files
          config.DEBUG && console.log("$$$ Small file")
          await graphService.uploadFile(file, this.state.currentSiteId, this.state.driveId, currentFolderPath).then(
            result => {
              config.DEBUG && console.log("_____start: " + this.state.pbNextStartPoint)
              this.reloadProgressBar(this.state.pbNextStartPoint, file.size, file.size);
              uploadFileArray.push("done");
              config.DEBUG && console.log(uploadFileArray.length);
              config.DEBUG && console.log(files.length);
              if (uploadFileArray.length === files.length) {
                this.updateState();
              }
            }
          );
        } else {
          //large files
          config.DEBUG && console.log("$$$ Large file")
          await graphService.uploadLargeFile(file, this.state.currentSiteId, this.state.driveId, currentFolderPath, this.reloadProgressBar.bind(this)).then(
            result => {
              uploadFileArray.push("done");
              config.DEBUG && console.log(uploadFileArray.length);
              config.DEBUG && console.log(files.length);
              if (uploadFileArray.length === files.length) {
                this.updateState();
              }
            }
          );
        }

        this.setState({
          pbNextStartPoint: file.size + this.state.pbNextStartPoint
        })
      }
    } else { //for single file only

      let file = event.target.files[0]
      config.DEBUG && console.log(file)
      this.setState({
        uploadPendingFileName: file.name,
        //uploadPending: true,
        uploadSuccessfull: false,
        singleFile: true,
        multiFile: false,
        pbFileSize: file.size
      })
      try {
        var currentFolderPath = this.folderPath.join("/");

        if (file.size < 4194304) {
          //small single file
          await graphService.uploadFile(file, this.state.currentSiteId, this.state.driveId, currentFolderPath).then(
            result => {
              this.reloadProgressBar(this.state.pbNextStartPoint, file.size, file.size);
              this.updateState();
            }
          );
        } else {
          //large single file
          await graphService.uploadLargeFile(file, this.state.currentSiteId, this.state.driveId, currentFolderPath, this.reloadProgressBar.bind(this)).then(
            result => {
              this.updateState();
            }
          );
        }
      } catch (err) {
        config.DEBUG && console.log(err);
      }
    }

  }

  async _onFolderChange(event: React.ChangeEvent<HTMLInputElement>) { //method used to upload a file
    this.clearMessages();
    event.persist();
    let files = event.target.files;
    config.DEBUG && console.log(files)
    var graphService = new GraphService(this.props.authToken, this.userAgentApplication);
    this._closeModal();

    var totalSize = 0
    for (let i = 0; i < files.length; i++) {
      totalSize += files[i].size
    }
    config.DEBUG && console.log("$$$ Total size of all files in Folders to upload in bytes: " + totalSize)

    this.setState({
      //uploadPending: true,
      uploadSuccessfull: false,
      multiFile: false,
      singleFile: false,
      multiFoldersFiles: true,
      pbTotalSize: totalSize
    });

    var uploadFileArray = [];

    config.DEBUG && console.log(this.folderPath.join('/'))

    for (var x = 0; x < files.length; x++) { //for each file
      var fullPathArray = []
      var path = ""
      var noFileArray:any = new Array()
      var file = event.target.files[x]
      var fullPathItems = file.webkitRelativePath.toString().split("/") //creating array of path items. will loop through to create tree
      for (let i = 0; i < fullPathItems.length; i++) {
        fullPathArray.push(fullPathItems[i])
      }
      //console.log(fullPathItems)
      var noFileArray:any = fullPathArray.slice(0, -1);
      config.DEBUG && console.log(noFileArray)

      if (this.folderPath.length > 0) {
        path = this.folderPath.join('/') + "/" + noFileArray.join("/")
      } else {
        path = noFileArray.join("/")
      }

      await graphService.getFolder(path, this.props.shareGroupId).then(async result => {
        if (result.id) {
          config.DEBUG && console.log("Folder path: " + path)
          config.DEBUG && console.log("Folder ID: " + result.id)

          if (file.size < 4194304) {
            //small file
            graphService.uploadFile(file, this.state.currentSiteId, this.state.driveId, path).then(
              result => {
                this.reloadProgressBar(this.state.pbNextStartPoint, file.size, file.size);
                uploadFileArray.push("done");
                this.setState({ pbNextStartPoint: file.size + this.state.pbNextStartPoint })
                if (uploadFileArray.length === files.length) {
                  this.updateState();
                }
              }
            );
          } else {
            //large file
            graphService.uploadLargeFile(file, this.state.currentSiteId, this.state.driveId, path, this.reloadProgressBar.bind(this)).then(
              result => {
                uploadFileArray.push("done");
                this.setState({ pbNextStartPoint: file.size + this.state.pbNextStartPoint })
                if (uploadFileArray.length === files.length) {
                  this.updateState();
                }
              }
            );
          }

        } else {
          config.DEBUG && console.log("Folder not found, creating")
          await this._createFolder(noFileArray[0], this.state.currentFolderId).then((result: any) => {

            if (file.size < 4194304) {
              //small file
              graphService.uploadFile(file, this.state.currentSiteId, this.state.driveId, path).then(
                result => {
                  this.reloadProgressBar(this.state.pbNextStartPoint, file.size, file.size);
                  uploadFileArray.push("done");
                  if (uploadFileArray.length === files.length) {
                    this.updateState();
                  }
                }
              );
            } else {
              //large file
              graphService.uploadLargeFile(file, this.state.currentSiteId, this.state.driveId, path, this.reloadProgressBar.bind(this)).then(
                result => {
                  uploadFileArray.push("done");
                  this.setState({ pbNextStartPoint: file.size + this.state.pbNextStartPoint })
                  if (uploadFileArray.length === files.length) {
                    this.updateState();
                  }
                }
              );
            }
          })
        }
      })
    }

    this.forceRefreshFileList()
  }

  private reloadProgressBar(start: number, chunkSize: number, fileSize: number) {
    config.DEBUG && console.log("!!! Reload progress bar triggered... !!!")
    this.setState({
      uploadPending: false,
      pbChunkStart: start,
      pbChunkSize: chunkSize,
      pbFileSize: fileSize
    })
    this.setState({
      uploadPending: true
    })
  }

  //#endregion General

  //#region Modals

  private _showNewFolderModal = (): void => {
    this.setState({ showNewFolderModal: true });
    this.newFolderForm.current._openNewFolderModal();
  };

  private _showRenameItemModal = (): void => {
    this.setState({ showRenameItemModal: true });
    this.renameItemForm.current._openRenameModal(this.state.selectedFiles[0].itemId, this.state.selectedFiles[0].name);
  };

  private _showDelItemModal = (): void => {
    this.setState({ showDelItemModal: true });
    //this.renameItemForm.current._openRenameModal();
  };

  private _closeModal = (): void => {
    this.setState({
      showAddItemModal: false,
      showAddFolderModal: false
    });
  };

  private _closeDelItemModal = (): void => {
    this.setState({ showDelItemModal: false });
  };

  private _showUploadFileModal = (): void => {
    this.setState({ showAddItemModal: true });
  };

  private _showUploadFolderModal = (): void => {
    this.setState({ showAddFolderModal: true });
  };

  private _closeRestoreDialog = (): void => {
    this.setState({
      restoreConfirmDlgDisabled: true
    })
  }

  private _openRestoreDialog = (): void => {
    config.DEBUG && console.log("Restore dialog Opened")
    this.setState({
      restoreConfirmDlgDisabled: false
    })
  }

  //#endregion Modals

  //#region SelectionsAndClicks

  private _onItemContextMenu(item: IDocument, index: number, ev: MouseEvent) {
    config.DEBUG && console.log("Item context menu invoked for item id: " + item.itemId);
    //this.state.renameButtonDisabled || shareDisabled
    var menuItems = [];
    if (item.fileType == "cmd" || item.fileType == "ps1" || item.fileType == "exe" || item.fileType == "zip" || item.fileType == "rar" || item.fileType == "cab" || item.fileType == "bat") {
      var diabledOpen: boolean = true
    }

    let shareDisabled = null;
    if (this.state.currentShareState == this.state.lang.str_ShareStatusDisabled) {
      shareDisabled = true;
    } else {
      shareDisabled = false;
    }

    if (item.isFolder || diabledOpen) {
      menuItems = [
        {
          key: 'text',
          name: this.state.lang.str_BtnChangeName,
          disabled: this.state.renameButtonDisabled || shareDisabled || this.state.role == 1,
          onClick: () => {
            config.DEBUG && console.log("Rename item context menu clicked for item id: " + item.itemId);
            this._showRenameItemModal();
          }
        },
        {
          key: 'text',
          name: this.state.lang.str_BtnDownload,
          disabled: this.state.downloadButtonDisabled,
          onClick: this._onDownloadItemClick.bind(this)
        },
        {
          key: 'text',
          name: this.state.lang.str_BtnDelete,
          disabled: this.state.delButtonDisabled || shareDisabled || this.state.role == 1,
          onClick: () => {
            config.DEBUG && console.log("Remove item context menu clicked for item id: " + item.itemId);
            this._showDelItemModal();

          }
        }
      ];
    } else {
      menuItems = [
        {
          key: 'text',
          name: this.state.lang.str_BtnOpenInApp,
          onClick: () => {
            window.open(item.webDavUrl);
          }
        },
        {
          key: 'text',
          name: this.state.lang.str_BtnOpenInBrowser,
          onClick: () => {
            window.open(item.webUrl);
          }
        },
        {
          key: 'text',
          name: this.state.lang.str_BtnDownload,
          onClick: () => {
            window.open(item.downloadUrl);
          }
        },
        {
          key: 'text',
          name: this.state.lang.str_BtnChangeName,
          disabled: this.state.renameButtonDisabled || shareDisabled || this.state.role == 1,
          onClick: () => {
            this._showRenameItemModal();
          }
        },
        {
          key: 'text',
          name: this.state.lang.str_BtnDelete,
          disabled: this.state.delButtonDisabled || shareDisabled || this.state.role == 1,
          onClick: () => {
            this._showDelItemModal();
          }
        }
      ];
    }

    const contextualMenuProps: IContextualMenuProps = {
      target: ev.target as HTMLElement,

      items: menuItems,
      onDismiss: () => {
        this.setState({
          contextualMenuProps: undefined
        });
      }
    };

    if (index > -1) {
      this.setState({
        contextualMenuProps: contextualMenuProps
      });
    }

    return false;
  }

  private dismissMsg = (): void => {
    config.DEBUG && console.log("Close message clicked");
    this.setState({
      uploadPending: false,
      uploadSuccessfull: false,
      uploadError: false,
      removeSuccessfull: false,
      downloadSuccessfull: false,
      downloadSuccessfullNonZipped: false,
      downloadPending: false
    });
  }

  private _onDelItemButtonClick() {
    config.DEBUG && console.log("Removing items: ");
    let itemIds: string[] = new Array();

    if (!this.state.createUserLoad) {
      config.DEBUG && console.log("Loading files START...") //trigger for spinner
      this.setState({ createUserLoad: true });
    }


    for (let i: number = 0; i < this.state.selectedFiles.length; i++) {
      config.DEBUG && console.log(this.state.selectedFiles[i].itemId);
      itemIds.push(this.state.selectedFiles[i].itemId);
    }

    var graphService = new GraphService(this.props.authToken, this.userAgentApplication);
    graphService.deleteItems(this.state.driveId, itemIds).then(result => {
      this.setState(
        {
          items: new Array()
        }
      )
      this.getDocuments(this.state.driveId, this.state.currentFolderId).then(
        result => {
          //const {items} = this.state;
          //this._allItems = items;
          this.setState(
            {
              items: this._allItems
            });
        }
      );

      if (this.state.createUserLoad) {
        config.DEBUG && console.log("Loading files START...") //trigger for spinner
        this.setState({ createUserLoad: false });
      }

      this.clearMessages();
      this.setState({ showDelItemModal: false, removeSuccessfull: true });
    });
  }

  _onFolderClick(event: React.ChangeEvent<HTMLInputElement>) {
    var folderId = event.target.id;
    this.addBreadcrumbItem(event.target.dataset.name, event.target.id);
    this.folderPath.push(event.target.dataset.name);

    this.setState({
      currentFolderId: folderId
    });

    //reset marquee selection
    this._selection.setItems([], true);

    if (!this.state.loadingState) {
      config.DEBUG && console.log("Loading files START...") //trigger for spinner
      this.setState({ loadingState: true });
    }

    this.getDocuments(this.state.driveId, folderId).then(result => {
      this.setState({
        items: this._allItems
      })
    }
    );
    //config.DEBUG && console.log(this.folderPath);

    if (this.state.loadingState) {
      config.DEBUG && console.log("Loading files STOP...") //trigger for spinner
      this.setState({ loadingState: false });
    }
  }

  private _getKey(item: any, index?: number): string {
    return item.key;
  }

  private _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
    this.setState({
      items: text ? this._allItems.filter(i => i.name.toLowerCase().indexOf(text) > -1) : this._allItems
    });
  };

  private _getSelectionDetails(): string {
    const selectionCount = this._selection.getSelectedCount();
    this._selection.getSelection();

    switch (selectionCount) {
      case 0:
        this.setState({ renameButtonDisabled: true, delButtonDisabled: true, downloadButtonDisabled: true, selectedFiles: null });
        return this.props.lang == "PL" ? gblResourcePL.str_MsgNoItemsSelected : gblResource.str_MsgNoItemsSelected;
      case 1:
        let selectedFile: IDocument[] = this._selection.getSelection() as IDocument[];
        this.setState({ renameButtonDisabled: false, delButtonDisabled: false, downloadButtonDisabled: false, selectedFiles: selectedFile })
        config.debug && console.log(this.state.selectedFiles);
        return this.props.lang == "PL" ? gblResourcePL.str_MsgOneItemSelected : gblResource.str_MsgOneItemSelected + (this._selection.getSelection()[0] as IDocument).name;
      default:
        let selectedFiles: IDocument[] = this._selection.getSelection() as IDocument[];
        this.setState({ renameButtonDisabled: true, delButtonDisabled: false, downloadButtonDisabled: false, selectedFiles: selectedFiles });
        console.log(selectedFiles);
        console.log(this.folderPath)
        return `${this.props.lang == "PL" ? gblResourcePL.str_MsgSelectedItemsCount : gblResource.str_MsgSelectedItemsCount} ${selectionCount}`;
    }
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          announcedMessage: `${currColumn.name} is sorted ${currColumn.isSortedDescending ? 'descending' : 'ascending'}`
        });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems
    });
  };

  private async restoreShareClick(){
    var graphService = new GraphService(this.props.authToken, this.userAgentApplication);
    await graphService.restoreShareInProgress(this.state.currentShareItem.id).then(result => {
      graphService.restoreShare(this.props.shareGroupId, this.props.currentUserUPN, this.state.currentShareItem.id).then(result => {
        this._openRestoreDialog()
        this.setState({
          restoreButtonDisabled: true
        })
      })
    })
  }

  //#endregion SelectionsAndClicks
}

//#region - Other functions

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

function _getFileIcon(docType: string): { docType: string; url: string } {
  return {
    docType,
    url: `https://static2.sharepointonline.com/files/fabric/assets/brand-icons/document/svg/${docType}_16x1.svg`
  };
}

function _getDocTypeFromFileName(fileName: string) {
  var fileParts = fileName.split(".");
  if (fileParts.length > 1) {
    return fileParts[fileParts.length - 1];
  } else {
    return "txt";
  }
}

function _getFileSize(fileSize: number): { value: string; rawSize: number } {
  var fileSizeKB = (fileSize / 1024);
  var fileSizeMB = fileSizeKB / 1024;
  if (fileSizeKB > 1000) {
    return {
      value: `${fileSizeMB.toFixed(1)} MB`,
      rawSize: fileSize
    };
  }
  else {
    return {
      value: `${fileSizeKB.toFixed(0)} KB`,
      rawSize: fileSize
    }
  }
}

//#endregion - Other functions
