//------------------------------------ Description ------------------------------------------------------------
//This form allows users to rename share without requesting it from SharePoint Online Administartor's.
//-------------------------------------------------------------------------------------------------------------

import * as React from "react";
import { Modal } from '@fluentui/react/lib/Modal';
import { getId } from '@fluentui/react/lib/Utilities';
import {
    PrimaryButton,
    Stack,
    DefaultButton,
    IStackTokens,
    TextField,
    Label,
    Dialog,
    DialogFooter,
    DialogType,
    Text
} from '@fluentui/react';
import { GraphService } from "../components/GraphService";
import * as Msal from "msal";
import { colorThemeLight } from "../components/Themes";
import { ProgressIndicator, IProgressIndicatorStyles } from '@fluentui/react/lib/ProgressIndicator';

const config = require("../components/config.json");
const gblResource = require("../LangFiles/resource.json");
const gblResourcePL = require("../LangFiles/resourcePL.json");

const stackTokens: IStackTokens = { childrenGap: 10 };

const progressStyles: Partial<IProgressIndicatorStyles> = {
    itemProgress: { paddingTop: 0 }
};

const dialogContentProps = {
    type: DialogType.normal,
    subText: 'Zmiana nazwy może potrwać do 5 minut. Należy wrócić do listy obszarów w celu weryfikacji zmiany.'
};

const dialogContentErrorProps = {
    type: DialogType.normal
};

const dialogModalProps = {
    isBlocking: true,
    styles: { main: { minWidth: 250, minHeight: 50 } }
};

export interface RenameShareFormProps {
    isOpen: boolean;
    authToken: string;
    userAgentApplication: Msal.UserAgentApplication;
    currentShareData: any; //ShareSize is a property we are looking for
    shareDetailsRefreshMethod: any;
    lang: string;
}
export interface RenameShareFormState {
    userTypeValue?: string;
    showRenameShareModal: boolean;
    RenameShareButtonDisabled: boolean;
    shareName: string;
    newShareName?: string;
    showConfirmDialog: boolean;
    showErrorDialog: boolean;
    contentLoad: boolean;
    renameShareErrorMessage: string;
}

export class RenameShareForm extends React.Component<RenameShareFormProps, RenameShareFormState> {
    private _RenameShareTitleId: string = getId('new-folder-title');
    private _RenameShareSubtitleId: string = getId('new-folder-subtitle');

    constructor(props: RenameShareFormProps) {
        super(props);

        this.state = {
            showRenameShareModal: false,
            RenameShareButtonDisabled: true,
            shareName: "",
            showConfirmDialog: true,
            showErrorDialog: true,
            contentLoad: false,
            renameShareErrorMessage: ""
        }
    }

    render() {

        //Language section
        let resource: any;
        if (this.props.lang == "PL") {
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //end of language section

        var textFieldLabel: string = `${resource.str_InpProvideNewShareName} (${resource.str_PlhShareNameField})`

        const { showRenameShareModal, showConfirmDialog, contentLoad, renameShareErrorMessage, showErrorDialog } = this.state;

        return (
            <Modal
                titleAriaId={this._RenameShareTitleId}
                subtitleAriaId={this._RenameShareSubtitleId}
                isOpen={showRenameShareModal}
                onDismiss={this._closeRenameShareModal.bind(this)}
                isModeless={false}
                dragOptions={undefined}
                containerClassName="swpStyle-narrow-forms-modal-window"
                theme={colorThemeLight}
            >
                <div className="swpStyle-upload-file-modal-header">
                    <span id={this._RenameShareTitleId}><h6>{resource.str_HeaderRenameShare}</h6></span>
                </div>
                <ProgressIndicator progressHidden={!contentLoad} barHeight={5} theme={colorThemeLight} styles={progressStyles} />

                <div id={this._RenameShareSubtitleId} className="swpStyle-upload-file-modal-body">
                    {/* <TextField required theme={colorThemeLight} placeholder={resource.str_PlhShareNameField} label={resource.str_InpProvideNewShareName} errorMessage={renameShareErrorMessage} onChange={this._onRenameShareTextBoxChange.bind(this)}></TextField><br></br> */}
                    <TextField required theme={colorThemeLight} value={this.state.newShareName} label={textFieldLabel} errorMessage={renameShareErrorMessage} onChange={this._onRenameShareTextBoxChange.bind(this)}></TextField><br></br>
                    {/* Submit buttons: */}
                    <Stack horizontal tokens={stackTokens} className="swpStyle-modal-buttonParrent">
                        <PrimaryButton theme={colorThemeLight} onClick={this._RenameShare.bind(this)} text={resource.str_BtnConfirm} disabled={this.state.RenameShareButtonDisabled} />
                        <DefaultButton theme={colorThemeLight} onClick={this._cancelRenameShare.bind(this)} text={resource.str_BtnCancel} />
                    </Stack>
                </div>
                <Dialog hidden={showConfirmDialog} onDismiss={this._closeRenameShareModal} dialogContentProps={dialogContentProps} modalProps={dialogModalProps} title={this.props.lang == "PL" ? gblResourcePL.str_LblCreateShareConfirm : gblResource.str_LblCreateShareConfirm}>
                    <DialogFooter>
                        <PrimaryButton theme={colorThemeLight} onClick={this._closeRenameShareModal} text={resource.str_BtnOK} />
                    </DialogFooter>
                </Dialog>
                <Dialog hidden={showErrorDialog} onDismiss={this._closeRenameShareModal} dialogContentProps={dialogContentErrorProps} modalProps={dialogModalProps} title={this.props.lang == "PL" ? gblResourcePL.str_MsgErrorGenericTitle : gblResource.str_MsgErrorGenericTitle}>
                    <Text>{this.props.lang == "PL" ? gblResourcePL.str_MsgErrorGeneric : gblResource.str_MsgErrorGeneric}</Text>
                    <DialogFooter>
                        <PrimaryButton theme={colorThemeLight} onClick={this._closeErrorDialog} text={resource.str_BtnOK} />
                    </DialogFooter>
                </Dialog>
            </Modal>
        )
    }
    private _closeRenameShareModal = (): void => {
        this.setState({
            showRenameShareModal: false,
            showConfirmDialog: true,
            RenameShareButtonDisabled: false
        });
        this.props.shareDetailsRefreshMethod(); //refreshing ShareDetails component
    }

    private _cancelRenameShare = (): void => {
        this.setState({
            showRenameShareModal: false,
            showConfirmDialog: true
        });
    }

    private _closeErrorDialog = (): void => {
        this.setState({
            showErrorDialog: true,
            showRenameShareModal: false
        });
        this.props.shareDetailsRefreshMethod();
    }

    public _openRenameShareModal() {
        this.setState({ 
            showRenameShareModal: true,
            newShareName: this.props.currentShareData.Title
         });
    }

    private _onRenameShareTextBoxChange(event: React.ChangeEvent<HTMLInputElement>) {
        var pattern = new RegExp("^[ 0-9A-Za-z-ółćążźęńśÓŁĆĄŻŹĘŃŚ_\d]{3,30}$");

        //Language section
        let resource: any;
        if (this.props.lang == "PL") {
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //end of language section

        if (event.target.value.match(pattern) != null) {
            config.DEBUG && console.log(event.target.value);
            this.setState({
                newShareName: event.target.value,
                renameShareErrorMessage: "",
                RenameShareButtonDisabled: false
            })
        } else {
            config.DEBUG && console.log("Error");
            this.setState({
                renameShareErrorMessage: resource.str_ErrWrongShareName,
                RenameShareButtonDisabled: true
            })
        }

    }

    private _RenameShare() {
        config.DEBUG && console.groupCollapsed("%cRENAME SHARE", "color: blue")
        config.DEBUG && console.log("_onCreateShareClick");

        let graphService: GraphService = new GraphService(this.props.authToken, this.props.userAgentApplication);

        if(this.state.newShareName != this.props.currentShareData.Title){
            this.setState({ contentLoad: true, RenameShareButtonDisabled: true }) //start load

            // additional check on request list as well to avoid quickly created shares before they show up on shares list
            graphService.getShareRequestItemByTitle(this.state.newShareName.trim()).then(requestResult => {
                config.DEBUG && console.log(requestResult.value)
                if (requestResult.value != undefined && requestResult.value.length > 0) { //something returned, not undefined
                    if (requestResult.value[0].fields.Title.toLowerCase() == this.state.newShareName.toLowerCase()) {
                        //show error dialog as such share already exist
                        config.DEBUG && console.log("%cError. Share already exist", "color: Red")
                        this._showErrorDialog();
                        this.setState({ contentLoad: false }) //finish load
                    }
    
                    config.DEBUG && console.log("Space after title used")
                    this._showErrorDialog();
                    this.setState({ contentLoad: false }) //finish load
    
                } else {
    
                    graphService.getShareItemByTitle(this.state.newShareName).then(shareResult => {
                        config.DEBUG && console.log(shareResult.value)
                        if (shareResult.value != undefined && shareResult.value.length > 0) { //something returned
                            if (shareResult.value[0].fields.Title.toLowerCase() == this.state.newShareName.toLowerCase()) {
                                //show error dialog as such share already been requested
                                config.DEBUG && console.log("%cError. Share already exist on request list", "color: Red")
                                this._showErrorDialog();
                                this.setState({ contentLoad: false }) //finish load 
                            }
                        } else {
                            //first - set field NameChange to true on shares list:
                            graphService.renameShareInProgress(this.props.currentShareData.id).then(result => {
                                if (result) {
                                    //second - add item to changesharename list: 
                                    graphService.renameShare(this.props.currentShareData, this.state.newShareName)
                                    .then(result => {
                                        if (result) {
                                            // 
                                            this._showDialog()
                                            this.setState({ contentLoad: false }) //finish load
                                        } else {
                                            //stop spinner when failed:
                                            this.setState({ contentLoad: false }) //finish load
                                            //show error message:
                                            this._showErrorDialog();
                                        }
                                    })
                                } else {
                                    //stop spinner when failed:
                                    this.setState({ contentLoad: false }) //finish load
                                    //show error message:
                                    this._showErrorDialog();
                                }
                            })
                        }
                    })
                }
                //stopping spinner in case conditions failed:
                this.setState({ contentLoad: false })
            })
        } else {
            //no changes to title done. No need to trigger anything. Closing modal only:
            config.DEBUG && console.log("%cNo share title change triggered", "color: green")
            this._cancelRenameShare();
        }

        config.DEBUG && console.groupEnd()

    }

    private _showDialog = (): void => {
        this.setState({ showConfirmDialog: false, RenameShareButtonDisabled: false });
    }

    private _showErrorDialog = (): void => {
        this.setState({ showErrorDialog: false, RenameShareButtonDisabled: false });
    }

}