import * as React from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Label } from '@fluentui/react/lib/Label';

export interface LoadingSpinnerProps { lang: string }

export class LoadingSpinner extends React.Component<LoadingSpinnerProps> {
  constructor(props: LoadingSpinnerProps) {
      super(props);
  }

  render(){
      return (
      <div>
        <Label className="spinner-value" >{this.props.lang == "PL" ? "Ładowanie elementów" : "Loading items"}</Label>
        <Spinner ariaLive="assertive" labelPosition="top" size={SpinnerSize.large} className="spinner-value" />
      </div>
    );
  }
}
