import * as React from "react";
import * as ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import { App } from "./components/App";

//require("babel-polyfill");

ReactDOM.render(
    <App />,
    document.getElementById("root")
);