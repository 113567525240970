
import { IDatePickerStrings } from '@fluentui/react';
import { colorThemeLight } from './Themes';

export const PLCalendarSettings: IDatePickerStrings = {
    months: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
  
    shortMonths: ['Sty', 'Luty', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
  
    days: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
  
    shortDays: ['Ni', 'Po', 'Wt', 'Sr', 'Cz', 'Pi', 'So'],
  
    goToToday: 'Dzisiaj',
    prevMonthAriaLabel: 'Poprzedni miesiąc',
    nextMonthAriaLabel: 'Następny miesiąc',
    prevYearAriaLabel: 'Poprzedni rokr',
    nextYearAriaLabel: 'Następny rok',
    closeButtonAriaLabel: 'Zamknij kalendarz',
    isRequiredErrorMessage: 'Wymagana data początkowa.',
    invalidInputErrorMessage: 'Niepoprawny format daty.',
    
  };

  export const ENCalendarSettings: IDatePickerStrings = {
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  
    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  
    goToToday: 'Go to today',
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year',
    closeButtonAriaLabel: 'Close date picker',
    isRequiredErrorMessage: 'Start date is required.',
    invalidInputErrorMessage: 'Invalid date format.'
  };