import * as React from "react";
import { Header } from "./Header";
import { LoggedUser } from "./LoggedUser";
import { LoginPane } from "./LoginPane";
import { Messages } from "./Messages";
import { Footer } from "./Footer";
import { Row, Col } from "react-bootstrap";
import * as Msal from "msal";
import { GraphService } from "./GraphService";
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { FileBrowser } from "./FileBrowser";
import { ManualBrowser } from "./ManualBrowser";
import { ShareList } from "./ShareList"
import { loadTheme } from '@fluentui/react';


const config = require("./config.json");
const resource = require("../LangFiles/resource.json");
const { detect } = require('detect-browser');

//////////////////
//below theme is not used anywhere but for some reason it affects a lot of components when removed. 
//Its currently set to colorThemeLight values
const notUsedColorTheme = loadTheme({
    palette: {
        themePrimary: '#152e52',
        themeLighterAlt: '#f1f4f8',
        themeLighter: '#c8d4e3',
        themeLight: '#9eb1cb',
        themeTertiary: '#547097',
        themeSecondary: '#244066',
        themeDarkAlt: '#132a49',
        themeDark: '#10233e',
        themeDarker: '#0c1a2e',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#c2c2c2',
        neutralSecondary: '#858585',
        neutralPrimaryAlt: '#4b4b4b',
        neutralPrimary: '#333333',
        neutralDark: '#272727',
        black: '#1d1d1d',
        white: '#ffffff',
    }
});
//////////////////

export interface AppProps { }
export interface AppState {
    isAuthenticated: boolean,
    userName?: string,
    userEmail?: string,
    error?: string,
    mode: string,
    shareName?: string,
    shareGroupId?: string,
    shareSiteId?: string,
    upn?: string
    authToken?: string,
    goToShareMode: string;
    isAdmin?: boolean;
    isOperator?: boolean;
    isOperatorAccessOnly?: boolean;
    globalLang: string;
}

export class App extends React.Component<{}, AppState> {
    private userAgentApplication: Msal.UserAgentApplication;
    private msalConfig: Msal.Configuration;
    private account: Msal.Account;
    private msalAuthParams: Msal.AuthenticationParameters;
    private _authToken: string;

    constructor(props: AppProps) {
        super(props);
        initializeIcons();
        this.login = this.login.bind(this);
        this.onFileShareClick = this.onFileShareClick.bind(this);

        this.state = {
            isAuthenticated: false,
            mode: "sharelist",
            goToShareMode: "0",
            globalLang: ""
        }

        this.msalConfig = {
            auth: {
                clientId: config.appId,
                authority: config.authority
            },
            cache: {
                // cacheLocation: "sessionStorage",
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true
            }
        }

        this.msalAuthParams = {
            scopes: config.scopes
        }

        if (!this.userAgentApplication) {
            this.userAgentApplication = new Msal.UserAgentApplication(this.msalConfig);
        }
    }

    async login() { //old login method
        var loginRequest = {
            scopes: config.scopes
        }
        var tokenRequest = {
            scopes: config.scopes,
            authority: config.authority,
            forceRefresh: false
        }
        // var silentTokenRequest = {
        //     authority: config.authority,//test
        //     scopes: config.appId,
        //     forceRefresh: false //test
        // }

        this.userAgentApplication
            .acquireTokenSilent(tokenRequest)
            .then(response => {
                config.DEBUG && console.log("[[[[[ First silent token request Success ]]]]")
                
                this.account = this.userAgentApplication.getAccount();
                this.getUserDetails(this.account);
                this.checkAdminRights();

            })
            .catch(error => {
                config.DEBUG && console.log(error)
                config.DEBUG && console.log("[[[[ Login required. Calling login popup ]]]]");
                this.userAgentApplication.loginPopup(loginRequest)
                    .then(response => {
                        config.DEBUG && console.log("[[[[ Grabbing token silently after popup ]]]]");
                        this.userAgentApplication.acquireTokenSilent(tokenRequest)
                            .then(response => {
                                this.account = this.userAgentApplication.getAccount();
                                this.getUserDetails(this.account);
                                this.checkAdminRights();
                            })
                            .catch(error =>{
                                //
                            })
                    })
                    .catch(error => {
                        //
                    })
            });
    }

    // async loginTest() {
    //     var loginRequest = {
    //         scopes: config.scopes
    //     }
    //     var tokenRequest = {
    //         scopes: config.scopes,
    //         authority: config.authority,
    //         forceRefresh: false
    //     }

    //     this.userAgentApplication.acquireTokenSilent(tokenRequest).then(response => {
    //         config.DEBUG && console.log("[[[[[ First silent token request Success ]]]]")
    //         this._authToken = response.accessToken;
    //         this.account = this.userAgentApplication.getAccount();
    //         this.getUserDetails(this.account);
    //         this.checkAdminRights();
    //     }).catch(error => {
    //         if (error.errorMessage.indexOf("interaction_required") !== -1) {
    //             this.userAgentApplication.acquireTokenPopup(tokenRequest).then(response => {
    //                 config.DEBUG && console.log("[[[[[ Second Popup token request Success ]]]]")
    //                 this._authToken = response.accessToken;
    //                 this.account = this.userAgentApplication.getAccount();
    //                 this.getUserDetails(this.account);
    //                 this.checkAdminRights();
    //             }).catch(error => {
    //                 this.userAgentApplication.acquireTokenSilent(tokenRequest).then(response => {
    //                     config.DEBUG && console.log("[[[[[ Last silent token request Success ]]]]")
    //                     this._authToken = response.accessToken;
    //                     this.account = this.userAgentApplication.getAccount();
    //                     this.getUserDetails(this.account);
    //                     this.checkAdminRights();
    //                 });
    //             });
    //         };
    //     })
    // }

    async getUserDetails(account: Msal.Account) {
        var graphService = new GraphService(this._authToken, this.userAgentApplication);

        graphService.getUserDetails(account.accountIdentifier).then(user => {

            if (user) {
                config.DEBUG && console.log("User details obtained " + user.displayName);
                this.setState({
                    isAuthenticated: true,
                    userName: user.displayName,
                    userEmail: user.mail || user.userPrincipalName,
                    authToken: this._authToken,
                    upn: user.userPrincipalName,
                    mode: "sharelist"
                });
            } else {
                config.DEBUG && console.log("Cannot obtain user details");
                this.setState({
                    isAuthenticated: false
                });
            }
        }).catch(error => {
            config.DEBUG && console.log("Cannot obtain user details");
            config.DEBUG && console.log(error);
            this.setState({
                isAuthenticated: false
            });
        });
    }

    async logout() {
        this.userAgentApplication.logout();
        this.userAgentApplication.loginRedirect
    }

    async backLinkRefresh() {
        //Clear query string as we need it only for first entry
        var uri = window.location.toString();
        if (uri.indexOf("?") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("?"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        this.setState({ mode: "sharelist" });
    }

    async setAppLanguage(fromButton: string) {
        this.setState({ globalLang: fromButton })
        config.DEBUG && console.log('Change language triggered. Set to: ' + fromButton)
    }

    async fileBrowserRefresh() {
        this.setState({ mode: "filelist" });
    }

    onFileShareClick(shareName: string, shareGroupId: string) {

        if (shareGroupId === "1") {
            config.DEBUG && console.log(shareName + " clicked onMAnual parent");
            this.setState({
                mode: "manuallist",
                shareName: shareName,
                shareGroupId: shareGroupId
            });
            //add sharedata to url parameters
            var uri = window.location.toString();

            if (uri.indexOf("?") < 1) {
                var newUriString: string = "?manuals"
                window.history.replaceState({}, document.title, newUriString);
            }

        } else {
            config.DEBUG && console.log(shareName + " clicked onFileShareClick parent");
            this.setState({
                mode: "filelist",
                shareName: shareName,
                shareGroupId: shareGroupId
            });
            //add sharedata to url parameters
            var uri = window.location.toString();

            if (uri.indexOf("?") < 1) {
                var newUriString: string = "?groupId=" + shareGroupId + "&goToShare=true&siteName=" + shareName
                config.DEBUG && console.log(newUriString)
                window.history.replaceState({}, document.title, newUriString);

            }
        }
    }

    checkIfDirectShareEntry(): Array<string> {
        //Get params from URL
        let params: URLSearchParams = new URLSearchParams(window.location.search);
        config.DEBUG && console.log(" GroupID: " + params.get("groupId") + " goToShare: " + params.get("goToShare"));

        var paramArray: string[] = new Array();

        let groupId: string = params.get("groupId");
        let siteName: string = params.get("siteName");
        let goToShare: string = params.get("goToShare");

        paramArray.push(groupId)
        paramArray.push(goToShare)
        paramArray.push(siteName)
        //config.DEBUG && console.log(paramArray);

        return paramArray;
    }

    async checkAdminRights() {

        var tokenRequest = {
            scopes: config.scopes
        }

        this.userAgentApplication.acquireTokenSilent(tokenRequest).then(response => {
            this._authToken = response.accessToken;
            var graphService = new GraphService(response.accessToken, this.userAgentApplication);

            //checking if SWP Admin
            graphService.checkIfSWPAdmin().then(result => {
                config.DEBUG == true && console.log("Checking if ADMIN")
                if (result) {
                    this.setState({ isAdmin: true });
                    config.DEBUG == true && console.log("%%%% ADMIN %%%%")
                } else {
                    this.setState({ isAdmin: false });
                }
            })

            //checking if operator Access Only
            // graphService.checkIfSWPOperatorAccessOnly().then(result => {
            //     config.DEBUG == true && console.log("Checking if ACCES ONLY OPERATOR")
            //     if (result) {
            //         this.setState({ isOperatorAccessOnly: true });
            //         config.DEBUG ==true && console.log("%%%% ACCESS ONLY OPERATOR %%%%")
            //     } else {
            //         this.setState({ isOperatorAccessOnly: false });
            //     }
            // })

            //checking if SWP Operator
            graphService.checkIfSWPOperator().then(result => {
                config.DEBUG == true && console.log("Checking if OPERATOR")
                if (result) {
                    this.setState({ isOperator: true });
                    config.DEBUG == true && console.log("%%%% OPERATOR %%%%")
                } else {
                    this.setState({ isOperator: false });
                }
            })
        })
    }

    async componentDidMount() {
        //setting language based on browser settings:
        var language = navigator.language.substring(0, 2)
        config.DEBUG && console.log(language)
        if (language == "pl") {
            this.setState({
                globalLang: "PL"
            })
        } else {
            this.setState({
                globalLang: "EN"
            })
        }

        //
        var paramArray = this.checkIfDirectShareEntry()

        this.account = this.userAgentApplication.getAccount();

        if (this.account) {
            config.DEBUG && console.log("Account object: " + this.account.userName + " " + this.account.accountIdentifier);
            config.DEBUG && console.log("Account object exists.");
            config.DEBUG && console.log(this._authToken);
            config.DEBUG && console.log(this.account);
            config.DEBUG && console.log("Created token request.");
            config.DEBUG && console.log("Acquiring token.");

            var tokenRequest = {
                scopes: config.scopes
            }

            config.DEBUG && console.log(tokenRequest);

            this.userAgentApplication.acquireTokenSilent(tokenRequest).then(response => {
                this._authToken = response.accessToken;
                var graphService = new GraphService(response.accessToken, this.userAgentApplication);
                var user = graphService.getUserDetails(this.account.accountIdentifier).then(result => {
                    config.DEBUG && console.log("Account object exists. Getting profile for user: " + result.userPrincipalName);

                    this.checkAdminRights()

                    //check for searchParams
                    if (paramArray[1] != null && paramArray[1] == "true") { //when direct
                        config.DEBUG && console.log("*****Should go Directly to share!!!!!")

                        this.setState({
                            isAuthenticated: true,
                            userName: result.displayName,
                            userEmail: result.mail || result.userPrincipalName,
                            error: "",
                            upn: result.userPrincipalName,
                            authToken: response.accessToken
                        });

                        this.onFileShareClick(paramArray[2], paramArray[0])

                    } else { //when not direct
                        //config.DEBUG && console.log("*****Should stay in shareList view!!!!!")

                        this.setState({
                            isAuthenticated: true,
                            userName: result.displayName,
                            userEmail: result.mail || result.userPrincipalName,
                            error: "",
                            upn: result.userPrincipalName,
                            authToken: response.accessToken,
                            mode: "sharelist"
                        });
                    }
                    //config.DEBUG && console.log("Account object exists. Mode: " + this.state.mode);
                });
            });


        } else {
            config.DEBUG && console.log("Account object does not exists.");
            this.setState({
                isAuthenticated: false,
                userName: "",
                userEmail: "",
                error: "",
                mode: "login",
                shareName: "",
                shareGroupId: "",
                shareSiteId: "",
                upn: ""
            });

            await this.login();
        }
    }

    render() {
        const { globalLang, isOperatorAccessOnly, isOperator, isAdmin } = this.state
        if (this.state.isAuthenticated && this.state.mode == "sharelist") {
            return (

                <div className="container bg-swpStyle-app-light">
                    <Row>
                        <Col>
                            <div style={{ height: '8px' }}></div>
                            <LoggedUser userName={this.state.userName} lang={globalLang} showLangButton={true} setGlobalLangMethod={this.setAppLanguage.bind(this)} />
                            <Header isAuthenticated={this.state.isAuthenticated}
                                userName={this.state.userName}
                                userEmail={this.state.userEmail}
                                authButtonMethod={this.logout.bind(this)}
                                isGuest={this.state.upn}
                                onFileShareClick={this.onFileShareClick}
                                lang={globalLang}
                            />
                            <ShareList
                                onFileShareClick={this.onFileShareClick}
                                authToken={this.state.authToken}
                                isAdmin={isAdmin}
                                isOperator={isOperator}
                                // isOperatorAccessOnly={isOperatorAccessOnly}
                                userName={this.state.userName}
                                lang={globalLang}
                            />
                            <Messages authToken={this.state.authToken} lang={globalLang} />
                            <Footer />
                        </Col>
                    </Row>
                </div>

            );
        } else if (this.state.isAuthenticated && this.state.mode == "filelist") {
            return (
                <div className="container bg-swpStyle-app-light">
                    <Row>
                        <Col>
                            <div style={{ height: '8px' }}></div>
                            <LoggedUser userName={this.state.userName} lang={globalLang} showLangButton={true} setGlobalLangMethod={this.setAppLanguage.bind(this)} />
                            <Header isAuthenticated={this.state.isAuthenticated}
                                userName={this.state.userName}
                                userEmail={this.state.userEmail}
                                authButtonMethod={this.logout.bind(this)}
                                isGuest={this.state.upn}
                                onFileShareClick={this.onFileShareClick}
                                lang={globalLang}
                            />
                            <FileBrowser shareName={this.state.shareName}
                                shareGroupId={this.state.shareGroupId}
                                backLinkMethod={this.backLinkRefresh.bind(this)}
                                fileBrowserRefresh={this.fileBrowserRefresh.bind(this)}
                                authToken={this.state.authToken}
                                currentUserUPN={this.state.upn}
                                isAdmin={isAdmin}
                                isOperator={isOperator}
                                // isOperatorAccessOnly={isOperatorAccessOnly}
                                lang={globalLang}
                            />
                            <Footer />
                        </Col>
                    </Row>
                </div>
            );
        } else if (this.state.isAuthenticated && this.state.mode == "manuallist") {
            return (
                <div className="container bg-swpStyle-app-light">
                    <Row>
                        <Col>
                            <div style={{ height: '8px' }}></div>
                            <LoggedUser userName={this.state.userName} lang={globalLang} showLangButton={true} setGlobalLangMethod={this.setAppLanguage.bind(this)} />
                            <Header isAuthenticated={this.state.isAuthenticated}
                                userName={this.state.userName}
                                userEmail={this.state.userEmail}
                                authButtonMethod={this.logout.bind(this)}
                                isGuest={this.state.upn}
                                onFileShareClick={this.onFileShareClick}
                                lang={globalLang}
                            />
                            <ManualBrowser shareName={resource.str_ManualsShareName}
                                shareGroupId=""
                                shareSiteId={config.helpSiteId}
                                backLinkMethod={this.backLinkRefresh.bind(this)}
                                authToken={this.state.authToken}
                                lang={globalLang}
                            />
                            {/* <Footer/> */}
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return (
                <div className="container bg-swpStyle-app-light">
                    <Row>
                        <Col>
                            <div style={{ height: '8px' }}></div>
                            <LoggedUser userName={this.state.userName} lang={globalLang} showLangButton={true} setGlobalLangMethod={this.setAppLanguage.bind(this)} />
                            <Header isAuthenticated={this.state.isAuthenticated}
                                userName={null}
                                userEmail={null}
                                authButtonMethod={null}
                                isGuest={null}
                                onFileShareClick={null}
                                lang={globalLang}
                            />
                            <LoginPane loginUser={this.login} lang={globalLang} />
                            <Footer />
                        </Col>
                    </Row>
                </div>
            );
        }
    }
}