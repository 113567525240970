
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as Msal from "msal";
import { GraphService } from "./GraphService";
import { Label, IIconProps, FontIcon, DefaultButton } from '@fluentui/react';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { ShareMenu } from './ShareMenu';
import { NewUserForm } from '../Forms/NewUserForm';
import { ChangeStatusForm } from '../Forms/ChangeStatusForm';
import { SetAlertForm } from '../Forms/SetAlertForm';
import { DeleteShareForm } from '../Forms/DeleteShareForm';
import { ExpandShareForm } from '../Forms/ExpandShareForm';
import { RenameShareForm } from '../Forms/RenameShareForm';
import { Button } from "react-bootstrap";
import { ExtendShareForm } from "../Forms/ExtendShareForm";
import { GeneratePermListForm } from "../Forms/GeneratePermListForm";
import { LoadingSpinner } from "./LoadingSpinner";
import { UserListIE } from '../Panels/UserListIE';
import { colorThemeLight, colorThemeDark } from './Themes';

const config = require("./config.json");
// const resource = require("../LangFiles/resource.json");

const refreshIcon: IIconProps = { iconName: 'Refresh' };
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const iconClass = mergeStyles({
    paddingLeft: '20px',
    paddingRight: '20px',
    marginTop: 'auto',
    marginBottom: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
});

const errorIconClass = mergeStyles({
    paddingLeft: '20px',
    paddingRight: '20px',
    marginTop: 'auto',
    marginBottom: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'Orange'
});

const errorLabelClass = mergeStyles({
    paddingLeft: '5px',
    paddingRight: '5px',
    marginTop: 'auto',
    marginBottom: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'rgb(255, 139, 19)'
});

const errorXLabelClass = mergeStyles({
    paddingLeft: '5px',
    paddingRight: '5px',
    marginTop: 'auto',
    marginBottom: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'red'
});

const errorBlockClass = mergeStyles({
    //   borderStyle: 'dashed',
    //   borderColor: 'rgb(0,105,95)',
    marginLeft: '5px',
    display: 'flex'
})

const wrnUsageClass = mergeStyles({
    color: 'rgb(255, 139, 19)'
})

const errUsageClass = mergeStyles({
    color: 'red'
})

export interface ShareDetailsProps { 
    shareGroupId: string, 
    authToken: string, 
    refreshMethod: any, 
    shareListRefreshMethod: any,
    alertRefreshMethod: any,
    lang: string,
    currentUserUPN: string,
    userAlertState: number,
    inviteId: number
};
export interface ShareDetailsState {
    shareItem?: any,
    showNewUserModal: boolean,
    showChangeStatusModal: boolean,
    showChangeTypeModal: boolean,
    showExtendShareModal: boolean,
    showExpandShareModal: boolean,
    showDeleteShareModal: boolean
    showGeneratePermListModal: boolean,
    showSetAlertModal: boolean,
    showRenameShareModal: boolean,
    loadingStateShareDetails: boolean,
    isUserListOpen: boolean,
    usageFullCheck: boolean,
    showCoachMarkForMenu: boolean,
    buttonClick: boolean,
};

export class ShareDetails extends React.Component<ShareDetailsProps, ShareDetailsState> {
    // private msalConfig: Msal.Configuration;
    private userAgentApplication: Msal.UserAgentApplication;
    private newUserForm = React.createRef<NewUserForm>();
    private changeStatusForm = React.createRef<ChangeStatusForm>();
    private extendShareForm = React.createRef<ExtendShareForm>();
    private expandShareForm = React.createRef<ExpandShareForm>();
    private renameShareForm = React.createRef<RenameShareForm>();
    private deleteShareForm = React.createRef<DeleteShareForm>();
    private generatePermListForm = React.createRef<GeneratePermListForm>();
    private setAlertForm = React.createRef<SetAlertForm>();
    private _targetItem = React.createRef<HTMLDivElement>();

    constructor(props: ShareDetailsProps) {
        super(props);
        this.state = {
            shareItem: [],
            showNewUserModal: false,
            showChangeStatusModal: false,
            showChangeTypeModal: false,
            showExtendShareModal: false,
            showExpandShareModal: false,
            showDeleteShareModal: false,
            showGeneratePermListModal: false,
            showSetAlertModal: false,
            showRenameShareModal: false,
            loadingStateShareDetails: false,
            isUserListOpen: false,
            usageFullCheck: false,
            showCoachMarkForMenu: false,
            buttonClick: false
        };
    }

    render() {

        //*** Language section ***
        let resource: any;
        if (this.props.lang == "PL") {
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        const { shareItem, buttonClick } = this.state;
        var sizeSuffix = "";
        var sizeValue = null;
        var dateNearExpiryCheck = false;
        var dateExpiredCheck = false;
        let today = new Date;
        let curClosureDate = new Date(shareItem.DataZamkniecia); //Regional strings
        var usageNearFullCheck = shareItem.ZuzyteZasoby > shareItem.WielkoscObszaru * 0.9 //Regional strings
        var wrnDaysCountInt: number = Number(config.wrnClosureDateCheck)

        // config.DEBUG && console.log(shareItem)

        var wrnClosureDate:any = addDays(curClosureDate, -wrnDaysCountInt);
        if(today >= wrnClosureDate && today < curClosureDate){
            dateNearExpiryCheck = true
            // dateExpiredCheck = false
            
        } else if(today >= curClosureDate){
            dateNearExpiryCheck = false
            // dateExpiredCheck = true
        }

        if(shareItem.Wygaszony == "1"){
            dateExpiredCheck = true
        } else {
            dateExpiredCheck = false
        }

        if (shareItem.ZuzyteZasoby && shareItem.WielkoscObszaru) {
            if (shareItem.ZuzyteZasoby == shareItem.WielkoscObszaru) {
                this.setState({ usageFullCheck: true })
            }
        }

        if (shareItem.ZuzyteZasoby > 999) { //Regional strings
            sizeSuffix = "GB";
            //sizeValue = Math.round(shareItem.ZuzyteZasoby / 1024); //Regional strings
            sizeValue = (shareItem.ZuzyteZasoby / 1024).toFixed(1); //Regional strings
        }
        else {
            sizeSuffix = "MB";
            sizeValue = shareItem.ZuzyteZasoby; //Regional strings
        }


        //setting share status and type:
        var shareDisabled = null;
        var shareStatus = "";
        var shareType = "";
        // var showDate = null;
        var translatedShareStatus = "";
        var reversedtranslatedShareStatus = "";
        var statusBar: boolean = false;

        function addDays(date: Date, days: number) {
            var result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        }

        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        const optionsShort: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };

        if (this.props.lang == "PL") {
            var returnDate = curClosureDate.toLocaleDateString('pl-pl', options);
        } else {
            var returnDate = curClosureDate.toLocaleDateString('en-uk', options);
        }

        if (shareItem.StatusObszaru == resource.str_ShareStatusEnabled) { //for enabled share
            shareStatus = resource.str_ShareStatusDisabledString; //set to reverse for button label change
            shareDisabled = false; //keep read value
            translatedShareStatus = resource.str_ShareStatusEnabledLang
            reversedtranslatedShareStatus = resource.str_ShareStatusDisabledLang

        } else if (shareItem.StatusObszaru == resource.str_ShareStatusDisabled) { //for disabled share
            shareStatus = resource.str_ShareStatusEnabledString; //set to reverse for button label change
            shareDisabled = true; //keep read value
            translatedShareStatus = resource.str_ShareStatusDisabledLang
            reversedtranslatedShareStatus = resource.str_ShareStatusEnabledLang

        } else { //for share in intermediate state
            translatedShareStatus = resource.str_FieldValueShareChangeInProgress
            // statusBar = true
        }

        if (shareItem.OpoznionaDataDezaktywacji != null) {
            var delayedDate = new Date(shareItem.OpoznionaDataDezaktywacji);
            var delayedString = resource.str_FieldValueDelayedMessage + delayedDate.toLocaleDateString('pl-pl', optionsShort)
            statusBar = true
        }

        return (
            <div>
                <Accordion defaultActiveKey="0" bsPrefix="swpStyle-wide-panel">
                    <div style={{ display: "flex" }}>
                        <Accordion.Toggle as={Button} eventKey="1" variant="link" style={{ color: "#152e52" }}>
                            {resource.str_ShareDetails}<FontIcon iconName="ChevronDown" style={{ paddingLeft: '0px' }} />
                        </Accordion.Toggle>
                        {shareItem.DodatkoweZabezpieczenia ?
                            <FontIcon iconName="LockSolid" className={iconClass} /> :
                            <div></div>
                        }
                        {shareItem.OpoznionaDataDezaktywacji != null ?
                            <FontIcon iconName="Timer" className={errorIconClass} /> :
                            <div></div>
                        }
                        {usageNearFullCheck && !this.state.usageFullCheck ?
                            <div className={errorBlockClass}>
                                <FontIcon iconName="Warning12" className={errorLabelClass} />
                                <Label className={errorLabelClass}>{resource.str_WrnLowResources}</Label>
                            </div> :
                            <div></div>
                        }
                        {this.state.usageFullCheck ?
                            <div className={errorBlockClass}>
                                <FontIcon iconName="Warning12" className={errorLabelClass} />
                                <Label className={errorLabelClass}>{resource.str_WrnNOResources}</Label>
                            </div> :
                            <div></div>
                        }
                        {dateNearExpiryCheck ?
                            <div className={errorBlockClass}>
                                <FontIcon iconName="Warning12" className={errorLabelClass} />
                                <Label className={errorLabelClass}>{resource.str_WrnNearExpiry}</Label>
                            </div> :
                            <div></div>

                        }
                        {dateExpiredCheck ?
                            <div className={errorBlockClass}>
                                <FontIcon iconName="BlockedSiteSolid12" className={errorXLabelClass} />
                                <Label className={errorXLabelClass}>{resource.str_WrnShareExpired}</Label>
                            </div> :
                            <div></div>

                        }
                        {shareItem.Wygaszony == "0" ?
                        <div className="swpStyle-menu-buttons-right" ref={this._targetItem}>
                            <ShareMenu
                                isReadOnly={shareStatus}
                                shareStateLang={reversedtranslatedShareStatus}
                                isLimited={false}
                                isLimitedValue={shareType}
                                currentItem={shareItem}
                                newUserOnClickMethod={this._showNewUserModal.bind(this)}
                                modifyUsersMethod={this.openUserListPanel.bind(this)}
                                changeStatusOnClickMethod={this._showChangeStatusModal.bind(this)}
                                generatePermListMethod={this._showGenerateListModal.bind(this)}
                                deleteShareMethod={this._deleteShareMethodModal.bind(this)}
                                extendShareMethod={this._extendShareMethodModal.bind(this)}
                                expandShareMethod={this._expandShareMethodModal.bind(this)}
                                refreshMethod={this.forceRefreshThisComponent.bind(this)}
                                setAlertListMethod={this._setAlertMethodModal.bind(this)}
                                renameShareMethod={this._renameShareMethodModal.bind(this)}
                                lang={this.props.lang}
                                userAlertState={this.props.userAlertState}
                            />
                        </div>
                        : <div></div>
                        }
                    </div>
                    {/* Regional strings in below Container */}
                    <Accordion.Collapse eventKey="1">
                        {this.state.loadingStateShareDetails ? <LoadingSpinner lang={this.props.lang} /> :
                            <Card.Body>
                                <Container>
                                    <Row>
                                        <Col>
                                            <Label disabled={true} className="swpStyle-form-label-titles">{resource.str_FieldCompany}:</Label>
                                            <Label disabled={false} className="swpStyle-form-label-values">{shareItem.Firma}</Label>
                                        </Col>
                                        <Col>
                                            <Label disabled={true} className="swpStyle-form-label-titles">{resource.str_FieldShareSize}:</Label>
                                            <Label disabled={false} className="swpStyle-form-label-values">{shareItem.WielkoscObszaru / 1024} GB</Label>
                                        </Col>
                                        <Col>
                                            <Label disabled={true} className="swpStyle-form-label-titles">{resource.str_FieldSharestatus}:</Label>
                                            <Label disabled={false} className="swpStyle-form-label-values">{translatedShareStatus}</Label>
                                        </Col>
                                        <Col>
                                            <Label disabled={true} className="swpStyle-form-label-titles">{resource.str_FieldShareID}:</Label>
                                            <Label disabled={false} className="swpStyle-form-label-values">{shareItem.IDObszaru}</Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label disabled={true} className="swpStyle-form-label-titles">{resource.str_FieldShareOwnerName}:</Label>
                                            <Label disabled={false} className="swpStyle-form-label-values">{shareItem.NazwaOperatora}</Label>
                                        </Col>
                                        <Col>
                                            <Label disabled={true} className="swpStyle-form-label-titles">{resource.str_FieldUsedResources}:</Label>
                                            {usageNearFullCheck ?
                                                <Label disabled={false} className={`${"swpStyle-form-label-values"} ${wrnUsageClass}`}>{sizeValue} {sizeSuffix}</Label> :
                                                <Label disabled={false} className="swpStyle-form-label-values">{sizeValue} {sizeSuffix}</Label>
                                            }
                                        </Col>
                                        <Col>
                                            <Label disabled={true} className="swpStyle-form-label-titles">{resource.str_FieldClosureDateDisplay}:</Label>
                                            {dateNearExpiryCheck && <Label disabled={false} className={`${"swpStyle-form-label-values"} ${wrnUsageClass}`}>{returnDate}</Label>}
                                            {dateExpiredCheck && <Label disabled={false} className={`${"swpStyle-form-label-values"} ${errUsageClass}`}>{returnDate}</Label>}
                                            {!dateNearExpiryCheck && !dateExpiredCheck && <Label disabled={false} className="swpStyle-form-label-values">{returnDate}</Label>}
                                        </Col>
                                        <Col>
                                        </Col>
                                    </Row>
                                    {statusBar &&
                                        <Row style={{ color: "Orange", justifyContent: "center", paddingTop: "20px", fontWeight: "bold", alignItems: "center" }}>
                                            {delayedString}!&nbsp;&nbsp;
                                            {/* <CommandBarButton iconProps={cancelIcon} text={"Anuluj"} onClick={this._onCancelDelayedStatusChange.bind(this)} theme={colorThemeLight} checked={null} style={{ backgroundColor: 'RGBA(0,0,0,0)' }} /> */}
                                            <DefaultButton disabled={buttonClick} iconProps={cancelIcon} text={resource.str_BtnCancelDelay} onClick={this._onCancelDelayedStatusChange.bind(this)} allowDisabledFocus theme={colorThemeLight}/>
                                        </Row>
                                    }
                                </Container>
                            </Card.Body>
                        }
                    </Accordion.Collapse>
                </Accordion>
                <NewUserForm
                    isOpen={this.state.showNewUserModal}
                    ref={this.newUserForm}
                    authToken={this.props.authToken}
                    userAgentApplication={this.userAgentApplication}
                    currentShareData={shareItem}
                    shareUsersRefreshMethod={this.forceRefreshThisComponent.bind(this)} //property not used on the form due to unefficient result
                    lang={this.props.lang}
                />
                <ChangeStatusForm
                    isOpen={this.state.showChangeStatusModal}
                    ref={this.changeStatusForm}
                    authToken={this.props.authToken}
                    userAgentApplication={this.userAgentApplication}
                    currentShareData={shareItem}
                    shareState={shareStatus}
                    shareStateLang={reversedtranslatedShareStatus}
                    shareDetailsRefreshMethod={this.forceRefreshThisComponent.bind(this)}
                    lang={this.props.lang}
                />
                <ExtendShareForm
                    isOpen={this.state.showExtendShareModal}
                    ref={this.extendShareForm}
                    authToken={this.props.authToken}
                    userAgentApplication={this.userAgentApplication}
                    currentShareData={shareItem}
                    shareDetailsRefreshMethod={this.runGraphService.bind(this)}
                    lang={this.props.lang}
                />
                <ExpandShareForm
                    isOpen={this.state.showExtendShareModal}
                    ref={this.expandShareForm}
                    authToken={this.props.authToken}
                    userAgentApplication={this.userAgentApplication}
                    currentShareData={shareItem}
                    shareDetailsRefreshMethod={this.runGraphService.bind(this)}
                    lang={this.props.lang}
                />
                <DeleteShareForm
                    isOpen={this.state.showDeleteShareModal}
                    ref={this.deleteShareForm}
                    authToken={this.props.authToken}
                    userAgentApplication={this.userAgentApplication}
                    currentShareData={shareItem}
                    shareDetailsRefreshMethod={this.runGraphService.bind(this)}
                    refreshAfterDeleteMethod={this.props.refreshMethod}
                    lang={this.props.lang}
                />
                <GeneratePermListForm
                    isOpen={this.state.showGeneratePermListModal}
                    ref={this.generatePermListForm}
                    authToken={this.props.authToken}
                    userAgentApplication={this.userAgentApplication}
                    currentShareData={shareItem}
                    shareDetailsRefreshMethod={this.runGraphService.bind(this)}
                    refreshAfterDeleteMethod={this.props.refreshMethod}
                    lang={this.props.lang}
                />
                <SetAlertForm
                    isOpen={this.state.showSetAlertModal}
                    authToken={this.props.authToken}
                    ref={this.setAlertForm}
                    userAgentApplication={this.userAgentApplication}
                    currentShareData={shareItem}
                    shareDetailsRefreshMethod={this.runGraphService.bind(this)}
                    refreshAfterAlertMethod={this.props.alertRefreshMethod}
                    lang={this.props.lang}
                    currentUserUPN={this.props.currentUserUPN}
                    userAlertState={this.props.userAlertState}
                    inviteId={this.props.inviteId}
                />
                <RenameShareForm 
                    isOpen={this.state.showExtendShareModal}
                    ref={this.renameShareForm}
                    authToken={this.props.authToken}
                    userAgentApplication={this.userAgentApplication}
                    currentShareData={shareItem}
                    shareDetailsRefreshMethod={this.runGraphService.bind(this)}
                    lang={this.props.lang}
                />
                {shareItem.EmailOperatora != undefined ? <UserListIE
                    closePanelMethod={this.closeUserListPanel.bind(this)}
                    isOpen={this.state.isUserListOpen}
                    authToken={this.props.authToken}
                    userAgentApplication={this.userAgentApplication}
                    shareGroupId={this.props.shareGroupId}
                    ownerEmail={shareItem.EmailOperatora} //regional string
                    ownerName={shareItem.NazwaOperatora} //regional string
                    newUserMethod={this._showNewUserModal.bind(this)}
                    isShareDisabled={shareDisabled}
                    currentShareData={shareItem}
                    lang={this.props.lang}
                    currentUserUPN={this.props.currentUserUPN}
                /> : null}
            </div>
        );
    }

    async componentDidMount() {
        this.runGraphService();
        //this.setState({ showCoachMarkForMenu: true })

    }

    private forceRefreshThisComponent() {
        this.runGraphService();
        this.props.alertRefreshMethod(); //performs refresh on invitation list to get correct alert settings for current user
        this.props.shareListRefreshMethod();
    }

    private async runGraphService() {
        var graphService = new GraphService(this.props.authToken, this.userAgentApplication);

        if (!this.state.loadingStateShareDetails) {
            this.setState({ loadingStateShareDetails: true });
        }

        await graphService.getShareListItem(this.props.shareGroupId).then(
            result => {
                this.setState({
                    shareItem: result.value[0].fields
                })
            }
        )

        if (this.state.loadingStateShareDetails) {
            this.setState({ loadingStateShareDetails: false });
        }
        this.setState({buttonClick: false})
    }

    private _showNewUserModal = (): void => {
        config.DEBUG && console.log("!!! New Share Modal triggered ");
        this.setState({ showNewUserModal: true });
        this.newUserForm.current._openNewUserModal();
    };

    private _showChangeStatusModal = (): void => {
        config.DEBUG && console.log("!!! Confirm Action Modal triggered ");
        this.setState({ showChangeStatusModal: true });
        this.changeStatusForm.current._openChangeStatusModal();
    };

    private _showGenerateListModal = (): void => {
        config.DEBUG && console.log("!!! Generate Perm List Modal triggered ");
        this.setState({ showGeneratePermListModal: true });
        this.generatePermListForm.current._openGeneratePermListModal();
    };

    private _deleteShareMethodModal = (): void => {
        config.DEBUG && console.log("!!! Delete Share Modal triggered ");
        this.setState({ showDeleteShareModal: true });
        this.deleteShareForm.current._openDeleteShareModal();
    };

    private _extendShareMethodModal = (): void => {
        config.DEBUG && console.log("!!! ExtendShare Modal triggered ");
        this.setState({ showExtendShareModal: true });
        this.extendShareForm.current._openExtendShareModal();
    };

    private _expandShareMethodModal = (): void => {
        config.DEBUG && console.log("!!! ExpandShare Modal triggered ");
        this.setState({ showExpandShareModal: true });
        this.expandShareForm.current._openExpandShareModal();
    };

    private _renameShareMethodModal = (): void => {
        config.DEBUG && console.log("!!! RenameShare Modal triggered ");
        this.setState({ showRenameShareModal: true });
        this.renameShareForm.current._openRenameShareModal();
    };

    private _setAlertMethodModal = (): void => {
        config.DEBUG && console.log("!!! Set Alert Modal triggered ");
        this.setState({ showSetAlertModal: true });
        this.setAlertForm.current._openSetAlertModal();
    };

    private openUserListPanel = (): void => {
        this.setState({ isUserListOpen: true });
    };

    private closeUserListPanel() {
        this.setState({ isUserListOpen: false, buttonClick: false });
    };

    private _onCancelDelayedStatusChange(){
        this.setState({buttonClick: true}) //disabling button to stop users from clicking like crazy
        var graphService = new GraphService(this.props.authToken, this.userAgentApplication);

        graphService.getDelayedStatusChangeItem(this.state.shareItem.IdentyfikatorGrupy).then(result =>{
            config.DEBUG && console.log(result.value[0]);
            graphService.removeDelayedStatusChange(result.value[0].id).then(result => {
                config.DEBUG && console.log(result.ok);
                graphService.changeShareStatusOnGoing("Zapis", this.state.shareItem.id, null).then(result =>{
                    //
                    config.DEBUG && console.log(result);
                    console.log("=== Delayed status change finished. Forcing refresh.")
                    this.runGraphService()
                })
            })
        })


    }
}