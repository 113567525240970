// File created for IE browser only. 
import * as React from 'react';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import * as Msal from "msal";
import { GraphService } from "../components/GraphService";
import { ShareUsers } from '../components/ShareUsers';
import { LoadingSpinner } from "../components/LoadingSpinner";
// import { NewUserForm } from '../Forms/NewUserForm';
const config = require("../components/config.json");
// const resource = require("../LangFiles/resource.json");
const gblResource = require("../LangFiles/resource.json");
const gblResourcePL = require("../LangFiles/resourcePL.json");

interface IUserItem {
    key: number;
    displayName: any;
    lastName: string;
    firstName: string;
    userType: string;
    upn: string;
    email: string;
    shareItemId: string;
    accountName: string;
    isBlocked: string;
    itemId: number;
    isOperator: boolean;
    roleChange: string;
}

export interface UserListIEProps {
    closePanelMethod: any,
    isOpen: boolean,
    shareGroupId: string,
    authToken: string,
    userAgentApplication: Msal.UserAgentApplication,
    newUserMethod: any,
    isShareDisabled: boolean,
    lang: string,
    ownerEmail: string,
    ownerName: string,
    currentShareData: any,
    currentUserUPN: string
};

export interface UserListIEState {
    isDialogVisible: boolean,
    allUsers?: IUserItem[],
    userLoadState: boolean,
    showNewUserModal: boolean,
    stateOwnerEmail?: string,
    stateOwnerName?: string
};

export class UserListIE extends React.Component<UserListIEProps, UserListIEState> {
    private _allUsers: IUserItem[];
    constructor(props: UserListIEProps) {
        super(props);
        this.state = {
            isDialogVisible: false,
            userLoadState: false,
            showNewUserModal: false
        };
    }

    //***********************************************************************************************
    //This method forcecully refreshes UserListIE component to show user correct Owner after change.
    //***********************************************************************************************
    userListUpdate = () => {
        this.forceUpdate();
        //config.DEBUG && console.log("@@ UserList component forcefully updated !!!")
    };

    render() {

        //*** Language section ***
        let resource: any;
        if (this.props.lang == "PL") {
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        const { Provider, Consumer } = React.createContext(this.forceRefreshPanel.bind(this));

        const dialogContentProps = {
            type: DialogType.normal,
            title: resource.str_MsgConfirmUserListCancel
        };
        const dialogModalProps = {
            isBlocking: true,
            styles: { main: { maxWidth: 450 } }
        };

        return (
            <div>
                {/* <DefaultButton text="Open panel" onClick={openPanel} /> */}
                <Panel
                    isOpen={this.props.isOpen}
                    onDismiss={this.hideDialogAndPanel}
                    closeButtonAriaLabel={resource.str_BtnDismiss}
                    type={PanelType.custom}
                    customWidth={'1000px'}
                >
                    <div style={{ display: 'flex' }}>
                        <DefaultButton onClick={this.props.newUserMethod} text={resource.str_MenuAddUser} style={{ marginLeft: 'auto' }} disabled={false} />
                        <DefaultButton onClick={this.forceRefreshPanel.bind(this)} text={resource.str_MenuRefresh} style={{ marginLeft: '10px', marginRight: '50px' }} />
                    </div>
                    {this.state.userLoadState ? <LoadingSpinner lang={this.props.lang} /> :
                        <div>
                            <ShareUsers
                                authToken={this.props.authToken}
                                userAgentApplication={this.props.userAgentApplication}
                                userArray={this.state.allUsers}
                                isExternalList={true}
                                listTitle={resource.str_HeaderUserListTitle}
                                userRefreshMethod={this.forceRefreshPanel.bind(this)}
                                // spoRefreshMethod={this.getOwnerDataFromSPO.bind(this)}
                                lang={this.props.lang}
                                //ownerEmail={this.props.ownerEmail}
                                ownerEmail={this.props.currentUserUPN} //test
                                groupId={this.props.shareGroupId}
                            />
                        </div>
                    }
                </Panel>
                <Dialog
                    hidden={!this.state.isDialogVisible}
                    onDismiss={this.hideDialog}
                    dialogContentProps={dialogContentProps}
                    modalProps={dialogModalProps}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={this.hideDialogAndPanel} text={resource.str_BtnOK} />
                        <DefaultButton onClick={this.hideDialog} text={resource.str_BtnCancel} />
                    </DialogFooter>
                </Dialog>
            </div>
        );


    }

    async componentDidMount() {
        config.DEBUG && console.log("userList mounted");
        // this.getOwnerData().then(result => {
        //     this.collectGraphData();
        // })

        this.collectGraphData();

    }

    //***********************************************************************************************
    //This method fores UserListIE panel data to refresh by collecting and rendering share users and
    // reloading whole component.
    //***********************************************************************************************
    private forceRefreshPanel = (): void => {
        config.DEBUG && console.log("Loading users START...") //trigger for spinner
        this.setState({ userLoadState: true })
        //config.DEBUG && console.log(result)
        this.getAllAccounts().then(result => {
            config.DEBUG && console.log("Loading users STOP...") //stopping spinner
            this.setState({
                userLoadState: false
            });
            this.userListUpdate();
        })

    }

    private collectGraphData = (): void => {

        config.DEBUG && console.log("Loading users START...") //trigger for spinner
        this.setState({ userLoadState: true });

        this.getAllAccounts();

        config.DEBUG && console.log("Loading users STOP...") //stopping spinner
        this.setState({ userLoadState: false });
    }

    //***********************************************************************************************
    //This method renders user array for permissions list (ShareUsers component)
    //It checks current state of the user object (being added, removed etc) and provides correct value.
    //***********************************************************************************************
    async getAllAccounts() {
        //*** Language section ***
        let langfile: any;
        if (this.props.lang == "PL") {
            langfile = require("../LangFiles/resourcePL.json");
        } else {
            langfile = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        var graphService = new GraphService(this.props.authToken, this.props.userAgentApplication);
        await graphService.getShareUsersFromInvitations(this.props.shareGroupId, "All").then(
            result => {
                config.DEBUG && console.log(result)
                var lockString = ""
                var nameSuffix = "" //used only when blocking functionality enabled
                var displayNameString = ""
                var userTypeValue = ""
                this._allUsers = new Array();
                this._allUsers = result.value.map(function (val: any) {
                    if (val.fields.IsUserBlocked == true) {
                        lockString = langfile.str_ToggleYesCapital
                        nameSuffix = langfile.str_LblLockedSuffix
                    } else {
                        lockString = langfile.str_ToggleNoCapital
                        nameSuffix = ""
                    }

                    //generating correct values for user Display Name
                    //if (val.fields.Dodawany == "tak") { //regional string "tak" is the value confugred in SPO list.
                    //displayNameString = langfile.str_FieldChangeUserAddInProgress
                    var setFirstName = val.fields.Imie !== undefined ? val.fields.Imie : ""   //checking if field is not empty
                    var setLastName = val.fields.Nazwisko !== undefined ? val.fields.Nazwisko : "" //checking if field is not empty
                    displayNameString = setFirstName + " " + setLastName
                    //} else {
                    //    displayNameString = val.fields.Imie + " " + val.fields.Nazwisko + nameSuffix
                    //}

                    //generating correct values for UserType field
                    if (val.fields.Rodzaj == "Pracownik NBP") {
                        userTypeValue = langfile.str_UserTypePartner
                    } else if (val.fields.Rodzaj == "PracownikNBP") {
                        userTypeValue = langfile.str_UserTypePartner
                    } else if (val.fields.Rodzaj == "Podmiot zewnętrzny") {
                        userTypeValue = langfile.str_UserTypeExternalSmall
                    } else if (val.fields.Rodzaj == "PodmiotZewnetrzny") {
                        userTypeValue = langfile.str_UserTypeExternalSmall
                    } else {
                        userTypeValue = "N/A" //most likelly failed attempt
                    }

                    return {    //Regional strings used
                        displayName: displayNameString,
                        email: val.fields.Email,
                        userType: userTypeValue,
                        shareItemId: val.fields.ShareNameLookupId,
                        upn: val.fields.NazwaUPN,
                        firstName: val.fields.Imie,
                        lastName: val.fields.Nazwisko,
                        accountName: val.fields.NazwaKontaLookupId,
                        isBlocked: lockString,
                        isBeingDeleted: val.fields.Usuwany,
                        isBeingAdded: val.fields.Dodawany,
                        itemId: val.id,
                        isOperator: val.fields.Operator,
                        roleChange: val.fields.ZmianaRoli,
                        alert: val.fields.Alert
                    }
                });
            }

        ).catch(
            error => {
                config.DEBUG && console.log(error);
            }
        );

        // config.DEBUG && console.log(this._allUsers)
        this.setState({ allUsers: this._allUsers })
        //config.DEBUG && console.log(this.state.externalUsers)
    }

    private hideDialog = (): void => {
        this.setState({
            isDialogVisible: false
        })
    }

    private hideDialogAndPanel = (): void => {
        this.setState({
            isDialogVisible: false
        })
        this.forceRefreshPanel()
        this.props.closePanelMethod();
    }

}
