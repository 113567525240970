import * as React from "react";
import Container from "react-bootstrap/Container";
import { IIconProps, IContextualMenuProps, IconButton, Link, Text } from '@fluentui/react';
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import { colorThemeLight } from './Themes';
const config = require("../components/config.json");

const gblResource = require("../LangFiles/resource.json");
const gblResourcePL = require("../LangFiles/resourcePL.json");

const langIcon: IIconProps = { iconName: 'LocaleLanguage' };

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

export interface LoggedUserProps { userName: string, lang: string, setGlobalLangMethod: any, showLangButton: boolean }
export interface LoggedUserState {
    isAuthenticated: boolean,
    userName: string,
    visible: boolean,
    buttonLang: boolean
}

export class LoggedUser extends React.Component<LoggedUserProps, LoggedUserState> {
    constructor(props: LoggedUserProps) {
        super(props);
        this.state = {
            isAuthenticated: false,
            userName: "",
            visible: false,
            buttonLang: true
        };
    }

    render() {

        const menuProps: IContextualMenuProps = {
            items: [
                {
                    key: 'pl',
                    text: 'Polski',
                    onClick: this._onButtonClick.bind(this),
                    disabled: this.props.lang == "PL"
                },
                {
                    key: 'en',
                    text: 'English',
                    onClick: this._onButtonClick.bind(this),
                    disabled: this.props.lang == "EN"
                },
            ],
        };

        config.DEBUG && console.log("%cUserValue is: " + this.props.userName, "color: Magenta")

        return (
            <Container className="pt-2" style={{ display: 'flex' }}>
                <div className="swpStyle-LangChoice-button">
                    {this.props.showLangButton == false ? <div></div> :
                        <TooltipHost
                            content={this.props.lang == "PL" ? gblResourcePL.str_TipChangeLanguage : gblResource.str_TipChangeLanguage} 
                            id={"1"}
                            calloutProps={calloutProps}
                            styles={hostStyles}
                        >
                            {/* <IconButton
                                split
                                iconProps={langIcon}
                                splitButtonAriaLabel="Language options"
                                aria-roledescription="Language options icon button"
                                menuProps={menuProps}
                                ariaLabel="Change language"
                                className={"swpStyle-LangChoice-button"}
                                theme={colorThemeLight}
                            /> */}
                            <Link className="swpStyle-LoggInText" onClick={this._onButtonClick.bind(this)}><Text variant={"medium"}>{this.props.lang == "PL" ? "English" : "Polski"}</Text></Link>
                        </TooltipHost>
                    }
                    <Text variant={"medium"}>&nbsp;|&nbsp;</Text>
                    {this.props.userName == undefined || this.props.userName == null || this.props.userName == "" ? 
                        <Text className="swpStyle-LoggInText" variant={"medium"}>
                            {this.props.lang == "PL" ? gblResourcePL.str_LblLoggedInAsNull : gblResource.str_LblLoggedInAsNull}
                        </Text> :
                        <Text className="swpStyle-LoggInText" variant={"medium"}>
                            {this.props.lang == "PL" ? gblResourcePL.str_LblLoggedInAs : gblResource.str_LblLoggedInAs} <strong>{this.props.userName}</strong>
                        </Text> 
                    }
                </div>
            </Container>
        );
    }

    private _onButtonClick() {

        if (this.props.lang == "PL") {
            this.props.setGlobalLangMethod("EN")
        } else {
            this.props.setGlobalLangMethod("PL")
        }

    }
}