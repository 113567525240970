import * as React from "react";
import { TextField } from "@fluentui/react/lib/TextField";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  IColumn,
} from "@fluentui/react/lib/DetailsList";
import { MarqueeSelection } from "@fluentui/react/lib/MarqueeSelection";
import { Fabric } from "@fluentui/react/lib/Fabric";
import { getId } from "@fluentui/react/lib/Utilities";
import { Modal } from "@fluentui/react/lib/Modal";
import {
  CommandBarButton,
  IIconProps,
  Stack,
  IStackStyles,
  IButtonStyles,
  DetailsRow,
  IStackTokens,
  IDetailsRowProps,
  IDetailsRowStyles,
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
  Text,
  MaskedTextField,
  MessageBar,
  MessageBarType,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { TooltipHost } from "@fluentui/react/lib/Tooltip";
import { Container, Row, Col } from "react-bootstrap";
import { colorThemeLight } from "./Themes";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import * as Msal from "msal";
import { GraphService } from "../components/GraphService";
import {
  ProgressIndicator,
  IProgressIndicatorStyles,
} from "@fluentui/react/lib/ProgressIndicator";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

initializeIcons();

const config = require("../components/config.json");
const gblResource = require("../LangFiles/resource.json");
const gblResourcePL = require("../LangFiles/resourcePL.json");

const stackStyles: Partial<IStackStyles> = { root: { height: 44 } };
const buttonStyles: Partial<IButtonStyles> = {
  icon: { color: "#005f69" },
  iconHovered: { color: "#005f69" },
};
const progressStyles: Partial<IProgressIndicatorStyles> = {
  itemProgress: { paddingTop: 0 },
};

const delIcon: IIconProps = { iconName: "Delete" };
const mailIcon: IIconProps = { iconName: "Mail" };
const operatorAddIcon: IIconProps = { iconName: "UserGauge" };
const operatorDelIcon: IIconProps = { iconName: "UserWarning" };
const resetIcon: IIconProps = { iconName: "PasswordField" };
const ringerOnIcon: IIconProps = { iconName: "Ringer" };
const ringerOffIcon: IIconProps = { iconName: "RingerOff" };

const classNames = mergeStyleSets({
  normalCell: {
    alignSelf: "center",
  },
  searchFieldClassName: {
    display: "block",
    marginBottom: "10px",
  },
});

const stackTokens: IStackTokens = { childrenGap: 10 };

const dialogContentProps = {
  type: DialogType.normal
};

const dialogModalProps = {
  isBlocking: true,
  styles: { main: { minWidth: 250, minHeight: 100 } },
};

const maskFormat: { [key: string]: RegExp } = {
  "*": /[0-9]/,
};

interface IMessageBarProps {
  closeMsgAction?: any;
  langData?: any;
}

const MessageBarLastOperatorWarning = (p: IMessageBarProps) => (
  <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
    {p.langData.str_MsgLastOperatorWarning}
  </MessageBar>
);

export interface ShareUsersItem {
  key: number;
  name: string;
  value: number;
}

interface IUserItem {
  key: number;
  displayName: any;
  lastName: string;
  firstName: string;
  userType: string;
  upn: string;
  email: string;
  shareItemId: string;
  accountName: string;
  isBlocked: string;
  isBeingDeleted: string;
  isBeingAdded: string;
  itemId: number;
  isOperator: boolean;
  ownerChange: string;
  roleChange: string;
  alert: string;
}

interface IUserData {
  id: number;
}

export interface ShareUsersState {
  realItems: IUserItem[];
  selectionDetails: {};
  columns: IColumn[];
  delButtonDisabled: boolean;
  blockButtonDisabled: boolean;
  unblockButtonDisabled: boolean;
  mailButtonDisabled: boolean;
  resetButtonDisabled: boolean;
  upgradeRoleButtonDisabled: boolean;
  downgradeRoleButtonDisabled: boolean;
  showupgradeRoleModal: boolean;
  showdowngradeRoleModal: boolean;
  showDelUserModal: boolean;
  showBlockUserModal: boolean;
  showUnBlockUserModal: boolean;
  showPromoteUserModal: boolean;
  showResetCredModal: boolean;
  selectedUsers?: IUserItem[];
  showConfirmModal: boolean;
  userLoadState: boolean;
  actionLoad: boolean;
  userCommsLink: string;
  externalUserSelected?: boolean;
  buttonClick: boolean;
  ownerUserDisplayName?: string;
  phoneNumber: string;
  phoneNotCorrectError?: string;
  phoneValueEmpty: boolean;
  confirmModalText: string;
  lastOperatorWrn: boolean;
  showalertOnModal: boolean;
  alertOnButtonDisabled: boolean;
  alertOffButtonDisabled: boolean;
}

export interface ShareUsersProps {
  userArray: any;
  isExternalList: boolean;
  listTitle: string;
  authToken: string;
  userAgentApplication: Msal.UserAgentApplication;
  userRefreshMethod: any;
  lang: string;
  ownerEmail: string;
  groupId: string;
}

export class ShareUsers extends React.Component<
  ShareUsersProps,
  ShareUsersState
> {
  private _delUserTitleId: string = getId("del-title");
  private _delUserSubtitleId: string = getId("del-subText");
  private _upgradeRoleTitleId: string = getId("bl-title");
  private _upgradeRoleSubtitleId: string = getId("bl-subText");
  private _downgradeRoleTitleId: string = getId("ubl-title");
  private _downgradeRoleSubtitleId: string = getId("ubl-subText");
  private _resetCredentialsTitleId: string = getId("reset-title");
  private _resetCredentialsSubTitleId: string = getId("reset-subText");
  private _selection: Selection;
  private _alertOnTitleId: string = getId("alert-title");
  private _alertOnSubtitleId: string = getId("alert-subText");

  constructor(props: ShareUsersProps) {
    super(props);

    this._selection = new Selection({
      onSelectionChanged: () =>
        this.setState({ selectionDetails: this._getSelectionDetails() }),
    });

    const columnsSet3: IColumn[] = [
      {
        key: "column0",
        name: "",
        isIconOnly: true,
        isResizable: false,
        fieldName: "isOperator",
        minWidth: 20,
        maxWidth: 20,
        onColumnClick: this._onColumnClick,
        onRender: (item: IUserItem) => {
          if (item.roleChange == undefined && item.isOperator == true) {
            return (
              <TooltipHost
                content={
                  this.props.lang == "PL"
                    ? gblResourcePL.str_TipUserIsOperator
                    : gblResource.str_TipUserIsOperator
                }
                id="1"
                calloutProps={{ gapSpace: 0 }}
                styles={{ root: { display: "inline-block" } }}
              >
                <Icon
                  className="swpStyle-user-list-icon"
                  iconName="Admin"
                  aria-label="Operator"
                />
              </TooltipHost>
            );
          } else if (item.roleChange == "tak") {
            return (
              <TooltipHost
                content={
                  this.props.lang == "PL"
                    ? gblResourcePL.str_MsgRoleChangeInProgress
                    : gblResource.str_MsgRoleChangeInProgress
                }
                id="2"
                calloutProps={{ gapSpace: 0 }}
                styles={{ root: { display: "inline-block" } }}
              >
                <Spinner size={SpinnerSize.xSmall} theme={colorThemeLight} />
              </TooltipHost>
            );
          } else {
            return <div></div>;
          }
        },
      },
      {
        key: 'column1',
        name: '',
        isIconOnly: true,
        isResizable: false,
        fieldName: 'isAlert',
        minWidth: 20,
        maxWidth: 20,
        onColumnClick: this._onColumnClick,
        onRender: (item: IUserItem) => {
          if (item.alert == "0") {
            return (
              <div></div>
            );
          } else if (item.alert == "1") {
            return (
              <TooltipHost content={this.props.lang == "PL" ? gblResourcePL.str_TipUserIsOperator : gblResource.str_TipUserIsOperator} id="1" calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
                <Icon className="swpStyle-user-list-icon" iconName="Ringer" aria-label="Alert" />
              </TooltipHost>
            );
          } else {
            return (
              <TooltipHost content={this.props.lang == "PL" ? gblResourcePL.str_MsgRoleChangeInProgress : gblResource.str_MsgRoleChangeInProgress} id="2" calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
                <Spinner size={SpinnerSize.xSmall} theme={colorThemeLight} />
              </TooltipHost>
            );
          }
        }
      },
      {
        key: "column2",
        name:
          this.props.lang == "PL"
            ? gblResourcePL.str_FieldUserName
            : gblResource.str_FieldUserName,
        fieldName: "displayName",
        className: classNames.normalCell,
        minWidth: 160,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "column3",
        name:
          this.props.lang == "PL"
            ? gblResourcePL.str_FieldEmail
            : gblResource.str_FieldEmail,
        fieldName: "email",
        className: classNames.normalCell,
        minWidth: 150,
        maxWidth: 250,
        isResizable: true,
      },
      // {
      //   key: "column4",
      //   name:
      //     this.props.lang == "PL"
      //       ? gblResourcePL.str_FieldUserAccountName
      //       : gblResource.str_FieldUserAccountName,
      //   fieldName: "upn",
      //   className: classNames.normalCell,
      //   minWidth: 100,
      //   maxWidth: 200,
      //   isResizable: true,
      //   onColumnClick: this._onColumnClick,
      // },
      {
        key: "column5",
        name:
          this.props.lang == "PL"
            ? gblResourcePL.str_FieldUserType
            : gblResource.str_FieldUserType,
        fieldName: "userType",
        className: classNames.normalCell,
        minWidth: 140,
        maxWidth: 140,
        isResizable: false,
        onColumnClick: this._onColumnClick,
      },
    ];

    this.state = {
      realItems: this.props.userArray,
      selectionDetails: this._getSelectionDetails(),
      columns: columnsSet3,
      delButtonDisabled: true,
      mailButtonDisabled: true,
      blockButtonDisabled: true,
      unblockButtonDisabled: true,
      resetButtonDisabled: true,
      // promoteButtonDisabled: true,
      showDelUserModal: false,
      showBlockUserModal: false,
      showUnBlockUserModal: false,
      showPromoteUserModal: false,
      upgradeRoleButtonDisabled: true,
      downgradeRoleButtonDisabled: true,
      showupgradeRoleModal: false,
      showdowngradeRoleModal: false,
      showConfirmModal: false,
      showResetCredModal: false,
      showalertOnModal: false,
      userLoadState: false,
      actionLoad: false,
      userCommsLink: "",
      // ownerSelected: false,
      buttonClick: false,
      ownerUserDisplayName: "",
      phoneNumber: "",
      phoneValueEmpty: false,
      confirmModalText: "",
      lastOperatorWrn: false,
      alertOnButtonDisabled: true,
      alertOffButtonDisabled: true,
    };
  }

  public render(): JSX.Element {
    //*** Language section ***
    let resource: any;
    if (this.props.lang == "PL") {
      resource = require("../LangFiles/resourcePL.json");
    } else {
      resource = require("../LangFiles/resource.json");
    }
    //*** end of language section ***

    const {
      buttonClick,
      showConfirmModal,
      showResetCredModal,
      actionLoad,
      realItems,
      columns,
      delButtonDisabled,
      mailButtonDisabled,
      showDelUserModal,
      showalertOnModal,
      phoneValueEmpty,
      confirmModalText,
      upgradeRoleButtonDisabled,
      downgradeRoleButtonDisabled,
      showupgradeRoleModal,
      showdowngradeRoleModal,
      resetButtonDisabled,
      alertOnButtonDisabled,
      alertOffButtonDisabled,
    } = this.state;

    return (
      <Fabric>
        {/* <h5 style={{paddingBottom: "20px", boxShadow: theme.effects.elevation8}}>Właściciel obszaru: {this.state.ownerUserDisplayName}</h5> */}
        <h5>{this.props.listTitle}</h5>

        <TextField
          className={classNames.searchFieldClassName}
          label={resource.str_PhSearch}
          onChange={this._onFilter}
          styles={{ root: { maxWidth: "300px" } }}
        />

        <Stack horizontal styles={stackStyles}>
          <CommandBarButton
            iconProps={delIcon}
            text={resource.str_BtnRemoveUsers}
            disabled={delButtonDisabled}
            checked={false}
            styles={buttonStyles}
            onClick={this._showDelUserModal}
            theme={colorThemeLight}
          />
          <CommandBarButton
            iconProps={mailIcon}
            text={resource.str_BtnMailUser}
            disabled={mailButtonDisabled}
            checked={false}
            styles={buttonStyles}
            onClick={this._emailUserButton}
            //onClick={this._emailUserButton}
            href={this.state.userCommsLink}
            theme={colorThemeLight}
          />
          {!upgradeRoleButtonDisabled ? (
            <CommandBarButton
              iconProps={operatorAddIcon}
              text={resource.str_BtnupgradeRoleToOperator}
              checked={false}
              styles={buttonStyles}
              onClick={this._showupgradeRoleModal}
              theme={colorThemeLight}
            />
          ) : (
            <div></div>
          )}
          {!downgradeRoleButtonDisabled ? (
            <CommandBarButton
              iconProps={operatorDelIcon}
              text={resource.str_BtndowngradeRoleFromOperator}
              checked={false}
              styles={buttonStyles}
              onClick={this._showdowngradeRoleModal}
              theme={colorThemeLight}
            />
          ) : (
            <div></div>
          )}
          {/* {!resetButtonDisabled ? (
            <CommandBarButton
              iconProps={resetIcon}
              text={resource.str_BtnResetAndSendCredentials}
              checked={false}
              styles={buttonStyles}
              onClick={this._showResetCredModal}
            />
          ) : (
            <div></div>
          )} */}
          {!alertOnButtonDisabled ? (
            <CommandBarButton
              iconProps={ringerOnIcon}
              text={resource.str_MenuEnableAlert}
              checked={false}
              styles={buttonStyles}
              onClick={this._showAlertModal}
            />
          ) : (
            <div></div>
          )}
          {!alertOffButtonDisabled ? (
            <CommandBarButton
              iconProps={ringerOffIcon}
              text={resource.str_MenuDisableAlert}
              checked={false}
              styles={buttonStyles}
              onClick={this._showAlertModal}
            />
          ) : (
            <div></div>
          )}
        </Stack>
        {this.state.lastOperatorWrn && (
          <MessageBarLastOperatorWarning
            langData={resource}
            closeMsgAction={this.dismissWarningMsg}
          />
        )}
        <MarqueeSelection selection={this._selection} theme={colorThemeLight}>
          {realItems ? (
            <DetailsList
              items={realItems}
              columns={columns}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              selection={this._selection}
              selectionPreservedOnEmptyClick={false}
              //selectionMode={1}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              onRenderRow={this._onRenderRow}
              theme={colorThemeLight}
            />
          ) : (
            <div>
              <p>{resource.str_MsgNoUsersOnShare}</p>
            </div>
          )}
        </MarqueeSelection>
        {realItems.length == 0 ? (
          <div>
            <div
              style={{
                textAlign: "center",
                width: "200px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <img src="https://spoprod-a.akamaihd.net/files/odsp-next-prod-amd_2020-01-10-sts_20200124.002/odsp-media/images/emptyfolder/empty_sites.svg"></img>
            </div>
            <div style={{ textAlign: "center" }}>
              {resource.str_LblNoUsersToShow}
            </div>
          </div>
        ) : (
          <div></div>
        )}

        <p></p>
        <Modal
          titleAriaId={this._delUserTitleId}
          subtitleAriaId={this._delUserSubtitleId}
          isOpen={showDelUserModal}
          onDismiss={this._closeDelUserModal}
          isModeless={false}
          //containerClassName={styles.stickyContainer}
          dragOptions={undefined}
          containerClassName="swpStyle-del-item-modal-window"
          theme={colorThemeLight}
        >
          <div className="swpStyle-del-item-modal-header">
            <span id={this._delUserTitleId}>
              <h6>{resource.str_HeaderDeleteUser}</h6>
            </span>
          </div>
          <ProgressIndicator
            progressHidden={!actionLoad}
            barHeight={5}
            theme={colorThemeLight}
            styles={progressStyles}
          />
          <div
            id={this._delUserSubtitleId}
            className="swpStyle-del-item-modal-body"
          >
            <p>{resource.str_MsgDeleteUserWarning}</p>
            {/* {this.state.externalUserSelected && (
              <p>
                <b>{resource.str_MsgDeleteExternalUserWarning}</b>
              </p>
            )} */}
            <div id="swpStyle-del-item-buttons">
              <Stack
                horizontal
                tokens={stackTokens}
                className="swpStyle-modal-buttonParrent"
              >
                <PrimaryButton
                  style={{ marginLeft: "auto" }}
                  onClick={this._onDelUserButtonClick.bind(this)}
                  text={resource.str_BtnDelete}
                  disabled={buttonClick}
                  theme={colorThemeLight}
                />
                <DefaultButton
                  style={{ marginRight: "auto" }}
                  onClick={this._closeDelUserModal}
                  text={resource.str_BtnCancel}
                />
              </Stack>
            </div>
          </div>
        </Modal>
        <Modal
          titleAriaId={this._upgradeRoleTitleId}
          subtitleAriaId={this._upgradeRoleSubtitleId}
          isOpen={showupgradeRoleModal}
          onDismiss={this._closeupgradeRoleModal}
          isModeless={false}
          dragOptions={undefined}
          containerClassName="swpStyle-del-item-modal-window"
          theme={colorThemeLight}
        >
          <div className="swpStyle-del-item-modal-header">
            <span id={this._upgradeRoleTitleId}>
              <h6>{resource.str_HeaderPromoteToOperator}</h6>
            </span>
          </div>
          <ProgressIndicator
            progressHidden={!actionLoad}
            barHeight={5}
            theme={colorThemeLight}
            styles={progressStyles}
          />
          <div
            id={this._upgradeRoleSubtitleId}
            className="swpStyle-del-item-modal-body"
          >
            <p>{resource.str_MsgPromoteToOperator}</p>
            <div id="swpStyle-del-item-buttons">
              <Stack
                horizontal
                tokens={stackTokens}
                className="swpStyle-modal-buttonParrent"
              >
                <PrimaryButton
                  style={{ marginLeft: "auto" }}
                  onClick={this._onupgradeRoleButtonClick.bind(this)}
                  text={resource.str_BtnOK}
                  theme={colorThemeLight}
                  disabled={buttonClick}
                />
                <DefaultButton
                  style={{ marginRight: "auto" }}
                  onClick={this._cancelupgradeRoleModal}
                  text={resource.str_BtnCancel}
                />
              </Stack>
            </div>
          </div>
        </Modal>
        <Modal
          titleAriaId={this._downgradeRoleTitleId}
          subtitleAriaId={this._downgradeRoleSubtitleId}
          isOpen={showdowngradeRoleModal}
          onDismiss={this._closedowngradeRoleModal}
          isModeless={false}
          dragOptions={undefined}
          containerClassName="swpStyle-del-item-modal-window"
          theme={colorThemeLight}
        >
          <div className="swpStyle-del-item-modal-header">
            <span id={this._downgradeRoleTitleId}>
              <h6>{resource.str_HeaderDemoteFromOperator}</h6>
            </span>
          </div>
          <ProgressIndicator
            progressHidden={!actionLoad}
            barHeight={5}
            theme={colorThemeLight}
            styles={progressStyles}
          />
          <div
            id={this._downgradeRoleSubtitleId}
            className="swpStyle-del-item-modal-body"
          >
            <p>{resource.str_MsgDemoteFromOperator}</p>
            <div id="swpStyle-del-item-buttons">
              <Stack
                horizontal
                tokens={stackTokens}
                className="swpStyle-modal-buttonParrent"
              >
                <PrimaryButton
                  style={{ marginLeft: "auto" }}
                  onClick={this._ondowngradeRoleButtonClick.bind(this)}
                  text={resource.str_BtnOK}
                  theme={colorThemeLight}
                />
                <DefaultButton
                  style={{ marginRight: "auto" }}
                  onClick={this._canceldowngradeRoleModal}
                  text={resource.str_BtnCancel}
                />
              </Stack>
              <br></br>
            </div>
          </div>
        </Modal>
        <Modal
          titleAriaId={this._resetCredentialsTitleId}
          subtitleAriaId={this._resetCredentialsSubTitleId}
          isOpen={showResetCredModal}
          onDismiss={this._closeResetCredModal}
          isModeless={false}
          //containerClassName={styles.stickyContainer}
          dragOptions={undefined}
          containerClassName="swpStyle-dresetcred-item-modal-window"
          theme={colorThemeLight}
        >
          <div className="swpStyle-upload-file-modal-header">
            <span id={this._resetCredentialsTitleId}>
              <h6>{resource.str_HeaderResetCredentials}</h6>
            </span>
          </div>
          <ProgressIndicator
            progressHidden={!actionLoad}
            barHeight={5}
            theme={colorThemeLight}
            styles={progressStyles}
          />
          <div
            id={this._resetCredentialsSubTitleId}
            className="swpStyle-upload-file-modal-body"
          >
            <Container>
              <Row>
                {/* <Col><Text>{resource.str_MsgResetCred}</Text></Col> */}
                <Col>
                  <Text block>
                    W celu wykonania resetu hasła dla wybranego użytkownika
                  </Text>
                  <Text block>należy wpisać numer telefonu komórkowego.</Text>
                  <Text block>Hasło zostanie przekazane na podany numer</Text>
                  <Text block>poprzez wiadomość SMS.</Text>
                </Col>
                <Col>
                  <MaskedTextField
                    theme={colorThemeLight}
                    label={resource.str_LblPhone}
                    mask="*********"
                    maskFormat={maskFormat}
                    maskChar=""
                    onChange={this._onPhoneAdded.bind(this)}
                    required
                    validateOnFocusIn
                    errorMessage={this.state.phoneNotCorrectError}
                  />
                  <br></br>
                </Col>
              </Row>
            </Container>
            <br></br>
            <Stack
              horizontal
              tokens={stackTokens}
              className="swpStyle-modal-buttonParrent"
            >
              <PrimaryButton
                style={{ marginLeft: "auto" }}
                onClick={this.__onResetCredButtonClick.bind(this)}
                text={resource.str_BtnOK}
                disabled={buttonClick || !phoneValueEmpty}
              />
              <DefaultButton
                style={{ marginRight: "auto" }}
                onClick={this._closeResetCredModal}
                text={resource.str_BtnCancel}
              />
            </Stack>
            <br></br>
          </div>
        </Modal>
        <Modal
          titleAriaId={this._alertOnTitleId}
          subtitleAriaId={this._alertOnSubtitleId}
          isOpen={showalertOnModal}
          onDismiss={this._closealertOnModal}
          isModeless={false}
          dragOptions={undefined}
          containerClassName="swpStyle-alert-modal-window"
          theme={colorThemeLight}
        >
          <div className="swpStyle-del-item-modal-header">
            <span id={this._alertOnTitleId}><h6>{resource.str_HeaderSetAlert}</h6></span>
          </div>
          <ProgressIndicator progressHidden={!actionLoad} barHeight={5} theme={colorThemeLight} styles={progressStyles}/>
          <div id={this._alertOnSubtitleId} className="swpStyle-del-item-modal-body">
            <p>
              {alertOnButtonDisabled
                ? resource.str_MsgSetAlertInfoOffExt
                : resource.str_MsgSetAlertInfoOnExt}
            </p>
            <div id="swpStyle-del-item-buttons">
              <Stack horizontal tokens={stackTokens} className="swpStyle-modal-buttonParrent">
                <PrimaryButton style={{ marginLeft: "auto" }} onClick={this._onSetAlertButtonClick.bind(this)} text={resource.str_BtnOK} disabled={buttonClick} theme={colorThemeLight}/>
                <DefaultButton style={{ marginRight: "auto" }} onClick={this._closealertOnModal} text={resource.str_BtnCancel}/>
              </Stack>
            </div>
          </div>
        </Modal>
        <Dialog
          //correctly configured modal - display as expected. Required min height set to 100px via dialogModalProps
          hidden={!showConfirmModal}
          onDismiss={this._closeConfirmModal}
          dialogContentProps={dialogContentProps}
          modalProps={dialogModalProps}
          title={resource.str_MsgConfirmSuccessHeader}
        >
          {confirmModalText == "" ? (
            <Text></Text>
          ) : (
            <Text>{confirmModalText}</Text>
          )}
          <DialogFooter>
              <PrimaryButton theme={colorThemeLight} onClick={this._closeConfirmModal} text={resource.str_BtnOK}/>
          </DialogFooter>
        </Dialog>
      </Fabric>
    );
  }

  //***********************************************************************************************
  //This method forcecully refreshes ShareUsers component to show user correct Owner after change.
  //***********************************************************************************************
  shareUsersUpdate = () => {
    this.forceUpdate();
  };

  private _onRenderRow = (props: IDetailsRowProps): JSX.Element => {
    //config.DEBUG == true && console.log(props.item)

    //*** Language section ***
    let langfile: any;
    if (this.props.lang == "PL") {
      langfile = require("../LangFiles/resourcePL.json");
    } else {
      langfile = require("../LangFiles/resource.json");
    }
    //*** end of language section ***

    const customStyles: Partial<IDetailsRowStyles> = {};

    if (props.item.isBeingDeleted == "tak") {
      customStyles.root = {
        backgroundColor: "red",
        color: colorThemeLight.palette.white,
      };
    }
    if (props.item.isBeingAdded == "tak") {
      customStyles.root = {
        backgroundColor: "rgb(220, 240, 240)",
        color: colorThemeLight.palette.black,
      };
    }

    return <DetailsRow {...props} styles={customStyles} />;
  };

  async componentDidMount() {
    config.DEBUG && console.log("shareUsers mounted");
    // this.getOwnerData();
  }

  private _getSelectionDetails(): string {
    const selectionCount = this._selection.getSelectedCount();

    //*** Language section ***
    let langfile: any;
    if (this.props.lang == "PL") {
      langfile = require("../LangFiles/resourcePL.json");
    } else {
      langfile = require("../LangFiles/resource.json");
    }
    //*** end of language section ***

    switch (selectionCount) {
      case 0:
        this.setState({
          resetButtonDisabled: true,
          delButtonDisabled: true,
          mailButtonDisabled: true,
          blockButtonDisabled: true,
          unblockButtonDisabled: true,
          downgradeRoleButtonDisabled: true,
          upgradeRoleButtonDisabled: true,
          lastOperatorWrn: false,
          alertOnButtonDisabled: true,
          alertOffButtonDisabled: true,
          // ownerSelected: false
        });
        return this.props.lang == "PL"
          ? gblResourcePL.str_MsgNoUserSelected
          : gblResource.str_MsgNoUserSelected;

      case 1:
        let selectedUser: IUserItem[] =
          this._selection.getSelection() as IUserItem[];
        let operatorsOnly: any = this.props.userArray.filter(
          (i: any) => i.isOperator === true
        );

        if (selectedUser[0].userType == langfile.str_UserTypeExternalSmall) {
          //if external user str_UserTypeExternalSmall
          this.setState({
            delButtonDisabled: false,
            mailButtonDisabled: false,
            selectedUsers: selectedUser,
            externalUserSelected: true,
            resetButtonDisabled: false,
            lastOperatorWrn: false,
            alertOnButtonDisabled: false,
            alertOffButtonDisabled: true
          });

          //alert button section
          if (selectedUser[0].alert == "1") {
            this.setState({
              alertOnButtonDisabled: true,
              alertOffButtonDisabled: false
            })
          } else if(selectedUser[0].alert == "2") {
            this.setState({
              alertOnButtonDisabled: true,
              alertOffButtonDisabled: true
            })
          } else {
            this.setState({
              alertOnButtonDisabled: false,
              alertOffButtonDisabled: true
            })
          }
          //END - alert button section

        } else if (selectedUser[0].userType == langfile.str_UserTypePartner) {
          //if employee
          this.setState({
            delButtonDisabled: false,
            mailButtonDisabled: false,
            selectedUsers: selectedUser,
            resetButtonDisabled: true,
            lastOperatorWrn: false,
            alertOnButtonDisabled: false,
            alertOffButtonDisabled: true
          });
          config.DEBUG && console.log("&& Employee &&");

          //alert button section
          if (selectedUser[0].alert == "1") {
            this.setState({
              alertOnButtonDisabled: true,
              alertOffButtonDisabled: false
            })
          } else if(selectedUser[0].alert == "2") {
            this.setState({
              alertOnButtonDisabled: true,
              alertOffButtonDisabled: true
            })
          } else {
            this.setState({
              alertOnButtonDisabled: false,
              alertOffButtonDisabled: true
            })
          }
          //END - alert button section

          //*** additional conditions to check if employee has Operator Role
          if (selectedUser[0].isOperator == true) {
            this.setState({
              upgradeRoleButtonDisabled: true,
              downgradeRoleButtonDisabled: false,
              alertOnButtonDisabled: true,
              alertOffButtonDisabled: true
            });
            config.DEBUG && console.log("&& Employee && Operator &&");

            //Preventing last Operator removal
            if (operatorsOnly.length <= 1) {
              this.setState({
                delButtonDisabled: true,
                downgradeRoleButtonDisabled: true,
                lastOperatorWrn: true,
              });
              config.DEBUG && console.log("&& Employee && Last Operator &&");
            }
          } else if (selectedUser[0].isOperator == false) {
            this.setState({
              upgradeRoleButtonDisabled: false,
              downgradeRoleButtonDisabled: true,
              lastOperatorWrn: false,
            });
            config.DEBUG && console.log("&& Employee && No Operator");
          } else {
            this.setState({
              //detecting forbiden isOperator state value
              upgradeRoleButtonDisabled: true,
              downgradeRoleButtonDisabled: true,
              delButtonDisabled: true,
              lastOperatorWrn: false,
            });
            config.DEBUG &&
              console.log("&& Employee && Any other state for Employee &&");
          }
        } else {
          //if Operator - not used - thie relies on Opertator user type, not relevant anymore
          config.DEBUG && console.log("&& Unkown User Type &&");
        }

        //for all user types:
        if (
          selectedUser[0].isBeingDeleted == "tak" ||
          selectedUser[0].isBeingAdded == "tak"
        ) {
          this.setState({
            delButtonDisabled: true,
            mailButtonDisabled: true,
            upgradeRoleButtonDisabled: true,
            downgradeRoleButtonDisabled: true,
            resetButtonDisabled: true,
          });
          config.DEBUG &&
            console.log("&& Employee or External && Added or Deleted");
        }

        var userEmail = selectedUser[0].email;
        this.setState({
          userCommsLink:
            "mailto:" + userEmail + this.props.lang == "PL"
              ? gblResourcePL.str_UserCommsLink
              : gblResource.str_UserCommsLink,
        });

        return this.props.lang == "PL"
          ? gblResourcePL.str_MsgOneUserSelected
          : gblResource.str_MsgOneUserSelected +
          (this._selection.getSelection()[0] as IUserItem).displayName;

      default:
        let selectedUsers: IUserItem[] =
          this._selection.getSelection() as IUserItem[];
        this.setState({
          delButtonDisabled: true,
          mailButtonDisabled: true,
          upgradeRoleButtonDisabled: true,
          downgradeRoleButtonDisabled: true,
          resetButtonDisabled: true,
          selectedUsers: selectedUsers,
          lastOperatorWrn: false,
          alertOnButtonDisabled: true,
          alertOffButtonDisabled: true
        });
        //detecting external:
        for (let i = 0; i < selectedUsers.length; i++) {
          if (selectedUsers[i].userType == langfile.str_UserTypeExternalSmall) {
            this.setState({ externalUserSelected: true });
            break;
          }
          //additional condition for currently deleted user.......
        }

        return `${this.props.lang == "PL"
            ? gblResourcePL.str_MsgSelectedUsersCount
            : gblResource.str_MsgSelectedUsersCount
          } ${selectionCount}`;
    }
  }

  private _onFilter = (
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    text: string
  ): void => {
    this.setState({
      realItems: text
        ? this.props.userArray.filter(
          (i: any) => i.displayName.toLowerCase().indexOf(text) > -1
        )
        : this.props.userArray,
    });
  };

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns, realItems } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._copyAndSort(
      realItems,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      realItems: newItems,
    });
  };

  private _copyAndSort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const key = columnKey as keyof T;
    return items
      .slice(0)
      .sort((a: T, b: T) =>
        (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
      );
  }

  private _onPhoneAdded(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.length == 9) {
      config.DEBUG && console.log(event.target.value.length);
      this.setState({
        phoneNotCorrectError: "",
        phoneNumber: event.target.value,
        phoneValueEmpty: true,
      });
    } else {
      config.DEBUG && console.log(event.target.value.length);
      this.setState({
        phoneNotCorrectError:
          this.props.lang == "PL"
            ? gblResourcePL.str_ErrIncorrectPhone
            : gblResource.str_ErrIncorrectPhone,
        phoneValueEmpty: false,
      });
    }
  }

  //#region Modal actions

  private _showDelUserModal = (): void => {
    this.setState({ showDelUserModal: true });
  };

  private _closeDelUserModal = (): void => {
    this.setState({ showDelUserModal: false, externalUserSelected: false });
  };

  private _showConfirmModal = (): void => {
    this.setState({ showConfirmModal: true });
  };

  private _closeConfirmModal = (): void => {
    this.setState({
      showConfirmModal: false,
      buttonClick: false,
      showPromoteUserModal: false,
      // showBlockUserModal: false,
      // showUnBlockUserModal: false,
      showDelUserModal: false,
      externalUserSelected: false,
      confirmModalText: "",
      showupgradeRoleModal: false,
    });
    this.props.userRefreshMethod(); //refreshes UserListIE panel data
    // this.props.spoRefreshMethod(); //refreshes owner data from share list
    this.shareUsersUpdate(); //rerenders shareUsersUpdate component
  };

  private _showResetCredModal = (): void => {
    this.setState({ showResetCredModal: true });
  };

  private _closeResetCredModal = (): void => {
    this.setState({ showResetCredModal: false, externalUserSelected: false });
  };

  //#endregion Modal actions

  //#region Button actions

  private _onDelUserButtonClick() {
    config.DEBUG && console.log("Removing users: ");
    //config.DEBUG && console.log(this.state.selectedUsers);
    this.setState({
      actionLoad: true,
      externalUserSelected: false,
      buttonClick: true,
    });
    var graphService = new GraphService(
      this.props.authToken,
      this.props.userAgentApplication
    );

    for (let i: number = 0; i < this.state.selectedUsers.length; i++) {
      config.DEBUG && console.log("Loop run nr: " + i);

      //setting property "Usuwany" to "TAK" for CSS loaded on list to Higlight item Red and block from repeat delete
      graphService
        .setUserStatusToDelete(this.state.selectedUsers[i].itemId)
        .then((result) => {
          graphService
            .deleteUserFromShare(this.state.selectedUsers[i])
            .then((result) => {
              config.DEBUG && console.log(result);
              // this._closeDelUserModal();
              this.setState({ actionLoad: false });
              this._showConfirmModal();
              // this.props.userRefreshMethod();
            });
        });
    }
  }

  private _emailUserButton = (): void => {
    //*** Language section ***
    let resource: any;
    if (this.props.lang == "PL") {
      resource = require("../LangFiles/resourcePL.json");
    } else {
      resource = require("../LangFiles/resource.json");
    }
    //*** end of language section ***

    let selectedUser: IUserItem[] =
      this._selection.getSelection() as IUserItem[];
    config.DEBUG && console.log("Mailing users: ");
    config.DEBUG && console.log(selectedUser);
    var userEmail = selectedUser[0].email;
    this.setState({
      userCommsLink: "mailto:" + userEmail + resource.str_UserCommsLink,
    });
  };

  private async __onResetCredButtonClick() {
    config.DEBUG && console.log("Resetting credentioals for: ");
    config.DEBUG && console.log(this.state.selectedUsers);

    this.setState({
      actionLoad: true,
      externalUserSelected: false,
      buttonClick: true,
    });
    var graphService = new GraphService(
      this.props.authToken,
      this.props.userAgentApplication
    );
    var user = this.state.selectedUsers[0];
    graphService
      .resetCredForExternalUser(
        user.upn,
        user.firstName,
        user.lastName,
        user.email,
        this.state.phoneNumber
      )
      .then((result) => {
        config.DEBUG && console.log(result);
        this.setState({ actionLoad: false });
        this._showConfirmModal();
      });
  }

  //#endregion Button actions

  //#region alerts

  private _showAlertModal = (): void => {
    this.setState({
      showalertOnModal: true,
    });
  };

  private _closealertOnModal = (): void => {
    this.setState({ showalertOnModal: false });
    this.props.userRefreshMethod();
  };

  private async _onSetAlertButtonClick() {
    //code
    let selectedUser: IUserItem[] =
      this._selection.getSelection() as IUserItem[];
    config.DEBUG && console.log("Setting Alert: ");
    config.DEBUG && console.log(selectedUser);

    this.setState({ actionLoad: true });
    var graphService = new GraphService(
      this.props.authToken,
      this.props.userAgentApplication
    );

    if (selectedUser[0].alert == "1") {
      var setAlert = "0";
      this.setState({confirmModalText: "Zgłoszenie o wyłączenie powiadomień wysłane"})
    } else if (selectedUser[0].alert == "0") {
      var setAlert = "1";
      this.setState({confirmModalText: "Zgłoszenie o włączenie powiadomień wysłane"})
    } else {
      //in progress
    }

    graphService
      .SetAlertsToInProgress(selectedUser[0].itemId)
      .then((result) => {
        graphService
          .SetAlert(null, selectedUser[0].upn, setAlert, selectedUser[0].itemId)
          .then((result) => {
            if (result) {
              this.setState({ 
                actionLoad: false
              });
              this._showConfirmModal();
            }
          });
      });
  }

  //#endregion alerts

  //#region upgradeRole/downgradeRole users

  private _showupgradeRoleModal = (): void => {
    this.setState({ showupgradeRoleModal: true });
  };

  private _showdowngradeRoleModal = (): void => {
    this.setState({ showdowngradeRoleModal: true });
  };

  private _closeupgradeRoleModal = (): void => {
    this.setState({ showupgradeRoleModal: false });
    this.props.userRefreshMethod();
  };

  private _closedowngradeRoleModal = (): void => {
    this.setState({ showdowngradeRoleModal: false });
    this.props.userRefreshMethod();
  };

  private _cancelupgradeRoleModal = (): void => {
    this.setState({ showupgradeRoleModal: false });
  };

  private _canceldowngradeRoleModal = (): void => {
    this.setState({ showdowngradeRoleModal: false });
  };

  private async _onupgradeRoleButtonClick() {
    config.DEBUG && console.log(this.state.selectedUsers[0].itemId);
    this.setState({ actionLoad: true });
    var cleanValue: boolean = null;
    var graphService = new GraphService(
      this.props.authToken,
      this.props.userAgentApplication
    );

    await graphService
      .changeUserStatusOnGoing(this.state.selectedUsers[0].itemId, cleanValue)
      .then(async (result) => {
        await graphService
          .changeRole(
            this.state.selectedUsers[0].itemId,
            !this.state.selectedUsers[0].isOperator,
            "upgradeRole"
          )
          .then(async (result) => {
            //newStatus [2nd] parameter not currently used
            if (this.state.selectedUsers[0].isOperator == true) {
              config.DEBUG && console.log("User downgradeRoled");
            } else {
              config.DEBUG && console.log("User upgradeRoled");
            }
          });
      });

    this.setState({ actionLoad: false });
    this._showConfirmModal();
  }

  private async _ondowngradeRoleButtonClick() {
    config.DEBUG && console.log(this.state.selectedUsers[0].itemId);
    this.setState({ actionLoad: true });
    var cleanValue: boolean = null;
    var graphService = new GraphService(
      this.props.authToken,
      this.props.userAgentApplication
    );

    await graphService
      .changeUserStatusOnGoing(this.state.selectedUsers[0].itemId, cleanValue)
      .then(async (result) => {
        await graphService
          .changeRole(
            this.state.selectedUsers[0].itemId,
            !this.state.selectedUsers[0].isOperator,
            "downgradeRole"
          )
          .then(async (result) => {
            //newStatus [2nd] parameter not currently used
            if (this.state.selectedUsers[0].isOperator == true) {
              config.DEBUG && console.log("User downgradeRoled");
            } else {
              config.DEBUG && console.log("User upgradeRoled");
            }
          });
      });

    this.setState({ actionLoad: false });
    this._showConfirmModal();
  }

  private dismissWarningMsg = (): void => {
    config.DEBUG && console.log("Close message clicked");
    this.setState({
      lastOperatorWrn: false,
    });
  };

  //#endregion upgradeRole/downgradeRole users
}
